import api from '@infrastructure/api/axios.ts';

export const fetchAds = async () => {
  const response = await api.get('/ads');
  return response.data;
};

export const createAd = async (adData: Record<string, any>) => {
  const response = await api.post('/ads', adData);
  return response.data;
};

export const fetchCategories = async (languageCode: string) => {
  const response = await api.get(`/categories/allEnabled?languageCode=${languageCode}`);
  return response.data;
};

export const fetchCategoriesForTypeAndSectionName = async (
  languageCode: string,
  type: string,
  sectionName: string,
) => {
  const response = await api.get(
    `/categories/allEnabledForTypeAndSectionName?languageCode=${languageCode}&type=${type}&sectionName=${sectionName}`,
  );
  return response.data;
};
