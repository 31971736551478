import React, { useState, useCallback , useEffect } from 'react';
import type { EventsForm } from './eventsform';
import { useNavigate } from 'react-router-dom';

const STORAGE_KEY = 'formData';
const PAGE_STORAGE_KEY = 'currentFormPage';
const SCHEMA_METADATA = {
  'id': 'ea75c953-809a-405c-8283-a47d63f02de1',
  'schemaFile': 'D:\\WebstormProjects\\schemas\\ad\\events\\events_1_0_0.json',
};


const EventsFormForm: React.FC = () => {
  const [formData, setFormData] = useState<Partial<EventsForm>>(() =>{
    try {
      const savedData = localStorage.getItem(STORAGE_KEY);
      return savedData ? JSON.parse(savedData) : {};
    } catch (error) {
      console.error('Error parsing saved form data:', error);
      return {};
    }
  });
  const [currentPage, setCurrentPage] = useState(0);
  const totalPages = 3;
  const navigate = useNavigate();
  
  // Save form data to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(formData));
  }, [formData]);

  // Save current page to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem(PAGE_STORAGE_KEY, currentPage.toString());
  }, [currentPage]);

  const setNestedValue = (obj: any, path: string, value: any) => {
    const pathParts = path.split('.');
    const sectionKey = pathParts[0]; // Get the section key (e.g., generalInformation)
    const fieldPath = pathParts.slice(1); // Get the rest of the path

    // If there's no section, treat it as a root-level field
    if (fieldPath.length === 0) {
      return { ...obj, [sectionKey]: value };
    }

    // Ensure the section exists
    const section = obj[sectionKey] || {};
  
    // If we only have one field in the path, set it directly
    if (fieldPath.length === 1) {
      return {
        ...obj,
        [sectionKey]: {
          ...section,
          [fieldPath[0]]: value,
        },
      };
    }

    // For nested fields within the section
    const current = { ...section };
    const lastKey = fieldPath.pop()!;
    let pointer = current;

    for (const key of fieldPath) {
      pointer[key] = pointer[key] || {};
      pointer = pointer[key];
    }

    pointer[lastKey] = value;

    return {
      ...obj,
      [sectionKey]: current,
    };
  };

  const getNestedValue = (obj: any, path: string) => {
    try {
      return path.split('.').reduce((acc, part) => acc?.[part], obj) ?? '';
    } catch {
      return '';
    }
  };
  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(0, prev - 1));
  };

  const handleNextPage = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setCurrentPage((prev) => Math.min(totalPages - 1, prev + 1));
  };
   
  const handleDraft = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    // Save the current form data as a draft
    localStorage.setItem(STORAGE_KEY, JSON.stringify(formData));
    localStorage.setItem(PAGE_STORAGE_KEY, currentPage.toString());
    console.log('Draft saved');
  };
   
  // Add a reset function to clear the form
  const resetForm = useCallback(() => {
    setFormData({});
    setCurrentPage(0);
    localStorage.removeItem(STORAGE_KEY);
    localStorage.removeItem(PAGE_STORAGE_KEY);
  }, []);
  

  const renderPaginationControls = () => {
    return (
      <div className="flex flex-col sm:flex-row justify-between items-center gap-4 px-4 py-6 bg-white rounded-lg shadow-sm">
        <button
          type="button"
          onClick={handlePrevPage}
          disabled={currentPage === 0}
          className="max-sm:w-15 max-md::w-30 lg:w-32 xl:w-32 px-5 py-3 bg-gradient-to-r from-blue-500 to-blue-600 text-white font-medium rounded-xl 
        transform transition-all duration-200 
        hover:from-blue-600 hover:to-blue-700 hover:shadow-md 
        active:scale-95
        disabled:from-gray-300 disabled:to-gray-400 disabled:cursor-not-allowed disabled:transform-none
        focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          <span className="flex items-center justify-center">
            <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          Previous
          </span>
        </button>
        <button
          type="button"
          onClick={handleDraft}
          disabled={currentPage === 0}
          className="max-sm:w-25 max-md::w-30 lg:w-36 xl:w-38 px-5 py-3 bg-white text-gray-600 font-medium rounded-xl hover:text-green-600"
        >
          <span className="flex items-center justify-center">
          Save as Draft
          </span>
        </button>
      
      
        {currentPage === totalPages - 1 ? (
          <button
            type="submit"
            className="max-sm:w-15 max-md::w-30 lg:w-32 xl:w-32 px-5 py-3 bg-gradient-to-r from-green-500 to-green-600 text-white font-medium rounded-xl 
          transform transition-all duration-200 
          hover:from-green-600 hover:to-green-700 hover:shadow-md 
          active:scale-95
          focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
          >
            <span className="flex items-center justify-center">
            Submit
              <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
            </span>
          </button>
        ) : (
          <button
            type="button"
            onClick={handleNextPage}
            className="max-sm:w-15 max-md::w-30 lg:w-32 xl:w-32 px-5 py-3 bg-gradient-to-r from-blue-500 to-blue-600 text-white font-medium rounded-xl 
          transform transition-all duration-200 
          hover:from-blue-600 hover:to-blue-700 hover:shadow-md 
          active:scale-95
          focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            <span className="flex items-center justify-center">
            Next
              <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </span>
          </button>
        )}
      </div>
    );
  };

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;

    setFormData((prev) => {
      let newValue: any;

      switch (type) {
        case 'checkbox':
          newValue = (e.target as HTMLInputElement).checked;
          break;
        case 'number':
        case 'range':
          newValue = value === '' ? null : Number(value);
          break;
        case 'radio':
          newValue = value;
          break;
        case 'file':
          return prev; // Files are handled by handleFileChange
        default:
          newValue = value;
      }

      return setNestedValue({ ...prev }, name, newValue);
    });
  }, []);

  const handleFileChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = e.target;
    if (files && files.length > 0) {
      setFormData((prev) => {
      // Get existing files array or initialize empty array
        const existingFiles = getNestedValue(prev, name) || [];
        // Add new files to existing files, avoiding duplicates
        const newFiles = Array.from(files).map((file) => file.name);
        const uniqueFiles = [...new Set([...existingFiles, ...newFiles])];
        return setNestedValue({ ...prev }, name, uniqueFiles);
      });
    }
  }, [formData, getNestedValue]);
  
  // Add a function to remove individual files
  const removeFile = useCallback((fieldName: string, fileIndex: number) => {
    setFormData((prev) => {
      const files = getNestedValue(prev, fieldName) || [];
      const newFiles = files.filter((_: any, index: number) => index !== fileIndex);
      return setNestedValue({ ...prev }, fieldName, newFiles);
    });
  }, []);



  const handleSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    // Process form data to ensure all fields are included
    const processFormData = (data: any) => {
      const processed: any = {};
      
      // Helper function to handle empty/undefined values
      const processValue = (value: any) => {
        if (Array.isArray(value)) {
          return value.length > 0 ? value : [];
        }
        if (value === '') {
          return null;
        }
        return value ?? null;
      };

      // Process each field, maintaining structure
      Object.entries(data).forEach(([key, value]) => {
        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
          processed[key] = processFormData(value); // Recursively process nested objects
        } else {
          processed[key] = processValue(value);
        }
      });

      return processed;
    };


    // Create the new payload structure
    const payload = {      
         
      primaryCategoryId: SCHEMA_METADATA.id,
      schemaFile: SCHEMA_METADATA.schemaFile,
      data: JSON.stringify(processFormData(formData)),
    };
    console.log('Payload being sent:', JSON.stringify(payload, null, 2));

    try {
      const response = await fetch('https://adzmonster.com/api/ads/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log('Success:', result);
      // Clear form data after successful submission
      resetForm();
    } catch (error) {
      console.error('Error:', error);
    }
  }, [formData, resetForm]);
  return (
    <div className="min-h-screen from-blue-50/50 to-white flex items-center justify-center p-4 sm:p-6 md:p-8 lg:p-9">
      <form onSubmit={handleSubmit} className="w-full max-w-4xl mx-auto bg-white backdrop-blur-lg bg-opacity-95 rounded-2xl shadow-xl border border-white border-opacity-20 p-4 sm:p-6 md:p-8 transition-all duration-300 hover:shadow-2xl">
        {/* Progress Bar */}
        <div className="mb-8">
          <div className="w-full bg-gray-100 rounded-full h-3 overflow-hidden shadow-inner">
            <div
              className="bg-gradient-to-r from-blue-500 to-purple-500 h-3 rounded-full transition-all duration-500 ease-in-out"
              style={{ width: `${((currentPage + 1) / totalPages) * 100}%` }}
            />
          </div>
          <div className="flex items-center justify-center px-4 py-2 bg-white rounded-lg">
            <span className="text-sm sm:text-base font-medium text-gray-700">
                  Step {currentPage + 1} of {totalPages}
            </span>
          </div>
        </div>
        {/* Action Buttons */}
        <div className="flex flex-col sm:flex-row justify-between items-center gap-4 mb-8">
          {/* Navigation Links */}
          <button
            type="button"
            onClick={() => navigate('/v1')}
            className="group flex items-center px-4 py-2 text-sm font-medium text-gray-700 hover:text-blue-600 transition-all duration-200"
          >
            <svg 
              className="w-4 h-4 mr-2 transform transition-transform group-hover:-translate-x-1" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
                    Back to Home
          </button>
        
          {/* Reset Button */}
          <button
            type="button"
            onClick={resetForm}
            className="flex items-center px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-red-500 to-red-600 rounded-xl 
                      transform transition-all duration-200 
                      hover:from-red-600 hover:to-red-700 hover:shadow-md 
                      active:scale-95
                      focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
          >
            <svg 
              className="w-4 h-4 mr-2" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
            </svg>
                      Reset Form
          </button>
        </div>
                
        {currentPage === 0 && (
          <div key="0" className="space-y-4">
                    
            <div className="mb-6">
              <div className="relative">
                <label className="block text-sm font-medium text-gray-700 mb-2">
          Type of event
                </label>
                <div className="relative group">
                  <select
                    name="eventType"
                    value={getNestedValue(formData, 'eventType') ?? ''}
                    onChange={handleChange}
                    className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
                    required
                  >
                    <option value="" className="text-gray-500">Select eventType</option>
                    <option key="CONCERT" value="CONCERT">CONCERT</option>
                    <option key="SPORTS" value="SPORTS">SPORTS</option>
                    <option key="THEATER" value="THEATER">THEATER</option>
                    <option key="FESTIVAL" value="FESTIVAL">FESTIVAL</option>
                    <option key="CONFERENCE" value="CONFERENCE">CONFERENCE</option>
                    <option key="WEBINAR" value="WEBINAR">WEBINAR</option>
                    <option key="EXHIBITION" value="EXHIBITION">EXHIBITION</option>
                    <option key="WORKSHOP" value="WORKSHOP">WORKSHOP</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="mb-6">
              <div className="flex justify-between items-center mb-2">
                <label className="block text-sm font-medium text-gray-700">
          Event title
                  <span className="ml-1 text-red-500">*</span>
                </label>
        
              </div>
      
              <div className="relative">
                <input
                  type="text"
                  name="title"
                  value={getNestedValue(formData, 'title') ?? ''}
                  onChange={handleChange}
                  placeholder="Enter event title..."
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 "
                  required
                />
        
        
              </div>
      
      
      
      
            </div>

            <div className="mb-6">
              <div className="flex justify-between items-center mb-2">
                <label className="block text-sm font-medium text-gray-700">
          Event date and time
                  <span className="ml-1 text-red-500">*</span>
                </label>
        
              </div>
      
              <div className="relative">
                <input
                  type="datetime-local"
                  name="date"
                  value={getNestedValue(formData, 'date') ?? ''}
                  onChange={handleChange}
                  placeholder="Enter event date and time..."
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 "
                  required
                />
        
        
              </div>
      
      
      
      
            </div>
          </div>
        )}

        {currentPage === 1 && (
          <div key="1" className="space-y-4">
                    
            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              venue
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Venue name
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="name"
                      value={getNestedValue(formData, 'name') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter venue name..."
                      className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 "
                      required
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Venue capacity
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="capacity"
                      value={getNestedValue(formData, 'capacity') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter venue capacity..."
                      className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 "
                      min="1" required
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                    <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              location
                    </h3>
                  </div>
                  <div className="space-y-4 px-6">
          
                    <div className="mb-6">
                      <div className="flex justify-between items-center mb-2">
                        <label className="block text-sm font-medium text-gray-700">
          street
                          <span className="ml-1 text-red-500">*</span>
                        </label>
        
                      </div>
      
                      <div className="relative">
                        <input
                          type="text"
                          name="street"
                          value={getNestedValue(formData, 'street') ?? ''}
                          onChange={handleChange}
                          placeholder="Enter street..."
                          className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 "
                          required
                        />
        
        
                      </div>
      
      
      
      
                    </div>

                    <div className="mb-6">
                      <div className="flex justify-between items-center mb-2">
                        <label className="block text-sm font-medium text-gray-700">
          city
                          <span className="ml-1 text-red-500">*</span>
                        </label>
        
                      </div>
      
                      <div className="relative">
                        <input
                          type="text"
                          name="city"
                          value={getNestedValue(formData, 'city') ?? ''}
                          onChange={handleChange}
                          placeholder="Enter city..."
                          className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 "
                          required
                        />
        
        
                      </div>
      
      
      
      
                    </div>

                    <div className="mb-6">
                      <div className="flex justify-between items-center mb-2">
                        <label className="block text-sm font-medium text-gray-700">
          state
                          <span className="ml-1 text-red-500">*</span>
                        </label>
        
                      </div>
      
                      <div className="relative">
                        <input
                          type="text"
                          name="state"
                          value={getNestedValue(formData, 'state') ?? ''}
                          onChange={handleChange}
                          placeholder="Enter state..."
                          className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 "
                          required
                        />
        
        
                      </div>
      
      
      
      
                    </div>

                    <div className="mb-6">
                      <div className="flex justify-between items-center mb-2">
                        <label className="block text-sm font-medium text-gray-700">
          zip
                          <span className="ml-1 text-red-500">*</span>
                        </label>
        
                      </div>
      
                      <div className="relative">
                        <input
                          type="text"
                          name="zip"
                          value={getNestedValue(formData, 'zip') ?? ''}
                          onChange={handleChange}
                          placeholder="Enter zip..."
                          className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 "
                          required
                        />
        
        
                      </div>
      
      
      
      
                    </div>
                  </div>
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Accessibility features available at the venue
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="accessibilityFeatures"
                      value={getNestedValue(formData, 'accessibilityFeatures') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter accessibility features available at the venue..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
          
                    />
        
        
                  </div>
      
      
      
      
                </div>
              </div>
            </div>

            <div className="mb-6">
              <div className="flex justify-between items-center mb-2">
                <label className="block text-sm font-medium text-gray-700">
          tickets
                  <span className="ml-1 text-red-500">*</span>
                </label>
        
              </div>
      
              <div className="relative">
                <input
                  type="text"
                  name="tickets"
                  value={getNestedValue(formData, 'tickets') ?? ''}
                  onChange={handleChange}
                  placeholder="Enter tickets..."
                  className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                  required
                />
        
        
              </div>
      
      
      
      
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
          Event Images
              </label>
              <div className="group relative">
                <div className="flex justify-center items-center w-full" >
                  <div className="relative w-full">
                    <input
                      type="file"
                      name="images"
                      onChange={handleFileChange}
                      multiple
                      className="absolute inset-0 w-full h-full opacity-0 z-10 cursor-pointer"
                      accept="image/*"
                    />
                    <div className="flex flex-col items-center w-full h-32 px-4 py-6 
                    bg-white text-gray-500 rounded-lg border-2 border-dashed border-gray-300 
                    transition-all duration-200 
                    hover:bg-gray-50 hover:border-blue-300
                    group-focus-within:border-blue-500 group-focus-within:ring-2 group-focus-within:ring-blue-500 group-focus-within:ring-offset-2">
                    
                      <div className="flex flex-col items-center pointer-events-none">
                        <svg className="w-8 h-8 mb-2 text-gray-400 group-hover:text-blue-500 transition-colors duration-200" 
                          fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                        </svg>
                      
                        <p className="text-sm text-gray-500 text-center group-hover:text-gray-600">
                          <span className="font-medium">Click to upload</span> or drag and drop
                        </p>
                      
                        <p className="text-xs text-gray-500 mt-1">
                        Accepted files: image/*
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {(getNestedValue(formData, 'images') || []).length > 0 && (
                  <div className="space-y-2">
                    {(getNestedValue(formData, 'images') || []).map((fileName: string, index: number) => (
                      <div key={index} className="flex items-center justify-between p-3 bg-blue-50 rounded-lg">
                        <div className="flex items-center">
                          <svg className="w-5 h-5 text-blue-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                              d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                          </svg>
                          <span className="text-sm font-medium text-blue-700">
                            {fileName}
                          </span>
                        </div>
          
                        <button
                          type="button"
                          onClick={() => removeFile('images', index)}
                          className="p-1 text-blue-400 hover:text-blue-600 rounded-full hover:bg-blue-100 
              transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {currentPage === 2 && (
          <div key="2" className="space-y-4">
                    
            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              media
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Event Videos
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="file-multiple"
                      name="videos"
                      value={getNestedValue(formData, 'videos') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter event videos..."
                      className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 "
          
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Virtual Tour URL
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="url"
                      name="virtualTour"
                      value={getNestedValue(formData, 'virtualTour') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter virtual tour url..."
                      className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 "
          
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Drone Footage URL
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="url"
                      name="droneFootage"
                      value={getNestedValue(formData, 'droneFootage') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter drone footage url..."
                      className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 "
          
                    />
        
        
                  </div>
      
      
      
      
                </div>
              </div>
            </div>

            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              utilities
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-4">
                  <label className="inline-flex items-start">
                    <input
                      type="checkbox"
                      name="internet"
                      checked={Boolean(getNestedValue(formData, 'internet'))}
                      onChange={handleChange}
                      className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                    />
                    <span className="ml-2 text-sm text-gray-600">
          Internet Connectivity
                    </span>
                  </label>
                </div>

                <div className="mb-4">
                  <label className="inline-flex items-start">
                    <input
                      type="checkbox"
                      name="waterConnection"
                      checked={Boolean(getNestedValue(formData, 'waterConnection'))}
                      onChange={handleChange}
                      className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                    />
                    <span className="ml-2 text-sm text-gray-600">
          Water Connection Available
                    </span>
                  </label>
                </div>

                <div className="mb-4">
                  <label className="inline-flex items-start">
                    <input
                      type="checkbox"
                      name="electricity"
                      checked={Boolean(getNestedValue(formData, 'electricity'))}
                      onChange={handleChange}
                      className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                    />
                    <span className="ml-2 text-sm text-gray-600">
          Electricity Connection
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        )}
        {renderPaginationControls()}
      </form>
    </div>
  );
};

export default EventsFormForm;