import { Link, useNavigate } from 'react-router-dom'; // For routing

export default function Logo() {
  const navigate = useNavigate();
  const handleClick = () => {
    console.log('Clicked Logo');
    navigate('/Header');
  };
  return (
    <div>
      <Link to="/" className="flex items-center">
        <img
          src="/assets/adzmonster_temp_logo.png"
          alt="Company Logo"
          width={160}
          height={40}
          className="object-contain "
          onClick={handleClick}
        />
      </Link>
    </div>
  );
}
