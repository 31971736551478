import { useState, useEffect } from 'react';


// Define breakpoints
const BREAKPOINTS = {
  mobile: 640, // Max width for mobile
  tablet: 1024, // Max width for tablet, above mobile
  laptop: 1440, // Max width for laptop, above tablet
};

export default function useScreenSize() {
  const [screenSize, setScreenSize] = useState({
    isMobile: window.innerWidth < BREAKPOINTS.mobile,
    isTablet:
      window.innerWidth >= BREAKPOINTS.mobile && window.innerWidth < BREAKPOINTS.tablet,
    isLaptop:
      window.innerWidth >= BREAKPOINTS.tablet && window.innerWidth < BREAKPOINTS.laptop,
    isDesktop: window.innerWidth >= BREAKPOINTS.laptop,
  });



  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        isMobile: window.innerWidth < BREAKPOINTS.mobile,
        isTablet:
          window.innerWidth >= BREAKPOINTS.mobile && window.innerWidth < BREAKPOINTS.tablet,
        isLaptop:
          window.innerWidth >= BREAKPOINTS.tablet && window.innerWidth < BREAKPOINTS.laptop,
        isDesktop: window.innerWidth >= BREAKPOINTS.laptop,
      });
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return screenSize;
}
