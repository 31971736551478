import React, { useEffect, useRef, useState } from 'react';
import { useLanguage } from '../language/useLanguage.ts';
import useScreenSize from '../../hooks/useScreenSizeHook.ts';


interface Category {
  id: string;
  code: string;
  translatedName: string;
}

interface CategoryDropdownProps {
  selectedCategory: string; // The currently selected category
  setSelectedCategory: (value: string) => void; // Function to update the selected category state
}

const CategoryDropdown: React.FC<CategoryDropdownProps> = ({
  selectedCategory,
  setSelectedCategory,
}) => {
  const { selectedLanguage } = useLanguage();
  const [categories, setCategories] = useState<Category[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const selectRef = useRef<HTMLSelectElement>(null);
  const [selectWidth, setSelectWidth] = useState('auto');
  const hiddenTextRef = useRef<HTMLSpanElement>(null);
  const { isMobile } = useScreenSize(); // Use hook for screen size

  // Fetch categories on language change
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `https://adzmonster.com/api/categories/allEnabled?languageCode=${selectedLanguage}`,
        );
        if (!response.ok) {
          console.error(`Error fetching categories: ${response.status}`);
          return;
        }
        const responseData = await response.json();
        const categories: Category[] = responseData.data.map((item: any) => ({
          id: item.id,
          code: item.code,
          translatedName: item.translatedName,
          childCategories: item.childCategories || [], // Map child categories if present
        }));
        setCategories(categories);
      } catch (error) {
        console.error('Error fetching categories:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategories().catch(console.error);
  }, [selectedLanguage]);

  // Get the current selected category text
  const getCurrentSelectedText = () => {
    if (!selectedCategory && categories.length === 0) return 'All Categories';
    const selectedItem = categories.find((cat) => cat.code === selectedCategory);
    return selectedItem ? selectedItem.translatedName || selectedItem.code : 'All Categories';
  };

  // Update dropdown width dynamically
  useEffect(() => {
    if (isMobile) {
      if (hiddenTextRef.current) {
        const textWidth = hiddenTextRef.current.offsetWidth;
        const newWidth = textWidth + 20;
        setSelectWidth(`${Math.min(Math.max(newWidth, 60), 100)}px`);
      } else {
        setSelectWidth('60px'); // Default small-screen width
      }
    } else {
      if (hiddenTextRef.current) {
        const textWidth = hiddenTextRef.current.offsetWidth;
        const newWidth = textWidth + 50;
        setSelectWidth(`${Math.min(Math.max(newWidth, 120), 350)}px`);
      } else {
        setSelectWidth('120px'); // Default large-screen width
      }
    }
  }, [selectedCategory, categories, isMobile]);

  return (
    <div className="relative ">
      <span
        ref={hiddenTextRef}
        className="absolute invisible whitespace-nowrap text-sm"
        aria-hidden="true"
      >
        {getCurrentSelectedText()}
      </span>
      <select
        value={selectedCategory}
        onChange={(e) => setSelectedCategory(e.target.value)}
        ref={selectRef}
        style={{ width: selectWidth }}
        className="bg-white border-none focus:ring-0 focus:outline-none focus:border-transparent "
      >
        <option value="">All</option>
        {!isLoading &&
          categories.map((category) => (
            <option key={category.id} value={category.code} className="truncate">
              {category.translatedName || category.code}
            </option>
          ))}
      </select>
    </div>
  );
};

export default CategoryDropdown;
