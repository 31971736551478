import React, { useState, useCallback , useEffect } from 'react';
import type { RoomsForRentForm } from './roomsforrentform';
import { useNavigate } from 'react-router-dom';

const STORAGE_KEY = 'formData';
const PAGE_STORAGE_KEY = 'currentFormPage';
const SCHEMA_METADATA = {
  'id': '184cb1b5-a9ff-4114-837d-db5eae350016',
  'schemaFile': 'D:\\WebstormProjects\\schemas\\ad\\realestate\\roomsforrent\\rooms_for_rent_v_1_0_0.json',
};


const RoomsForRentFormForm: React.FC = () => {
  const [formData, setFormData] = useState<Partial<RoomsForRentForm>>(() =>{
    try {
      const savedData = localStorage.getItem(STORAGE_KEY);
      return savedData ? JSON.parse(savedData) : {};
    } catch (error) {
      console.error('Error parsing saved form data:', error);
      return {};
    }
  });
  const [currentPage, setCurrentPage] = useState(0);
  const totalPages = 4;
  const navigate = useNavigate();
  
  // Save form data to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(formData));
  }, [formData]);

  // Save current page to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem(PAGE_STORAGE_KEY, currentPage.toString());
  }, [currentPage]);

  const setNestedValue = (obj: any, path: string, value: any) => {
    const pathParts = path.split('.');
    const sectionKey = pathParts[0]; // Get the section key (e.g., generalInformation)
    const fieldPath = pathParts.slice(1); // Get the rest of the path

    // If there's no section, treat it as a root-level field
    if (fieldPath.length === 0) {
      return { ...obj, [sectionKey]: value };
    }

    // Ensure the section exists
    const section = obj[sectionKey] || {};
  
    // If we only have one field in the path, set it directly
    if (fieldPath.length === 1) {
      return {
        ...obj,
        [sectionKey]: {
          ...section,
          [fieldPath[0]]: value,
        },
      };
    }

    // For nested fields within the section
    const current = { ...section };
    const lastKey = fieldPath.pop()!;
    let pointer = current;

    for (const key of fieldPath) {
      pointer[key] = pointer[key] || {};
      pointer = pointer[key];
    }

    pointer[lastKey] = value;

    return {
      ...obj,
      [sectionKey]: current,
    };
  };

  const getNestedValue = (obj: any, path: string) => {
    try {
      return path.split('.').reduce((acc, part) => acc?.[part], obj) ?? '';
    } catch {
      return '';
    }
  };
  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(0, prev - 1));
  };

  const handleNextPage = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setCurrentPage((prev) => Math.min(totalPages - 1, prev + 1));
  };
   
  const handleDraft = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    // Save the current form data as a draft
    localStorage.setItem(STORAGE_KEY, JSON.stringify(formData));
    localStorage.setItem(PAGE_STORAGE_KEY, currentPage.toString());
    console.log('Draft saved');
  };
   
  // Add a reset function to clear the form
  const resetForm = useCallback(() => {
    setFormData({});
    setCurrentPage(0);
    localStorage.removeItem(STORAGE_KEY);
    localStorage.removeItem(PAGE_STORAGE_KEY);
  }, []);
  

  const renderPaginationControls = () => {
    return (
      <div className="flex flex-col sm:flex-row justify-between items-center gap-4 px-4 py-6 bg-white rounded-lg shadow-sm">
        <button
          type="button"
          onClick={handlePrevPage}
          disabled={currentPage === 0}
          className="max-sm:w-15 max-md::w-30 lg:w-32 xl:w-32 px-5 py-3 bg-gradient-to-r from-blue-500 to-blue-600 text-white font-medium rounded-xl 
        transform transition-all duration-200 
        hover:from-blue-600 hover:to-blue-700 hover:shadow-md 
        active:scale-95
        disabled:from-gray-300 disabled:to-gray-400 disabled:cursor-not-allowed disabled:transform-none
        focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          <span className="flex items-center justify-center">
            <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          Previous
          </span>
        </button>
        <button
          type="button"
          onClick={handleDraft}
          disabled={currentPage === 0}
          className="max-sm:w-25 max-md::w-30 lg:w-36 xl:w-38 px-5 py-3 bg-white text-gray-600 font-medium rounded-xl hover:text-green-600"
        >
          <span className="flex items-center justify-center">
          Save as Draft
          </span>
        </button>
      
      
        {currentPage === totalPages - 1 ? (
          <button
            type="submit"
            className="max-sm:w-15 max-md::w-30 lg:w-32 xl:w-32 px-5 py-3 bg-gradient-to-r from-green-500 to-green-600 text-white font-medium rounded-xl 
          transform transition-all duration-200 
          hover:from-green-600 hover:to-green-700 hover:shadow-md 
          active:scale-95
          focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
          >
            <span className="flex items-center justify-center">
            Submit
              <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
            </span>
          </button>
        ) : (
          <button
            type="button"
            onClick={handleNextPage}
            className="max-sm:w-15 max-md::w-30 lg:w-32 xl:w-32 px-5 py-3 bg-gradient-to-r from-blue-500 to-blue-600 text-white font-medium rounded-xl 
          transform transition-all duration-200 
          hover:from-blue-600 hover:to-blue-700 hover:shadow-md 
          active:scale-95
          focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            <span className="flex items-center justify-center">
            Next
              <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </span>
          </button>
        )}
      </div>
    );
  };

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;

    setFormData((prev) => {
      let newValue: any;

      switch (type) {
        case 'checkbox':
          newValue = (e.target as HTMLInputElement).checked;
          break;
        case 'number':
        case 'range':
          newValue = value === '' ? null : Number(value);
          break;
        case 'radio':
          newValue = value;
          break;
        case 'file':
          return prev; // Files are handled by handleFileChange
        default:
          newValue = value;
      }

      return setNestedValue({ ...prev }, name, newValue);
    });
  }, []);

  const handleFileChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = e.target;
    if (files && files.length > 0) {
      setFormData((prev) => {
      // Get existing files array or initialize empty array
        const existingFiles = getNestedValue(prev, name) || [];
        // Add new files to existing files, avoiding duplicates
        const newFiles = Array.from(files).map((file) => file.name);
        const uniqueFiles = [...new Set([...existingFiles, ...newFiles])];
        return setNestedValue({ ...prev }, name, uniqueFiles);
      });
    }
  }, [formData, getNestedValue]);
  
  // Add a function to remove individual files
  const removeFile = useCallback((fieldName: string, fileIndex: number) => {
    setFormData((prev) => {
      const files = getNestedValue(prev, fieldName) || [];
      const newFiles = files.filter((_: any, index: number) => index !== fileIndex);
      return setNestedValue({ ...prev }, fieldName, newFiles);
    });
  }, []);



  const handleSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    // Process form data to ensure all fields are included
    const processFormData = (data: any) => {
      const processed: any = {};
      
      // Helper function to handle empty/undefined values
      const processValue = (value: any) => {
        if (Array.isArray(value)) {
          return value.length > 0 ? value : [];
        }
        if (value === '') {
          return null;
        }
        return value ?? null;
      };

      // Process each field, maintaining structure
      Object.entries(data).forEach(([key, value]) => {
        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
          processed[key] = processFormData(value); // Recursively process nested objects
        } else {
          processed[key] = processValue(value);
        }
      });

      return processed;
    };


    // Create the new payload structure
    const payload = {      
         
      primaryCategoryId: SCHEMA_METADATA.id,
      schemaFile: SCHEMA_METADATA.schemaFile,
      data: JSON.stringify(processFormData(formData)),
    };
    console.log('Payload being sent:', JSON.stringify(payload, null, 2));

    try {
      const response = await fetch('https://adzmonster.com/api/ads/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log('Success:', result);
      // Clear form data after successful submission
      resetForm();
    } catch (error) {
      console.error('Error:', error);
    }
  }, [formData, resetForm]);
  return (
    <div className="min-h-screen from-blue-50/50 to-white flex items-center justify-center p-4 sm:p-6 md:p-8 lg:p-9">
      <form onSubmit={handleSubmit} className="w-full max-w-4xl mx-auto bg-white backdrop-blur-lg bg-opacity-95 rounded-2xl shadow-xl border border-white border-opacity-20 p-4 sm:p-6 md:p-8 transition-all duration-300 hover:shadow-2xl">
        {/* Progress Bar */}
        <div className="mb-8">
          <div className="w-full bg-gray-100 rounded-full h-3 overflow-hidden shadow-inner">
            <div
              className="bg-gradient-to-r from-blue-500 to-purple-500 h-3 rounded-full transition-all duration-500 ease-in-out"
              style={{ width: `${((currentPage + 1) / totalPages) * 100}%` }}
            />
          </div>
          <div className="flex items-center justify-center px-4 py-2 bg-white rounded-lg">
            <span className="text-sm sm:text-base font-medium text-gray-700">
                  Step {currentPage + 1} of {totalPages}
            </span>
          </div>
        </div>
        {/* Action Buttons */}
        <div className="flex flex-col sm:flex-row justify-between items-center gap-4 mb-8">
          {/* Navigation Links */}
          <button
            type="button"
            onClick={() => navigate('/v1')}
            className="group flex items-center px-4 py-2 text-sm font-medium text-gray-700 hover:text-blue-600 transition-all duration-200"
          >
            <svg 
              className="w-4 h-4 mr-2 transform transition-transform group-hover:-translate-x-1" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
                    Back to Home
          </button>
        
          {/* Reset Button */}
          <button
            type="button"
            onClick={resetForm}
            className="flex items-center px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-red-500 to-red-600 rounded-xl 
                      transform transition-all duration-200 
                      hover:from-red-600 hover:to-red-700 hover:shadow-md 
                      active:scale-95
                      focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
          >
            <svg 
              className="w-4 h-4 mr-2" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
            </svg>
                      Reset Form
          </button>
        </div>
                
        {currentPage === 0 && (
          <div key="0" className="space-y-4">
                    
            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              basicInformation
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <fieldset>
                    <legend className="text-sm font-medium text-gray-700 mb-3">
            utilities
                    </legend>
                    <div className="grid grid-cols-2 sm:grid-cols-1 lg:grid-cols-3  gap-x-6 gap-y-2 items-center justify-center">
            
                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="utilities"
                          value="Internet Connectivity"
                          checked={Array.isArray(getNestedValue(formData, 'utilities')) && 
                    getNestedValue(formData, 'utilities').includes('Internet Connectivity')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'utilities')) 
                              ? getNestedValue(formData, 'utilities') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Internet Connectivity']
                              : currentValues.filter((val: string) => val !== 'Internet Connectivity');
                            setFormData((prev) => setNestedValue({ ...prev }, 'utilities', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Internet Connectivity
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="utilities"
                          value="Sewage System"
                          checked={Array.isArray(getNestedValue(formData, 'utilities')) && 
                    getNestedValue(formData, 'utilities').includes('Sewage System')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'utilities')) 
                              ? getNestedValue(formData, 'utilities') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Sewage System']
                              : currentValues.filter((val: string) => val !== 'Sewage System');
                            setFormData((prev) => setNestedValue({ ...prev }, 'utilities', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Sewage System
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="utilities"
                          value="Electricity Connection"
                          checked={Array.isArray(getNestedValue(formData, 'utilities')) && 
                    getNestedValue(formData, 'utilities').includes('Electricity Connection')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'utilities')) 
                              ? getNestedValue(formData, 'utilities') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Electricity Connection']
                              : currentValues.filter((val: string) => val !== 'Electricity Connection');
                            setFormData((prev) => setNestedValue({ ...prev }, 'utilities', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Electricity Connection
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="utilities"
                          value="Water Connection Available"
                          checked={Array.isArray(getNestedValue(formData, 'utilities')) && 
                    getNestedValue(formData, 'utilities').includes('Water Connection Available')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'utilities')) 
                              ? getNestedValue(formData, 'utilities') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Water Connection Available']
                              : currentValues.filter((val: string) => val !== 'Water Connection Available');
                            setFormData((prev) => setNestedValue({ ...prev }, 'utilities', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Water Connection Available
                        </span>
                      </label>
            
                    </div>
                  </fieldset>
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Unique Room Identifier
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="roomId"
                      value={getNestedValue(formData, 'roomId') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter unique room identifier..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      pattern="^RM-[0-9]{6}$" required
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
          Type of Listing
                    </label>
                    <div className="relative group">
                      <select
                        name="listingType"
                        value={getNestedValue(formData, 'listingType') ?? ''}
                        onChange={handleChange}
                        className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
                        required
                      >
                        <option value="" className="text-gray-500">Select listingType</option>
                        <option key="Entire Room" value="Entire Room">Entire Room</option>
                        <option key="Shared Room" value="Shared Room">Shared Room</option>
                        <option key="PG/Hostel" value="PG/Hostel">PG/Hostel</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Room Title
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="title"
                      value={getNestedValue(formData, 'title') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter room title..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      minLength={3} maxLength={100} required
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Room Description
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="textarea"
                      name="description"
                      value={getNestedValue(formData, 'description') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter room description..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
          
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
          Type of Building
                    </label>
                    <div className="relative group">
                      <select
                        name="buildingType"
                        value={getNestedValue(formData, 'buildingType') ?? ''}
                        onChange={handleChange}
                        className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
          
                      >
                        <option value="" className="text-gray-500">Select buildingType</option>
                        <option key="Apartment" value="Apartment">Apartment</option>
                        <option key="Independent House" value="Independent House">Independent House</option>
                        <option key="Villa" value="Villa">Villa</option>
                        <option key="Gated Community" value="Gated Community">Gated Community</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Floor Number
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="floorNumber"
                      value={getNestedValue(formData, 'floorNumber') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter floor number..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="0"
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Total Floors in Building
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="totalFloors"
                      value={getNestedValue(formData, 'totalFloors') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter total floors in building..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="1"
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Available From Date
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="date"
                      name="availableFrom"
                      value={getNestedValue(formData, 'availableFrom') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter available from date..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      required
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-4">
                  <label className="inline-flex items-start">
                    <input
                      type="checkbox"
                      name="immediateAvailability"
                      checked={Boolean(getNestedValue(formData, 'immediateAvailability'))}
                      onChange={handleChange}
                      className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                    />
                    <span className="ml-2 text-sm text-gray-600">
          Available Immediately
                    </span>
                  </label>
                </div>

                <div className="mb-6">
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
          Posted By
                    </label>
                    <div className="relative group">
                      <select
                        name="postedBy"
                        value={getNestedValue(formData, 'postedBy') ?? ''}
                        onChange={handleChange}
                        className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
                        required
                      >
                        <option value="" className="text-gray-500">Select postedBy</option>
                        <option key="Owner" value="Owner">Owner</option>
                        <option key="Agent" value="Agent">Agent</option>
                        <option key="Property Manager" value="Property Manager">Property Manager</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              location
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Address Line 1
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="addressLine1"
                      value={getNestedValue(formData, 'addressLine1') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter address line 1..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      required
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Address Line 2
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="addressLine2"
                      value={getNestedValue(formData, 'addressLine2') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter address line 2..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
          
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Nearest Landmark
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="landmark"
                      value={getNestedValue(formData, 'landmark') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter nearest landmark..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
          
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Locality/Area
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="locality"
                      value={getNestedValue(formData, 'locality') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter locality/area..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      required
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          City
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="city"
                      value={getNestedValue(formData, 'city') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter city..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      required
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          State
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="select"
                      name="state"
                      value={getNestedValue(formData, 'state') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter state..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      required
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                    <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              distances
                    </h3>
                  </div>
                  <div className="space-y-4 px-6">
          
                    <div className="mb-6">
                      <div className="flex justify-between items-center mb-2">
                        <label className="block text-sm font-medium text-gray-700">
          Distance from Metro (km)
          
                        </label>
        
                      </div>
      
                      <div className="relative">
                        <input
                          type="number"
                          name="metro"
                          value={getNestedValue(formData, 'metro') ?? ''}
                          onChange={handleChange}
                          placeholder="Enter distance from metro (km)..."
                          className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                          min="0"
                        />
        
        
                      </div>
      
      
      
      
                    </div>

                    <div className="mb-6">
                      <div className="flex justify-between items-center mb-2">
                        <label className="block text-sm font-medium text-gray-700">
          Distance from Bus Stop (km)
          
                        </label>
        
                      </div>
      
                      <div className="relative">
                        <input
                          type="number"
                          name="busStop"
                          value={getNestedValue(formData, 'busStop') ?? ''}
                          onChange={handleChange}
                          placeholder="Enter distance from bus stop (km)..."
                          className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                          min="0"
                        />
        
        
                      </div>
      
      
      
      
                    </div>

                    <div className="mb-6">
                      <div className="flex justify-between items-center mb-2">
                        <label className="block text-sm font-medium text-gray-700">
          Distance from Market (km)
          
                        </label>
        
                      </div>
      
                      <div className="relative">
                        <input
                          type="number"
                          name="market"
                          value={getNestedValue(formData, 'market') ?? ''}
                          onChange={handleChange}
                          placeholder="Enter distance from market (km)..."
                          className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                          min="0"
                        />
        
        
                      </div>
      
      
      
      
                    </div>

                    <div className="mb-6">
                      <div className="flex justify-between items-center mb-2">
                        <label className="block text-sm font-medium text-gray-700">
          Distance from Hospital (km)
          
                        </label>
        
                      </div>
      
                      <div className="relative">
                        <input
                          type="number"
                          name="hospital"
                          value={getNestedValue(formData, 'hospital') ?? ''}
                          onChange={handleChange}
                          placeholder="Enter distance from hospital (km)..."
                          className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                          min="0"
                        />
        
        
                      </div>
      
      
      
      
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              roomSpecifications
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
          Room Type
                    </label>
                    <div className="relative group">
                      <select
                        name="roomType"
                        value={getNestedValue(formData, 'roomType') ?? ''}
                        onChange={handleChange}
                        className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
                        required
                      >
                        <option value="" className="text-gray-500">Select roomType</option>
                        <option key="Single Room" value="Single Room">Single Room</option>
                        <option key="Double Room" value="Double Room">Double Room</option>
                        <option key="Triple Room" value="Triple Room">Triple Room</option>
                        <option key="Studio Room" value="Studio Room">Studio Room</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                    <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              dimensions
                    </h3>
                  </div>
                  <div className="space-y-4 px-6">
          
                    <div className="mb-6">
                      <div className="flex justify-between items-center mb-2">
                        <label className="block text-sm font-medium text-gray-700">
          Room Length (feet)
          
                        </label>
        
                      </div>
      
                      <div className="relative">
                        <input
                          type="number"
                          name="length"
                          value={getNestedValue(formData, 'length') ?? ''}
                          onChange={handleChange}
                          placeholder="Enter room length (feet)..."
                          className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                          min="0"
                        />
        
        
                      </div>
      
      
      
      
                    </div>

                    <div className="mb-6">
                      <div className="flex justify-between items-center mb-2">
                        <label className="block text-sm font-medium text-gray-700">
          Room Width (feet)
          
                        </label>
        
                      </div>
      
                      <div className="relative">
                        <input
                          type="number"
                          name="width"
                          value={getNestedValue(formData, 'width') ?? ''}
                          onChange={handleChange}
                          placeholder="Enter room width (feet)..."
                          className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                          min="0"
                        />
        
        
                      </div>
      
      
      
      
                    </div>

                    <div className="mb-6">
                      <div className="flex justify-between items-center mb-2">
                        <label className="block text-sm font-medium text-gray-700">
          Room Area (sq ft)
          
                        </label>
        
                      </div>
      
                      <div className="relative">
                        <input
                          type="number"
                          name="area"
                          value={getNestedValue(formData, 'area') ?? ''}
                          onChange={handleChange}
                          placeholder="Enter room area (sq ft)..."
                          className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                          min="0"
                        />
        
        
                      </div>
      
      
      
      
                    </div>
                  </div>
                </div>

                <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                    <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              bathroom
                    </h3>
                  </div>
                  <div className="space-y-4 px-6">
          
                    <div className="mb-6">
                      <fieldset className="w-full">
                        <legend className="text-sm font-medium text-gray-700 mb-3">
            Bathroom Type
                        </legend>
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
            
                          <label className="group relative block">
                            <input
                              type="radio"
                              name="type"
                              value="Attached"
                              checked={getNestedValue(formData, 'type') === 'Attached'}
                              onChange={handleChange}
                              className="peer sr-only"
                    
                            />
                            <div className="relative rounded-xl p-3 border-2 cursor-pointer
                  bg-gradient-to-b from-white to-gray-50
                  border-gray-200 
                  peer-checked:border-blue-500 peer-checked:from-blue-50 peer-checked:to-blue-50
                  peer-focus:ring-2 peer-focus:ring-blue-500 peer-focus:ring-offset-2
                  group-hover:border-blue-200 group-hover:shadow-sm
                  transition-all duration-200">
                              <div className="flex items-center justify-between">
                                <span className="block text-sm font-medium text-gray-900">
                      Attached
                                </span>
                              </div>
                            </div>
                          </label>
              

                          <label className="group relative block">
                            <input
                              type="radio"
                              name="type"
                              value="Common"
                              checked={getNestedValue(formData, 'type') === 'Common'}
                              onChange={handleChange}
                              className="peer sr-only"
                    
                            />
                            <div className="relative rounded-xl p-3 border-2 cursor-pointer
                  bg-gradient-to-b from-white to-gray-50
                  border-gray-200 
                  peer-checked:border-blue-500 peer-checked:from-blue-50 peer-checked:to-blue-50
                  peer-focus:ring-2 peer-focus:ring-blue-500 peer-focus:ring-offset-2
                  group-hover:border-blue-200 group-hover:shadow-sm
                  transition-all duration-200">
                              <div className="flex items-center justify-between">
                                <span className="block text-sm font-medium text-gray-900">
                      Common
                                </span>
                              </div>
                            </div>
                          </label>
              

                          <label className="group relative block">
                            <input
                              type="radio"
                              name="type"
                              value="Shared"
                              checked={getNestedValue(formData, 'type') === 'Shared'}
                              onChange={handleChange}
                              className="peer sr-only"
                    
                            />
                            <div className="relative rounded-xl p-3 border-2 cursor-pointer
                  bg-gradient-to-b from-white to-gray-50
                  border-gray-200 
                  peer-checked:border-blue-500 peer-checked:from-blue-50 peer-checked:to-blue-50
                  peer-focus:ring-2 peer-focus:ring-blue-500 peer-focus:ring-offset-2
                  group-hover:border-blue-200 group-hover:shadow-sm
                  transition-all duration-200">
                              <div className="flex items-center justify-between">
                                <span className="block text-sm font-medium text-gray-900">
                      Shared
                                </span>
                              </div>
                            </div>
                          </label>
              
                        </div>
                      </fieldset>
                    </div>

                    <div className="mb-6">
                      <div className="flex justify-between items-center mb-2">
                        <label className="block text-sm font-medium text-gray-700">
          Number of Bathrooms
          
                        </label>
        
                      </div>
      
                      <div className="relative">
                        <input
                          type="number"
                          name="count"
                          value={getNestedValue(formData, 'count') ?? ''}
                          onChange={handleChange}
                          placeholder="Enter number of bathrooms..."
                          className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                          min="1"
                        />
        
        
                      </div>
      
      
      
      
                    </div>
                  </div>
                </div>

                <div className="mb-4">
                  <label className="inline-flex items-start">
                    <input
                      type="checkbox"
                      name="balcony"
                      checked={Boolean(getNestedValue(formData, 'balcony'))}
                      onChange={handleChange}
                      className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                    />
                    <span className="ml-2 text-sm text-gray-600">
          Has Balcony
                    </span>
                  </label>
                </div>

                <div className="mb-6">
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
          Room Direction
                    </label>
                    <div className="relative group">
                      <select
                        name="direction"
                        value={getNestedValue(formData, 'direction') ?? ''}
                        onChange={handleChange}
                        className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
          
                      >
                        <option value="" className="text-gray-500">Select direction</option>
                        <option key="North" value="North">North</option>
                        <option key="South" value="South">South</option>
                        <option key="East" value="East">East</option>
                        <option key="West" value="West">West</option>
                        <option key="North-East" value="North-East">North-East</option>
                        <option key="North-West" value="North-West">North-West</option>
                        <option key="South-East" value="South-East">South-East</option>
                        <option key="South-West" value="South-West">South-West</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="mb-6">
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
          Ventilation Rating
                    </label>
                    <div className="relative group">
                      <select
                        name="ventilation"
                        value={getNestedValue(formData, 'ventilation') ?? ''}
                        onChange={handleChange}
                        className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
          
                      >
                        <option value="" className="text-gray-500">Select ventilation</option>
                        <option key="Excellent" value="Excellent">Excellent</option>
                        <option key="Good" value="Good">Good</option>
                        <option key="Average" value="Average">Average</option>
                        <option key="Poor" value="Poor">Poor</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Number of Windows
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="windowCount"
                      value={getNestedValue(formData, 'windowCount') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter number of windows..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="0"
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Ceiling Height (feet)
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="ceilingHeight"
                      value={getNestedValue(formData, 'ceilingHeight') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter ceiling height (feet)..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="0"
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
          Floor Type
                    </label>
                    <div className="relative group">
                      <select
                        name="floorType"
                        value={getNestedValue(formData, 'floorType') ?? ''}
                        onChange={handleChange}
                        className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
          
                      >
                        <option value="" className="text-gray-500">Select floorType</option>
                        <option key="Marble" value="Marble">Marble</option>
                        <option key="Tile" value="Tile">Tile</option>
                        <option key="Wood" value="Wood">Wood</option>
                        <option key="Concrete" value="Concrete">Concrete</option>
                        <option key="Other" value="Other">Other</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {currentPage === 1 && (
          <div key="1" className="space-y-4">
                    
            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              pricing
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Monthly Rent
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="monthlyRent"
                      value={getNestedValue(formData, 'monthlyRent') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter monthly rent..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="0" required
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Security Deposit
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="securityDeposit"
                      value={getNestedValue(formData, 'securityDeposit') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter security deposit..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="0" required
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Maintenance Charges
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="maintenanceCharges"
                      value={getNestedValue(formData, 'maintenanceCharges') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter maintenance charges..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="0"
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                    <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              utilities
                    </h3>
                  </div>
                  <div className="space-y-4 px-6">
          
                    <div className="mb-6">
                      <div className="relative">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
          Electricity Charges
                        </label>
                        <div className="relative group">
                          <select
                            name="electricity"
                            value={getNestedValue(formData, 'electricity') ?? ''}
                            onChange={handleChange}
                            className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
          
                          >
                            <option value="" className="text-gray-500">Select electricity</option>
                            <option key="Included" value="Included">Included</option>
                            <option key="As per usage" value="As per usage">As per usage</option>
                            <option key="Fixed" value="Fixed">Fixed</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="mb-6">
                      <div className="relative">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
          Water Charges
                        </label>
                        <div className="relative group">
                          <select
                            name="water"
                            value={getNestedValue(formData, 'water') ?? ''}
                            onChange={handleChange}
                            className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
          
                          >
                            <option value="" className="text-gray-500">Select water</option>
                            <option key="Included" value="Included">Included</option>
                            <option key="As per usage" value="As per usage">As per usage</option>
                            <option key="Fixed" value="Fixed">Fixed</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="mb-6">
                      <div className="relative">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
          Gas Charges
                        </label>
                        <div className="relative group">
                          <select
                            name="gas"
                            value={getNestedValue(formData, 'gas') ?? ''}
                            onChange={handleChange}
                            className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
          
                          >
                            <option value="" className="text-gray-500">Select gas</option>
                            <option key="Included" value="Included">Included</option>
                            <option key="As per usage" value="As per usage">As per usage</option>
                            <option key="Fixed" value="Fixed">Fixed</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="mb-6">
                      <div className="relative">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
          Internet Charges
                        </label>
                        <div className="relative group">
                          <select
                            name="internet"
                            value={getNestedValue(formData, 'internet') ?? ''}
                            onChange={handleChange}
                            className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
          
                          >
                            <option value="" className="text-gray-500">Select internet</option>
                            <option key="Included" value="Included">Included</option>
                            <option key="As per usage" value="As per usage">As per usage</option>
                            <option key="Fixed" value="Fixed">Fixed</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-4">
                  <label className="inline-flex items-start">
                    <input
                      type="checkbox"
                      name="negotiable"
                      checked={Boolean(getNestedValue(formData, 'negotiable'))}
                      onChange={handleChange}
                      className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                    />
                    <span className="ml-2 text-sm text-gray-600">
          Rent Negotiable
                    </span>
                  </label>
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Minimum Stay (months)
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="minimumStay"
                      value={getNestedValue(formData, 'minimumStay') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter minimum stay (months)..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="0"
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Notice Period (days)
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="noticePeriod"
                      value={getNestedValue(formData, 'noticePeriod') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter notice period (days)..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="0"
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Lock-in Period (months)
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="lockInPeriod"
                      value={getNestedValue(formData, 'lockInPeriod') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter lock-in period (months)..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="0"
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Advance Rent (months)
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="advanceRentMonths"
                      value={getNestedValue(formData, 'advanceRentMonths') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter advance rent (months)..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="0" max="12"
                    />
        
        
                  </div>
      
      
      
      
                </div>
              </div>
            </div>

            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              furnishing
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <fieldset className="w-full">
                    <legend className="text-sm font-medium text-gray-700 mb-3">
            Furnishing Status
                    </legend>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
            
                      <label className="group relative block">
                        <input
                          type="radio"
                          name="status"
                          value="Fully Furnished"
                          checked={getNestedValue(formData, 'status') === 'Fully Furnished'}
                          onChange={handleChange}
                          className="peer sr-only"
                          required
                        />
                        <div className="relative rounded-xl p-3 border-2 cursor-pointer
                  bg-gradient-to-b from-white to-gray-50
                  border-gray-200 
                  peer-checked:border-blue-500 peer-checked:from-blue-50 peer-checked:to-blue-50
                  peer-focus:ring-2 peer-focus:ring-blue-500 peer-focus:ring-offset-2
                  group-hover:border-blue-200 group-hover:shadow-sm
                  transition-all duration-200">
                          <div className="flex items-center justify-between">
                            <span className="block text-sm font-medium text-gray-900">
                      Fully Furnished
                            </span>
                          </div>
                        </div>
                      </label>
              

                      <label className="group relative block">
                        <input
                          type="radio"
                          name="status"
                          value="Semi Furnished"
                          checked={getNestedValue(formData, 'status') === 'Semi Furnished'}
                          onChange={handleChange}
                          className="peer sr-only"
                          required
                        />
                        <div className="relative rounded-xl p-3 border-2 cursor-pointer
                  bg-gradient-to-b from-white to-gray-50
                  border-gray-200 
                  peer-checked:border-blue-500 peer-checked:from-blue-50 peer-checked:to-blue-50
                  peer-focus:ring-2 peer-focus:ring-blue-500 peer-focus:ring-offset-2
                  group-hover:border-blue-200 group-hover:shadow-sm
                  transition-all duration-200">
                          <div className="flex items-center justify-between">
                            <span className="block text-sm font-medium text-gray-900">
                      Semi Furnished
                            </span>
                          </div>
                        </div>
                      </label>
              

                      <label className="group relative block">
                        <input
                          type="radio"
                          name="status"
                          value="Unfurnished"
                          checked={getNestedValue(formData, 'status') === 'Unfurnished'}
                          onChange={handleChange}
                          className="peer sr-only"
                          required
                        />
                        <div className="relative rounded-xl p-3 border-2 cursor-pointer
                  bg-gradient-to-b from-white to-gray-50
                  border-gray-200 
                  peer-checked:border-blue-500 peer-checked:from-blue-50 peer-checked:to-blue-50
                  peer-focus:ring-2 peer-focus:ring-blue-500 peer-focus:ring-offset-2
                  group-hover:border-blue-200 group-hover:shadow-sm
                  transition-all duration-200">
                          <div className="flex items-center justify-between">
                            <span className="block text-sm font-medium text-gray-900">
                      Unfurnished
                            </span>
                          </div>
                        </div>
                      </label>
              
                    </div>
                  </fieldset>
                </div>

                <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                    <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              items
                    </h3>
                  </div>
                  <div className="space-y-4 px-6">
          
                    <div className="mb-6">
                      <div className="relative">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
          Bed Type
                        </label>
                        <div className="relative group">
                          <select
                            name="bed"
                            value={getNestedValue(formData, 'bed') ?? ''}
                            onChange={handleChange}
                            className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
          
                          >
                            <option value="" className="text-gray-500">Select bed</option>
                            <option key="Single" value="Single">Single</option>
                            <option key="Double" value="Double">Double</option>
                            <option key="None" value="None">None</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="mb-4">
                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="wardrobe"
                          checked={Boolean(getNestedValue(formData, 'wardrobe'))}
                          onChange={handleChange}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
          Has Wardrobe
                        </span>
                      </label>
                    </div>

                    <div className="mb-4">
                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="ac"
                          checked={Boolean(getNestedValue(formData, 'ac'))}
                          onChange={handleChange}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
          Has AC
                        </span>
                      </label>
                    </div>

                    <div className="mb-4">
                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="fan"
                          checked={Boolean(getNestedValue(formData, 'fan'))}
                          onChange={handleChange}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
          Has Fan
                        </span>
                      </label>
                    </div>

                    <div className="mb-4">
                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="geyser"
                          checked={Boolean(getNestedValue(formData, 'geyser'))}
                          onChange={handleChange}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
          Has Geyser
                        </span>
                      </label>
                    </div>

                    <div className="mb-4">
                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="washingMachine"
                          checked={Boolean(getNestedValue(formData, 'washingMachine'))}
                          onChange={handleChange}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
          Has Washing Machine
                        </span>
                      </label>
                    </div>

                    <div className="mb-4">
                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="refrigerator"
                          checked={Boolean(getNestedValue(formData, 'refrigerator'))}
                          onChange={handleChange}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
          Has Refrigerator
                        </span>
                      </label>
                    </div>

                    <div className="mb-4">
                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="tv"
                          checked={Boolean(getNestedValue(formData, 'tv'))}
                          onChange={handleChange}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
          Has TV
                        </span>
                      </label>
                    </div>

                    <div className="mb-4">
                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="studyTable"
                          checked={Boolean(getNestedValue(formData, 'studyTable'))}
                          onChange={handleChange}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
          Has Study Table
                        </span>
                      </label>
                    </div>

                    <div className="mb-4">
                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="chair"
                          checked={Boolean(getNestedValue(formData, 'chair'))}
                          onChange={handleChange}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
          Has Chair
                        </span>
                      </label>
                    </div>

                    <div className="mb-4">
                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="curtains"
                          checked={Boolean(getNestedValue(formData, 'curtains'))}
                          onChange={handleChange}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
          Has Curtains
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              amenities
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-4">
                  <label className="inline-flex items-start">
                    <input
                      type="checkbox"
                      name="powerBackup"
                      checked={Boolean(getNestedValue(formData, 'powerBackup'))}
                      onChange={handleChange}
                      className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                    />
                    <span className="ml-2 text-sm text-gray-600">
          Has Power Backup
                    </span>
                  </label>
                </div>

                <div className="mb-4">
                  <label className="inline-flex items-start">
                    <input
                      type="checkbox"
                      name="lift"
                      checked={Boolean(getNestedValue(formData, 'lift'))}
                      onChange={handleChange}
                      className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                    />
                    <span className="ml-2 text-sm text-gray-600">
          Has Lift
                    </span>
                  </label>
                </div>

                <div className="mb-4">
                  <label className="inline-flex items-start">
                    <input
                      type="checkbox"
                      name="parking"
                      checked={Boolean(getNestedValue(formData, 'parking'))}
                      onChange={handleChange}
                      className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                    />
                    <span className="ml-2 text-sm text-gray-600">
          Has Parking
                    </span>
                  </label>
                </div>

                <div className="mb-4">
                  <label className="inline-flex items-start">
                    <input
                      type="checkbox"
                      name="security"
                      checked={Boolean(getNestedValue(formData, 'security'))}
                      onChange={handleChange}
                      className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                    />
                    <span className="ml-2 text-sm text-gray-600">
          Has Security
                    </span>
                  </label>
                </div>

                <div className="mb-4">
                  <label className="inline-flex items-start">
                    <input
                      type="checkbox"
                      name="cctv"
                      checked={Boolean(getNestedValue(formData, 'cctv'))}
                      onChange={handleChange}
                      className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                    />
                    <span className="ml-2 text-sm text-gray-600">
          Has CCTV
                    </span>
                  </label>
                </div>

                <div className="mb-4">
                  <label className="inline-flex items-start">
                    <input
                      type="checkbox"
                      name="water24x7"
                      checked={Boolean(getNestedValue(formData, 'water24x7'))}
                      onChange={handleChange}
                      className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                    />
                    <span className="ml-2 text-sm text-gray-600">
          24x7 Water Supply
                    </span>
                  </label>
                </div>

                <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                    <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              kitchen
                    </h3>
                  </div>
                  <div className="space-y-4 px-6">
          
                    <div className="mb-4">
                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="available"
                          checked={Boolean(getNestedValue(formData, 'available'))}
                          onChange={handleChange}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
          Kitchen Available
                        </span>
                      </label>
                    </div>

                    <div className="mb-6">
                      <div className="relative">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
          Kitchen Type
                        </label>
                        <div className="relative group">
                          <select
                            name="type"
                            value={getNestedValue(formData, 'type') ?? ''}
                            onChange={handleChange}
                            className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
          
                          >
                            <option value="" className="text-gray-500">Select type</option>
                            <option key="Private" value="Private">Private</option>
                            <option key="Shared" value="Shared">Shared</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="mb-4">
                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="modular"
                          checked={Boolean(getNestedValue(formData, 'modular'))}
                          onChange={handleChange}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
          Is Modular Kitchen
                        </span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="mb-4">
                  <label className="inline-flex items-start">
                    <input
                      type="checkbox"
                      name="waterPurifier"
                      checked={Boolean(getNestedValue(formData, 'waterPurifier'))}
                      onChange={handleChange}
                      className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                    />
                    <span className="ml-2 text-sm text-gray-600">
          Has Water Purifier
                    </span>
                  </label>
                </div>

                <div className="mb-4">
                  <label className="inline-flex items-start">
                    <input
                      type="checkbox"
                      name="gasPipeline"
                      checked={Boolean(getNestedValue(formData, 'gasPipeline'))}
                      onChange={handleChange}
                      className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                    />
                    <span className="ml-2 text-sm text-gray-600">
          Has Gas Pipeline
                    </span>
                  </label>
                </div>

                <div className="mb-4">
                  <label className="inline-flex items-start">
                    <input
                      type="checkbox"
                      name="internet"
                      checked={Boolean(getNestedValue(formData, 'internet'))}
                      onChange={handleChange}
                      className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                    />
                    <span className="ml-2 text-sm text-gray-600">
          Has Internet Connection
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        )}

        {currentPage === 2 && (
          <div key="2" className="space-y-4">
                    
            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              rules
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <fieldset className="w-full">
                    <legend className="text-sm font-medium text-gray-700 mb-3">
            Gender Preference
                    </legend>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
            
                      <label className="group relative block">
                        <input
                          type="radio"
                          name="genderPreference"
                          value="Male Only"
                          checked={getNestedValue(formData, 'genderPreference') === 'Male Only'}
                          onChange={handleChange}
                          className="peer sr-only"
                          required
                        />
                        <div className="relative rounded-xl p-3 border-2 cursor-pointer
                  bg-gradient-to-b from-white to-gray-50
                  border-gray-200 
                  peer-checked:border-blue-500 peer-checked:from-blue-50 peer-checked:to-blue-50
                  peer-focus:ring-2 peer-focus:ring-blue-500 peer-focus:ring-offset-2
                  group-hover:border-blue-200 group-hover:shadow-sm
                  transition-all duration-200">
                          <div className="flex items-center justify-between">
                            <span className="block text-sm font-medium text-gray-900">
                      Male Only
                            </span>
                          </div>
                        </div>
                      </label>
              

                      <label className="group relative block">
                        <input
                          type="radio"
                          name="genderPreference"
                          value="Female Only"
                          checked={getNestedValue(formData, 'genderPreference') === 'Female Only'}
                          onChange={handleChange}
                          className="peer sr-only"
                          required
                        />
                        <div className="relative rounded-xl p-3 border-2 cursor-pointer
                  bg-gradient-to-b from-white to-gray-50
                  border-gray-200 
                  peer-checked:border-blue-500 peer-checked:from-blue-50 peer-checked:to-blue-50
                  peer-focus:ring-2 peer-focus:ring-blue-500 peer-focus:ring-offset-2
                  group-hover:border-blue-200 group-hover:shadow-sm
                  transition-all duration-200">
                          <div className="flex items-center justify-between">
                            <span className="block text-sm font-medium text-gray-900">
                      Female Only
                            </span>
                          </div>
                        </div>
                      </label>
              

                      <label className="group relative block">
                        <input
                          type="radio"
                          name="genderPreference"
                          value="No Preference"
                          checked={getNestedValue(formData, 'genderPreference') === 'No Preference'}
                          onChange={handleChange}
                          className="peer sr-only"
                          required
                        />
                        <div className="relative rounded-xl p-3 border-2 cursor-pointer
                  bg-gradient-to-b from-white to-gray-50
                  border-gray-200 
                  peer-checked:border-blue-500 peer-checked:from-blue-50 peer-checked:to-blue-50
                  peer-focus:ring-2 peer-focus:ring-blue-500 peer-focus:ring-offset-2
                  group-hover:border-blue-200 group-hover:shadow-sm
                  transition-all duration-200">
                          <div className="flex items-center justify-between">
                            <span className="block text-sm font-medium text-gray-900">
                      No Preference
                            </span>
                          </div>
                        </div>
                      </label>
              
                    </div>
                  </fieldset>
                </div>

                <div className="mb-4">
                  <label className="inline-flex items-start">
                    <input
                      type="checkbox"
                      name="preferredTenants"
                      checked={Boolean(getNestedValue(formData, 'preferredTenants'))}
                      onChange={handleChange}
                      className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                    />
                    <span className="ml-2 text-sm text-gray-600">
          Preferred Tenants
                    </span>
                  </label>
                </div>

                <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                    <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              allowances
                    </h3>
                  </div>
                  <div className="space-y-4 px-6">
          
                    <div className="mb-4">
                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="couples"
                          checked={Boolean(getNestedValue(formData, 'couples'))}
                          onChange={handleChange}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
          Couples Allowed
                        </span>
                      </label>
                    </div>

                    <div className="mb-4">
                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="nonVeg"
                          checked={Boolean(getNestedValue(formData, 'nonVeg'))}
                          onChange={handleChange}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
          Non-Veg Allowed
                        </span>
                      </label>
                    </div>

                    <div className="mb-4">
                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="pets"
                          checked={Boolean(getNestedValue(formData, 'pets'))}
                          onChange={handleChange}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
          Pets Allowed
                        </span>
                      </label>
                    </div>

                    <div className="mb-4">
                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="smoking"
                          checked={Boolean(getNestedValue(formData, 'smoking'))}
                          onChange={handleChange}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
          Smoking Allowed
                        </span>
                      </label>
                    </div>

                    <div className="mb-4">
                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="drinking"
                          checked={Boolean(getNestedValue(formData, 'drinking'))}
                          onChange={handleChange}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
          Drinking Allowed
                        </span>
                      </label>
                    </div>

                    <div className="mb-4">
                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="guests"
                          checked={Boolean(getNestedValue(formData, 'guests'))}
                          onChange={handleChange}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
          Guests Allowed
                        </span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                    <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              restrictions
                    </h3>
                  </div>
                  <div className="space-y-4 px-6">
          
                    <div className="mb-6">
                      <div className="relative">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
          Guest Entry Time Restriction
                        </label>
                        <div className="relative group">
                          <select
                            name="guestEntryTime"
                            value={getNestedValue(formData, 'guestEntryTime') ?? ''}
                            onChange={handleChange}
                            className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
          
                          >
                            <option value="" className="text-gray-500">Select guestEntryTime</option>
                            <option key="No Restriction" value="No Restriction">No Restriction</option>
                            <option key="Before 10 PM" value="Before 10 PM">Before 10 PM</option>
                            <option key="Before 11 PM" value="Before 11 PM">Before 11 PM</option>
                            <option key="Not Allowed" value="Not Allowed">Not Allowed</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="mb-6">
                      <div className="relative">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
          Night Time Entry Restriction
                        </label>
                        <div className="relative group">
                          <select
                            name="nightTimeEntry"
                            value={getNestedValue(formData, 'nightTimeEntry') ?? ''}
                            onChange={handleChange}
                            className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
          
                          >
                            <option value="" className="text-gray-500">Select nightTimeEntry</option>
                            <option key="No Restriction" value="No Restriction">No Restriction</option>
                            <option key="Before 10 PM" value="Before 10 PM">Before 10 PM</option>
                            <option key="Before 11 PM" value="Before 11 PM">Before 11 PM</option>
                            <option key="Before 12 AM" value="Before 12 AM">Before 12 AM</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="mb-4">
                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="cooking"
                          checked={Boolean(getNestedValue(formData, 'cooking'))}
                          onChange={handleChange}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
          Cooking Allowed
                        </span>
                      </label>
                    </div>

                    <div className="mb-4">
                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="parties"
                          checked={Boolean(getNestedValue(formData, 'parties'))}
                          onChange={handleChange}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
          Parties/Events Allowed
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              services
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                    <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              food
                    </h3>
                  </div>
                  <div className="space-y-4 px-6">
          
                    <div className="mb-4">
                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="available"
                          checked={Boolean(getNestedValue(formData, 'available'))}
                          onChange={handleChange}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
          Food Service Available
                        </span>
                      </label>
                    </div>

                    <div className="mb-4">
                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="mealTypes"
                          checked={Boolean(getNestedValue(formData, 'mealTypes'))}
                          onChange={handleChange}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
          Meals Included
                        </span>
                      </label>
                    </div>

                    <div className="mb-6">
                      <div className="relative">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
          Food Type
                        </label>
                        <div className="relative group">
                          <select
                            name="foodType"
                            value={getNestedValue(formData, 'foodType') ?? ''}
                            onChange={handleChange}
                            className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
          
                          >
                            <option value="" className="text-gray-500">Select foodType</option>
                            <option key="Veg" value="Veg">Veg</option>
                            <option key="Non-veg" value="Non-veg">Non-veg</option>
                            <option key="Both" value="Both">Both</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="mb-6">
                      <div className="flex justify-between items-center mb-2">
                        <label className="block text-sm font-medium text-gray-700">
          Monthly Food Charges
          
                        </label>
        
                      </div>
      
                      <div className="relative">
                        <input
                          type="number"
                          name="charges"
                          value={getNestedValue(formData, 'charges') ?? ''}
                          onChange={handleChange}
                          placeholder="Enter monthly food charges..."
                          className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                          min="0"
                        />
        
        
                      </div>
      
      
      
      
                    </div>
                  </div>
                </div>

                <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                    <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              housekeeping
                    </h3>
                  </div>
                  <div className="space-y-4 px-6">
          
                    <div className="mb-4">
                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="included"
                          checked={Boolean(getNestedValue(formData, 'included'))}
                          onChange={handleChange}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
          Housekeeping Included
                        </span>
                      </label>
                    </div>

                    <div className="mb-6">
                      <div className="relative">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
          Cleaning Frequency
                        </label>
                        <div className="relative group">
                          <select
                            name="frequency"
                            value={getNestedValue(formData, 'frequency') ?? ''}
                            onChange={handleChange}
                            className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
          
                          >
                            <option value="" className="text-gray-500">Select frequency</option>
                            <option key="Daily" value="Daily">Daily</option>
                            <option key="Alternate Days" value="Alternate Days">Alternate Days</option>
                            <option key="Weekly" value="Weekly">Weekly</option>
                            <option key="Monthly" value="Monthly">Monthly</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-4">
                  <label className="inline-flex items-start">
                    <input
                      type="checkbox"
                      name="laundry"
                      checked={Boolean(getNestedValue(formData, 'laundry'))}
                      onChange={handleChange}
                      className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                    />
                    <span className="ml-2 text-sm text-gray-600">
          Laundry Service Available
                    </span>
                  </label>
                </div>

                <div className="mb-4">
                  <label className="inline-flex items-start">
                    <input
                      type="checkbox"
                      name="cook"
                      checked={Boolean(getNestedValue(formData, 'cook'))}
                      onChange={handleChange}
                      className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                    />
                    <span className="ml-2 text-sm text-gray-600">
          Cook Service Available
                    </span>
                  </label>
                </div>
              </div>
            </div>

            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              contact
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Contact Person Name
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="name"
                      value={getNestedValue(formData, 'name') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter contact person name..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      required
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Primary Phone Number
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="tel"
                      name="phone"
                      value={getNestedValue(formData, 'phone') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter primary phone number..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      pattern="^[0-9]{10}$" required
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Alternate Phone Number
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="tel"
                      name="alternatePhone"
                      value={getNestedValue(formData, 'alternatePhone') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter alternate phone number..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      pattern="^[0-9]{10}$"
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Email Address
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="email"
                      name="email"
                      value={getNestedValue(formData, 'email') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter email address..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
          
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
          Preferred Contact Method
                    </label>
                    <div className="relative group">
                      <select
                        name="preferredContact"
                        value={getNestedValue(formData, 'preferredContact') ?? ''}
                        onChange={handleChange}
                        className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
          
                      >
                        <option value="" className="text-gray-500">Select preferredContact</option>
                        <option key="Phone" value="Phone">Phone</option>
                        <option key="WhatsApp" value="WhatsApp">WhatsApp</option>
                        <option key="Email" value="Email">Email</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                    <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              availableHours
                    </h3>
                  </div>
                  <div className="space-y-4 px-6">
          
                    <div className="mb-6">
                      <div className="flex justify-between items-center mb-2">
                        <label className="block text-sm font-medium text-gray-700">
          Available From
          
                        </label>
        
                      </div>
      
                      <div className="relative">
                        <input
                          type="time"
                          name="from"
                          value={getNestedValue(formData, 'from') ?? ''}
                          onChange={handleChange}
                          placeholder="Enter available from..."
                          className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
          
                        />
        
        
                      </div>
      
      
      
      
                    </div>

                    <div className="mb-6">
                      <div className="flex justify-between items-center mb-2">
                        <label className="block text-sm font-medium text-gray-700">
          Available To
          
                        </label>
        
                      </div>
      
                      <div className="relative">
                        <input
                          type="time"
                          name="to"
                          value={getNestedValue(formData, 'to') ?? ''}
                          onChange={handleChange}
                          placeholder="Enter available to..."
                          className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
          
                        />
        
        
                      </div>
      
      
      
      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {currentPage === 3 && (
          <div key="3" className="space-y-4">
                    
            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              media
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
          Main Room Photo
                  </label>
                  <div className="group relative">
                    <div className="flex justify-center items-center w-full" >
                      <div className="relative w-full">
                        <input
                          type="file"
                          name="mainImage"
                          onChange={handleFileChange}
                          multiple
                          className="absolute inset-0 w-full h-full opacity-0 z-10 cursor-pointer"
                          required accept="image/*"
                        />
                        <div className="flex flex-col items-center w-full h-32 px-4 py-6 
                    bg-white text-gray-500 rounded-lg border-2 border-dashed border-gray-300 
                    transition-all duration-200 
                    hover:bg-gray-50 hover:border-blue-300
                    group-focus-within:border-blue-500 group-focus-within:ring-2 group-focus-within:ring-blue-500 group-focus-within:ring-offset-2">
                    
                          <div className="flex flex-col items-center pointer-events-none">
                            <svg className="w-8 h-8 mb-2 text-gray-400 group-hover:text-blue-500 transition-colors duration-200" 
                              fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                            </svg>
                      
                            <p className="text-sm text-gray-500 text-center group-hover:text-gray-600">
                              <span className="font-medium">Click to upload</span> or drag and drop
                            </p>
                      
                            <p className="text-xs text-gray-500 mt-1">
                        Accepted files: image/*
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {(getNestedValue(formData, 'mainImage') || []).length > 0 && (
                      <div className="space-y-2">
                        {(getNestedValue(formData, 'mainImage') || []).map((fileName: string, index: number) => (
                          <div key={index} className="flex items-center justify-between p-3 bg-blue-50 rounded-lg">
                            <div className="flex items-center">
                              <svg className="w-5 h-5 text-blue-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                              </svg>
                              <span className="text-sm font-medium text-blue-700">
                                {fileName}
                              </span>
                            </div>
          
                            <button
                              type="button"
                              onClick={() => removeFile('mainImage', index)}
                              className="p-1 text-blue-400 hover:text-blue-600 rounded-full hover:bg-blue-100 
              transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                              </svg>
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
          Bathroom Photo
                  </label>
                  <div className="group relative">
                    <div className="flex justify-center items-center w-full" >
                      <div className="relative w-full">
                        <input
                          type="file"
                          name="bathroomImage"
                          onChange={handleFileChange}
                          multiple
                          className="absolute inset-0 w-full h-full opacity-0 z-10 cursor-pointer"
                          accept="image/*"
                        />
                        <div className="flex flex-col items-center w-full h-32 px-4 py-6 
                    bg-white text-gray-500 rounded-lg border-2 border-dashed border-gray-300 
                    transition-all duration-200 
                    hover:bg-gray-50 hover:border-blue-300
                    group-focus-within:border-blue-500 group-focus-within:ring-2 group-focus-within:ring-blue-500 group-focus-within:ring-offset-2">
                    
                          <div className="flex flex-col items-center pointer-events-none">
                            <svg className="w-8 h-8 mb-2 text-gray-400 group-hover:text-blue-500 transition-colors duration-200" 
                              fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                            </svg>
                      
                            <p className="text-sm text-gray-500 text-center group-hover:text-gray-600">
                              <span className="font-medium">Click to upload</span> or drag and drop
                            </p>
                      
                            <p className="text-xs text-gray-500 mt-1">
                        Accepted files: image/*
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {(getNestedValue(formData, 'bathroomImage') || []).length > 0 && (
                      <div className="space-y-2">
                        {(getNestedValue(formData, 'bathroomImage') || []).map((fileName: string, index: number) => (
                          <div key={index} className="flex items-center justify-between p-3 bg-blue-50 rounded-lg">
                            <div className="flex items-center">
                              <svg className="w-5 h-5 text-blue-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                              </svg>
                              <span className="text-sm font-medium text-blue-700">
                                {fileName}
                              </span>
                            </div>
          
                            <button
                              type="button"
                              onClick={() => removeFile('bathroomImage', index)}
                              className="p-1 text-blue-400 hover:text-blue-600 rounded-full hover:bg-blue-100 
              transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                              </svg>
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
          Kitchen Photo
                  </label>
                  <div className="group relative">
                    <div className="flex justify-center items-center w-full" >
                      <div className="relative w-full">
                        <input
                          type="file"
                          name="kitchenImage"
                          onChange={handleFileChange}
                          multiple
                          className="absolute inset-0 w-full h-full opacity-0 z-10 cursor-pointer"
                          accept="image/*"
                        />
                        <div className="flex flex-col items-center w-full h-32 px-4 py-6 
                    bg-white text-gray-500 rounded-lg border-2 border-dashed border-gray-300 
                    transition-all duration-200 
                    hover:bg-gray-50 hover:border-blue-300
                    group-focus-within:border-blue-500 group-focus-within:ring-2 group-focus-within:ring-blue-500 group-focus-within:ring-offset-2">
                    
                          <div className="flex flex-col items-center pointer-events-none">
                            <svg className="w-8 h-8 mb-2 text-gray-400 group-hover:text-blue-500 transition-colors duration-200" 
                              fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                            </svg>
                      
                            <p className="text-sm text-gray-500 text-center group-hover:text-gray-600">
                              <span className="font-medium">Click to upload</span> or drag and drop
                            </p>
                      
                            <p className="text-xs text-gray-500 mt-1">
                        Accepted files: image/*
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {(getNestedValue(formData, 'kitchenImage') || []).length > 0 && (
                      <div className="space-y-2">
                        {(getNestedValue(formData, 'kitchenImage') || []).map((fileName: string, index: number) => (
                          <div key={index} className="flex items-center justify-between p-3 bg-blue-50 rounded-lg">
                            <div className="flex items-center">
                              <svg className="w-5 h-5 text-blue-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                              </svg>
                              <span className="text-sm font-medium text-blue-700">
                                {fileName}
                              </span>
                            </div>
          
                            <button
                              type="button"
                              onClick={() => removeFile('kitchenImage', index)}
                              className="p-1 text-blue-400 hover:text-blue-600 rounded-full hover:bg-blue-100 
              transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                              </svg>
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
          Building Photo
                  </label>
                  <div className="group relative">
                    <div className="flex justify-center items-center w-full" >
                      <div className="relative w-full">
                        <input
                          type="file"
                          name="buildingImage"
                          onChange={handleFileChange}
                          multiple
                          className="absolute inset-0 w-full h-full opacity-0 z-10 cursor-pointer"
                          accept="image/*"
                        />
                        <div className="flex flex-col items-center w-full h-32 px-4 py-6 
                    bg-white text-gray-500 rounded-lg border-2 border-dashed border-gray-300 
                    transition-all duration-200 
                    hover:bg-gray-50 hover:border-blue-300
                    group-focus-within:border-blue-500 group-focus-within:ring-2 group-focus-within:ring-blue-500 group-focus-within:ring-offset-2">
                    
                          <div className="flex flex-col items-center pointer-events-none">
                            <svg className="w-8 h-8 mb-2 text-gray-400 group-hover:text-blue-500 transition-colors duration-200" 
                              fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                            </svg>
                      
                            <p className="text-sm text-gray-500 text-center group-hover:text-gray-600">
                              <span className="font-medium">Click to upload</span> or drag and drop
                            </p>
                      
                            <p className="text-xs text-gray-500 mt-1">
                        Accepted files: image/*
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {(getNestedValue(formData, 'buildingImage') || []).length > 0 && (
                      <div className="space-y-2">
                        {(getNestedValue(formData, 'buildingImage') || []).map((fileName: string, index: number) => (
                          <div key={index} className="flex items-center justify-between p-3 bg-blue-50 rounded-lg">
                            <div className="flex items-center">
                              <svg className="w-5 h-5 text-blue-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                              </svg>
                              <span className="text-sm font-medium text-blue-700">
                                {fileName}
                              </span>
                            </div>
          
                            <button
                              type="button"
                              onClick={() => removeFile('buildingImage', index)}
                              className="p-1 text-blue-400 hover:text-blue-600 rounded-full hover:bg-blue-100 
              transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                              </svg>
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {renderPaginationControls()}
      </form>
    </div>
  );
};

export default RoomsForRentFormForm;