import React, { useState, useEffect } from 'react';
import { ArrowLeft, Building, Clock, MapPin, Tag, Home } from 'lucide-react';

interface Ad {
  id: string;
  primaryCategory: string;
  version: number;
  data: string;
  createdAt: string;
  updatedAt: string;
}

interface BadgeProps {
  children: React.ReactNode;
  variant?: 'default' | 'outline' | 'secondary';
  className?: string;
}

interface AdCardPreviewProps {
  ad: Ad;
  onClick: () => void;
}

interface AdDetailViewProps {
  ad: Ad;
  onBack: () => void;
}

interface ParsedData {
  title?: string;
  listingTitle?: string;
  projectName?: string;
  address?: string;
  price?: number;
  currency?: string;
  propertyType?: string;
  projectType?: string;
  status?: string;
  [key: string]: any;
}

// Badge component
const Badge: React.FC<BadgeProps> = ({ children, variant = 'default', className = '' }) => {
  const baseStyle =
    'inline-flex items-center rounded-xl px-3 py-1 text-xs font-semibold tracking-wide transition-all duration-200';
  const variants: Record<string, string> = {
    default: 'bg-blue-100 text-blue-800 hover:bg-blue-200',
    outline: 'border-2 border-blue-300 text-blue-700 hover:bg-blue-50',
    secondary:
      'bg-gradient-to-r from-purple-100 to-pink-100 text-purple-800 hover:from-purple-200 hover:to-pink-200',
  };

  return <span className={`${baseStyle} ${variants[variant]} ${className}`}>{children}</span>;
};

// Card Preview Component
const AdCardPreview: React.FC<AdCardPreviewProps> = ({ ad, onClick }) => {
  const parseNestedData = (data: string): ParsedData => {
    try {
      return JSON.parse(data);
    } catch (e) {
      return {};
    }
  };

  const parsedData = React.useMemo(() => {
    return parseNestedData(ad.data);
  }, [ad.data]);

  const formatDate = (dateString: string): string => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  return (
    <div
      className="group bg-white rounded-xl shadow-md hover:shadow-xl transition-all duration-300 overflow-hidden cursor-pointer transform hover:-translate-y-1"
      onClick={onClick}
    >
      <div className="p-5 border-b border-gray-100">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-bold text-gray-900 truncate group-hover:text-blue-600 transition-colors">
            {parsedData.title || parsedData.listingTitle || parsedData.projectName || 'Ad Details'}
          </h3>
          <Badge variant="outline" className="group-hover:border-blue-400">
            v{ad.version}
          </Badge>
        </div>
        <div className="text-sm text-gray-500 flex items-center gap-2 mt-2">
          <Clock className="h-4 w-4 text-blue-500" />
          {formatDate(ad.createdAt)}
        </div>
      </div>
      <div className="p-5 space-y-3 bg-gradient-to-b from-white to-gray-50">
        {parsedData.address && (
          <div className="flex items-center gap-3 text-sm group-hover:text-blue-600 transition-colors">
            <MapPin className="h-4 w-4 text-blue-500" />
            <span className="truncate">{parsedData.address}</span>
          </div>
        )}
        {parsedData.price !== undefined && (
          <div className="flex items-center gap-3">
            <Tag className="h-4 w-4 text-green-500" />
            <span className="text-lg font-bold text-green-600">
              {parsedData.currency || '$'} {Number(parsedData.price).toFixed(2)}
            </span>
          </div>
        )}
        <div className="flex items-center gap-3 text-sm text-gray-600">
          <Building className="h-4 w-4 text-purple-500" />
          <span>{parsedData.propertyType || parsedData.projectType || 'Property'}</span>
        </div>
      </div>
    </div>
  );
};

// Detailed View Component
const AdDetailView: React.FC<AdDetailViewProps> = ({ ad, onBack }) => {
  const parseNestedData = (data: string): ParsedData => {
    try {
      return JSON.parse(data);
    } catch (e) {
      return {};
    }
  };

  const parsedData = React.useMemo(() => {
    return parseNestedData(ad.data);
  }, [ad.data]);

  const formatDate = (dateString: string): string => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const renderValue = (value: any): string => {
    if (Array.isArray(value)) {
      return value.filter((item) => item && item !== '').join(', ') || '-';
    } else if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    } else if (value === null || value === undefined || value === '') {
      return '-';
    } else if (typeof value === 'object') {
      return JSON.stringify(value, null, 2);
    }
    return String(value);
  };

  const formatKey = (key: string): string => {
    return key
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, (str) => str.toUpperCase())
      .trim();
  };

  return (
    <div className="bg-white rounded-xl shadow-lg border border-gray-100">
      <div className="p-6 border-b border-gray-100">
        <button
          onClick={onBack}
          className="group flex items-center gap-2 text-blue-600 hover:text-blue-800 mb-6 transition-colors"
        >
          <ArrowLeft className="h-5 w-5 transform group-hover:-translate-x-1 transition-transform" />
          <span className="font-medium">Back to Ads</span>
        </button>
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
          <h2 className="text-3xl font-bold text-gray-900 leading-tight">
            {parsedData.title || parsedData.listingTitle || parsedData.projectName || 'Ad Details'}
          </h2>
          <div className="flex gap-3">
            <Badge variant="outline">v{ad.version}</Badge>
            {parsedData.status && <Badge variant="secondary">{parsedData.status}</Badge>}
          </div>
        </div>
        <div className="mt-4 space-y-1">
          <div className="text-sm text-gray-600 flex items-center gap-2">
            <Clock className="h-4 w-4 text-blue-500" />
            Created: {formatDate(ad.createdAt)}
          </div>
          <div className="text-sm text-gray-600 flex items-center gap-2">
            <Clock className="h-4 w-4 text-green-500" />
            Last Updated: {formatDate(ad.updatedAt)}
          </div>
        </div>
      </div>

      <div className="p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Main Information */}
          <div className="space-y-6 p-6 bg-blue-50 rounded-xl">
            <h3 className="text-xl font-bold text-blue-900">Main Information</h3>
            {parsedData.address && (
              <div className="flex items-center gap-3 bg-white p-4 rounded-lg shadow-sm">
                <MapPin className="h-5 w-5 text-blue-500" />
                <span className="text-gray-700">{parsedData.address}</span>
              </div>
            )}
            {parsedData.price !== undefined && (
              <div className="flex items-center gap-3 bg-white p-4 rounded-lg shadow-sm">
                <Tag className="h-5 w-5 text-green-500" />
                <span className="text-2xl font-bold text-green-600">
                  {parsedData.currency || '$'} {Number(parsedData.price).toFixed(2)}
                </span>
              </div>
            )}
          </div>

          {/* Property Details */}
          <div className="space-y-6 p-6 bg-purple-50 rounded-xl">
            <h3 className="text-xl font-bold text-purple-900">Property Details</h3>
            {parsedData.propertyType && (
              <div className="flex items-center gap-3 bg-white p-4 rounded-lg shadow-sm">
                <Home className="h-5 w-5 text-purple-500" />
                <span className="text-gray-700">{parsedData.propertyType}</span>
              </div>
            )}
          </div>
        </div>

        {/* All Other Details */}
        <div className="mt-8">
          <h3 className="text-xl font-bold text-gray-900 mb-6">Additional Information</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {Object.entries(parsedData)
              .filter(([key]) => !['address', 'price', 'currency', 'propertyType'].includes(key))
              .sort(([a], [b]) => a.localeCompare(b))
              .map(([key, value]) => (
                <div
                  key={key}
                  className="p-4 bg-gray-50 rounded-xl hover:bg-gray-100 transition-colors"
                >
                  <div className="text-sm font-semibold text-gray-700">{formatKey(key)}</div>
                  <div className="mt-2 text-sm text-gray-600 break-words">{renderValue(value)}</div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

// Main Component
const AdsDisplay: React.FC = () => {
  const [ads, setAds] = useState<Ad[]>([]);
  const [selectedAd, setSelectedAd] = useState<Ad | null>(null);

  useEffect(() => {
    const loadAdsFromFile = async () => {
      try {
        const response = await fetch('https://adzmonster.com/api/ads/all');
        const text = await response.text();
        const data = JSON.parse(text);
        if (data.success) {
          setAds(data.data);
        }
      } catch (err) {
        console.error('Error loading ads:', err);
      }
    };

    loadAdsFromFile();
  }, []);

  if (selectedAd) {
    return (
      <div className="container mx-auto p-4 max-w-7xl">
        <AdDetailView ad={selectedAd} onBack={() => setSelectedAd(null)} />
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4 max-w-7xl">
      <div className="flex items-center justify-between mb-8">
        <h1 className="text-3xl font-bold text-gray-900">
          Available Ads
          <span className="ml-3 text-lg font-semibold text-blue-600">({ads.length})</span>
        </h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {ads.map((ad) => (
          <AdCardPreview key={ad.id} ad={ad} onClick={() => setSelectedAd(ad)} />
        ))}
      </div>
    </div>
  );
};

export default AdsDisplay;
