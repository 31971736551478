import React, { useEffect, useState, useRef, useContext } from 'react';
import { Globe, ChevronDown } from 'lucide-react';
import { fetchSupportedLanguages } from '@infrastructure/api/language.ts';
import LanguageContext from '@/components/language/language_context.tsx';

const LanguageDropdown: React.FC = () => {
  const languageContext = useContext(LanguageContext);
  const selectedLanguage = languageContext?.selectedLanguage;
  const setSelectedLanguage = languageContext?.setSelectedLanguage;
  const [languages, setLanguages] = useState<
    { translatedLanguageName: string; languageCode: string }[]
  >([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const getLanguages = async () => {
      try {
        const data = await fetchSupportedLanguages();
        setLanguages(data);
      } catch (error) {
        console.error('Failed to fetch languages:', error);
      }
    };

    getLanguages().catch(console.error);
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <div>
        <button
          type="button"
          className="inline-flex justify-center items-center rounded border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none "
          onClick={() => setIsOpen(!isOpen)}
        >
          <Globe className="mr-2 h-5 w-5" aria-hidden="true" />
          {selectedLanguage}
          <ChevronDown className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      {isOpen && (
        <div className="absolute mt-2 w-40 rounded-md shadow-lg bg-white">
          <div className="py-1">
            {languages.map((language) => (
              <a
                key={language.languageCode}
                href="#"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                onClick={() => {
                  setSelectedLanguage?.(language.languageCode);
                  setIsOpen(false);
                }}
              >
                {language.translatedLanguageName}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageDropdown;
