import { useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

const LOGIN_API_URL = 'https://adzmonster.com/api/users/login';

export const useLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const redirectTo = location.state?.redirectTo || '/';

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(LOGIN_API_URL, null, {
        params: { username, password },
      });
      if (response.status === 200) {
        setSuccessMessage('Login successful!');
        setTimeout(() => {
          navigate(redirectTo, { state: { isLoggedIn: true, username } });
        }, 500);
      } else {
        setError('Invalid username or password.');
      }
    } catch (err) {
      setError('Error connecting to the server. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return {
    username,
    password,
    loading,
    error,
    successMessage,
    setUsername,
    setPassword,
    handleLogin,
  };
};
