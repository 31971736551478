import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const SIGNUP_API_URL = 'https://adzmonster.com/api/users/signup';

export const useSignup = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const navigate = useNavigate();

  const handleSignup = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(SIGNUP_API_URL, {
        username,
        email,
        password,
      });

      if (response.status === 201) {
        setSuccessMessage('Signup successful! Redirecting to login...');
        setTimeout(() => {
          navigate('/login', { state: { success: true } });
        }, 1000);
      } else {
        setError('Signup failed. Please try again.');
      }
    } catch (err) {
      setError('Error connecting to the server. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return {
    username,
    email,
    password,
    confirmPassword,
    loading,
    error,
    successMessage,
    setUsername,
    setEmail,
    setPassword,
    setConfirmPassword,
    handleSignup,
  };
};
