import { useState, useEffect, useRef } from 'react';
import Logo from './logo.tsx';
import { ShoppingCart, UserRound } from 'lucide-react';
import LanguageDropdown from '@/components/header/language_dropdown.tsx';
import SearchBar from '@/components/header/search_bar.tsx';
import { useNavigate } from 'react-router-dom';
import useScreenSize from '@/hooks/useScreenSizeHook.ts';
import Location from '@/components/header/location.tsx';

export default function HeaderLayout() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Burger menu toggle state
  const menuRef = useRef<HTMLDivElement>(null); // Reference to the menu
  const { isMobile, isTablet } = useScreenSize(); // Use hook for screen size

  useEffect(() => {
    // Close menu if user clicks outside menu (for mobile/tablet)
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        isMenuOpen &&
        menuRef.current &&
        !menuRef.current.contains(event.target as Node)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, [isMenuOpen]);

  const handleLogin = () => {
    navigate('/Login');
  };

  // ================================================
  // Render for MOBILE version
  // ================================================
  if (isMobile) {
    return (
      <header className="bg-[#131921] text-white flex flex-col">
        <div className="flex items-center justify-between px-4 py-2">
          <div className="flex items-center space-x-4">
            {/* Logo */}
            <Logo />
          </div>

          {/* Burger Menu */}
          <button
            className="block p-2 border rounded-md"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-label="Toggle Menu"
          >
            <svg
              className="w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {isMenuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
        </div>

        <SearchBar/>

        {/* Dropdown Menu */}
        {isMenuOpen && (
          <div
            ref={menuRef}
            className="fixed top-0 left-0 h-full w-[60%] bg-[#232f3e] p-6 z-50"
          >
            <div className="flex flex-col space-y-4">
              <Location />
              <LanguageDropdown />
              <button
                className="px-1 py-2 rounded bg-green-500 hover:bg-green-600 font-medium"
                onClick={() => navigate('/post-ad')}
              >
                PostAD
              </button>
              <UserRound onClick={handleLogin} />
              <ShoppingCart size={24} />
            </div>
          </div>
        )}
      </header>
    );
  }

  // ================================================
  // Render for TABLET version
  // ================================================

  if (isTablet) {
    return (
      <header className="bg-[#131921] text-white shadow-md flex flex-col">
        <div className="flex  items-center justify-between px-6 py-2">
          <div className="flex items-center space-x-4">
            {/* Logo */}
            <Logo />
          </div>
          <div className="flex-1 px-4">
            <SearchBar />
          </div>
          <button
            className="block p-2 border rounded-md"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-label="Toggle Menu"
          >
            <svg
              className="w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {isMenuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
          {/* Search Bar */}

          {/* Dropdown Menu */}
          {isMenuOpen && (
            <div ref={menuRef} className="fixed top-0 left-0 h-full w-[60%] bg-[#232f3e] p-6 z-50">
              <div className="flex flex-col space-y-4">
                <Location />
                <LanguageDropdown />
                <button
                  className="px-1 py-2 rounded bg-green-500 hover:bg-green-600 font-medium"
                  onClick={() => navigate('/post-ad')}
                >
                  PostAD
                </button>
                <UserRound onClick={handleLogin} />
                <ShoppingCart size={24} />
              </div>
            </div>
          )}
        </div>
      </header>
    );
  }

  // ================================================
  // Render for LARGE screens (default/fallback)
  // ================================================
  return (
    <header className="bg-[#131921] text-white shadow-md flex flex-col relative">
      <div className="flex flex-wrap items-center justify-between px-8 py-4">
        <div className="flex items-center space-x-4">
          {/* Logo */}
          <Logo />
        </div>
        <div className="flex-1 px-4">
          {/* Search Bar */}
          <SearchBar />
        </div>
        <div className="flex items-center space-x-6">
          <Location />
          <LanguageDropdown />
          <button
            className="px-4 py-2 rounded bg-green-500 hover:bg-green-600 text-sm font-medium"
            onClick={() => navigate('/post-ad')}
          >
            PostAD
          </button>
          <UserRound onClick={handleLogin} />
          <ShoppingCart size={24} />
        </div>
      </div>
    </header>
  );
}
