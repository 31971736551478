import React from 'react';
import { useSignup } from '@/components/LoginForm/useSignUp.ts';
import SignupForm from '@/components/LoginForm/SignupForm.tsx';
import LoginPrompt from '@/components/LoginForm/LoginPrompt.tsx';


const Signup: React.FC = () => {
  const {
    username,
    email,
    password,
    confirmPassword,
    loading,
    error,
    successMessage,
    setUsername,
    setEmail,
    setPassword,
    setConfirmPassword,
    handleSignup,
  } = useSignup();

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
      <div className="bg-white shadow-xl rounded-lg overflow-hidden w-full max-w-4xl flex flex-col lg:flex-row">

        <SignupForm
          username={username}
          email={email}
          password={password}
          confirmPassword={confirmPassword}
          loading={loading}
          error={error}
          successMessage={successMessage}
          onUsernameChange={setUsername}
          onEmailChange={setEmail}
          onPasswordChange={setPassword}
          onConfirmPasswordChange={setConfirmPassword}
          onSubmit={handleSignup}
        />
        <LoginPrompt />
      </div>
    </div>

  );
};

export default Signup;
