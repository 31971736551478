import React from 'react';
import LoginForm from '@/components/LoginForm/LoginForm.tsx';
import { useLogin } from './useLogin';
import SignupPrompt from '@/components/LoginForm/SignupPrompt.tsx';

const Login: React.FC = () => {
  const {
    username,
    password,
    loading,
    error,
    successMessage,
    setUsername,
    setPassword,
    handleLogin,
  } = useLogin();

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
      <div className="bg-white shadow-xl rounded-lg overflow-hidden w-full max-w-4xl flex flex-col lg:flex-row">
        <LoginForm
          username={username}
          password={password}
          loading={loading}
          error={error}
          successMessage={successMessage}
          onUsernameChange={setUsername}
          onPasswordChange={setPassword}
          onSubmit={handleLogin}
        />
        <SignupPrompt />
      </div>
    </div>
  );
};

export default Login;
