import React, { useState, useCallback , useEffect } from 'react';
import type { AutomobileForm } from './automobileform';
import { useNavigate } from 'react-router-dom';

const STORAGE_KEY = 'formData';
const PAGE_STORAGE_KEY = 'currentFormPage';
const SCHEMA_METADATA = {
  'id': '2b9b3ddd-1951-44c0-8bb0-ad50786c13fe',
  'schemaFile': 'D:\\WebstormProjects\\schemas\\ad\\automobile\\automobile_v_1_0_0.json',
};


const AutomobileFormForm: React.FC = () => {
  const [formData, setFormData] = useState<Partial<AutomobileForm>>(() =>{
    try {
      const savedData = localStorage.getItem(STORAGE_KEY);
      return savedData ? JSON.parse(savedData) : {};
    } catch (error) {
      console.error('Error parsing saved form data:', error);
      return {};
    }
  });
  const [currentPage, setCurrentPage] = useState(0);
  const totalPages = 6;
  const navigate = useNavigate();
  
  // Save form data to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(formData));
  }, [formData]);

  // Save current page to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem(PAGE_STORAGE_KEY, currentPage.toString());
  }, [currentPage]);

  const setNestedValue = (obj: any, path: string, value: any) => {
    const pathParts = path.split('.');
    const sectionKey = pathParts[0]; // Get the section key (e.g., generalInformation)
    const fieldPath = pathParts.slice(1); // Get the rest of the path

    // If there's no section, treat it as a root-level field
    if (fieldPath.length === 0) {
      return { ...obj, [sectionKey]: value };
    }

    // Ensure the section exists
    const section = obj[sectionKey] || {};
  
    // If we only have one field in the path, set it directly
    if (fieldPath.length === 1) {
      return {
        ...obj,
        [sectionKey]: {
          ...section,
          [fieldPath[0]]: value,
        },
      };
    }

    // For nested fields within the section
    const current = { ...section };
    const lastKey = fieldPath.pop()!;
    let pointer = current;

    for (const key of fieldPath) {
      pointer[key] = pointer[key] || {};
      pointer = pointer[key];
    }

    pointer[lastKey] = value;

    return {
      ...obj,
      [sectionKey]: current,
    };
  };

  const getNestedValue = (obj: any, path: string) => {
    try {
      return path.split('.').reduce((acc, part) => acc?.[part], obj) ?? '';
    } catch {
      return '';
    }
  };
  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(0, prev - 1));
  };

  const handleNextPage = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setCurrentPage((prev) => Math.min(totalPages - 1, prev + 1));
  };
   
  const handleDraft = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    // Save the current form data as a draft
    localStorage.setItem(STORAGE_KEY, JSON.stringify(formData));
    localStorage.setItem(PAGE_STORAGE_KEY, currentPage.toString());
    console.log('Draft saved');
  };
   
  // Add a reset function to clear the form
  const resetForm = useCallback(() => {
    setFormData({});
    setCurrentPage(0);
    localStorage.removeItem(STORAGE_KEY);
    localStorage.removeItem(PAGE_STORAGE_KEY);
  }, []);
  

  const renderPaginationControls = () => {
    return (
      <div className="flex flex-col sm:flex-row justify-between items-center gap-4 px-4 py-6 bg-white rounded-lg shadow-sm">
        <button
          type="button"
          onClick={handlePrevPage}
          disabled={currentPage === 0}
          className="max-sm:w-15 max-md::w-30 lg:w-32 xl:w-32 px-5 py-3 bg-gradient-to-r from-blue-500 to-blue-600 text-white font-medium rounded-xl 
        transform transition-all duration-200 
        hover:from-blue-600 hover:to-blue-700 hover:shadow-md 
        active:scale-95
        disabled:from-gray-300 disabled:to-gray-400 disabled:cursor-not-allowed disabled:transform-none
        focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          <span className="flex items-center justify-center">
            <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          Previous
          </span>
        </button>
        <button
          type="button"
          onClick={handleDraft}
          disabled={currentPage === 0}
          className="max-sm:w-25 max-md::w-30 lg:w-36 xl:w-38 px-5 py-3 bg-white text-gray-600 font-medium rounded-xl hover:text-green-600"
        >
          <span className="flex items-center justify-center">
          Save as Draft
          </span>
        </button>
      
      
        {currentPage === totalPages - 1 ? (
          <button
            type="submit"
            className="max-sm:w-15 max-md::w-30 lg:w-32 xl:w-32 px-5 py-3 bg-gradient-to-r from-green-500 to-green-600 text-white font-medium rounded-xl 
          transform transition-all duration-200 
          hover:from-green-600 hover:to-green-700 hover:shadow-md 
          active:scale-95
          focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
          >
            <span className="flex items-center justify-center">
            Submit
              <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
            </span>
          </button>
        ) : (
          <button
            type="button"
            onClick={handleNextPage}
            className="max-sm:w-15 max-md::w-30 lg:w-32 xl:w-32 px-5 py-3 bg-gradient-to-r from-blue-500 to-blue-600 text-white font-medium rounded-xl 
          transform transition-all duration-200 
          hover:from-blue-600 hover:to-blue-700 hover:shadow-md 
          active:scale-95
          focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            <span className="flex items-center justify-center">
            Next
              <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </span>
          </button>
        )}
      </div>
    );
  };

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;

    setFormData((prev) => {
      let newValue: any;

      switch (type) {
        case 'checkbox':
          newValue = (e.target as HTMLInputElement).checked;
          break;
        case 'number':
        case 'range':
          newValue = value === '' ? null : Number(value);
          break;
        case 'radio':
          newValue = value;
          break;
        case 'file':
          return prev; // Files are handled by handleFileChange
        default:
          newValue = value;
      }

      return setNestedValue({ ...prev }, name, newValue);
    });
  }, []);

  const handleFileChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = e.target;
    if (files && files.length > 0) {
      setFormData((prev) => {
      // Get existing files array or initialize empty array
        const existingFiles = getNestedValue(prev, name) || [];
        // Add new files to existing files, avoiding duplicates
        const newFiles = Array.from(files).map((file) => file.name);
        const uniqueFiles = [...new Set([...existingFiles, ...newFiles])];
        return setNestedValue({ ...prev }, name, uniqueFiles);
      });
    }
  }, [formData, getNestedValue]);
  
  // Add a function to remove individual files
  const removeFile = useCallback((fieldName: string, fileIndex: number) => {
    setFormData((prev) => {
      const files = getNestedValue(prev, fieldName) || [];
      const newFiles = files.filter((_: any, index: number) => index !== fileIndex);
      return setNestedValue({ ...prev }, fieldName, newFiles);
    });
  }, []);



  const handleSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    // Process form data to ensure all fields are included
    const processFormData = (data: any) => {
      const processed: any = {};
      
      // Helper function to handle empty/undefined values
      const processValue = (value: any) => {
        if (Array.isArray(value)) {
          return value.length > 0 ? value : [];
        }
        if (value === '') {
          return null;
        }
        return value ?? null;
      };

      // Process each field, maintaining structure
      Object.entries(data).forEach(([key, value]) => {
        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
          processed[key] = processFormData(value); // Recursively process nested objects
        } else {
          processed[key] = processValue(value);
        }
      });

      return processed;
    };


    // Create the new payload structure
    const payload = {      
         
      primaryCategoryId: SCHEMA_METADATA.id,
      schemaFile: SCHEMA_METADATA.schemaFile,
      data: JSON.stringify(processFormData(formData)),
    };
    console.log('Payload being sent:', JSON.stringify(payload, null, 2));

    try {
      const response = await fetch('https://adzmonster.com/api/ads/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log('Success:', result);
      // Clear form data after successful submission
      resetForm();
    } catch (error) {
      console.error('Error:', error);
    }
  }, [formData, resetForm]);
  return (
    <div className="min-h-screen from-blue-50/50 to-white flex items-center justify-center p-4 sm:p-6 md:p-8 lg:p-9">
      <form onSubmit={handleSubmit} className="w-full max-w-4xl mx-auto bg-white backdrop-blur-lg bg-opacity-95 rounded-2xl shadow-xl border border-white border-opacity-20 p-4 sm:p-6 md:p-8 transition-all duration-300 hover:shadow-2xl">
        {/* Progress Bar */}
        <div className="mb-8">
          <div className="w-full bg-gray-100 rounded-full h-3 overflow-hidden shadow-inner">
            <div
              className="bg-gradient-to-r from-blue-500 to-purple-500 h-3 rounded-full transition-all duration-500 ease-in-out"
              style={{ width: `${((currentPage + 1) / totalPages) * 100}%` }}
            />
          </div>
          <div className="flex items-center justify-center px-4 py-2 bg-white rounded-lg">
            <span className="text-sm sm:text-base font-medium text-gray-700">
                  Step {currentPage + 1} of {totalPages}
            </span>
          </div>
        </div>
        {/* Action Buttons */}
        <div className="flex flex-col sm:flex-row justify-between items-center gap-4 mb-8">
          {/* Navigation Links */}
          <button
            type="button"
            onClick={() => navigate('/v1')}
            className="group flex items-center px-4 py-2 text-sm font-medium text-gray-700 hover:text-blue-600 transition-all duration-200"
          >
            <svg 
              className="w-4 h-4 mr-2 transform transition-transform group-hover:-translate-x-1" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
                    Back to Home
          </button>
        
          {/* Reset Button */}
          <button
            type="button"
            onClick={resetForm}
            className="flex items-center px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-red-500 to-red-600 rounded-xl 
                      transform transition-all duration-200 
                      hover:from-red-600 hover:to-red-700 hover:shadow-md 
                      active:scale-95
                      focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
          >
            <svg 
              className="w-4 h-4 mr-2" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
            </svg>
                      Reset Form
          </button>
        </div>
                
        {currentPage === 0 && (
          <div key="0" className="space-y-4">
                    
            <div className="mb-6">
              <div className="flex justify-between items-center mb-2">
                <label className="block text-sm font-medium text-gray-700">
          Vehicle manufacturer
                  <span className="ml-1 text-red-500">*</span>
                </label>
        
              </div>
      
              <div className="relative">
                <input
                  type="text"
                  name="make"
                  value={getNestedValue(formData, 'make') ?? ''}
                  onChange={handleChange}
                  placeholder="Enter vehicle manufacturer..."
                  className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                  required
                />
        
        
              </div>
      
      
      
      
            </div>

            <div className="mb-6">
              <div className="flex justify-between items-center mb-2">
                <label className="block text-sm font-medium text-gray-700">
          Vehicle model
                  <span className="ml-1 text-red-500">*</span>
                </label>
        
              </div>
      
              <div className="relative">
                <input
                  type="text"
                  name="model"
                  value={getNestedValue(formData, 'model') ?? ''}
                  onChange={handleChange}
                  placeholder="Enter vehicle model..."
                  className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                  required
                />
        
        
              </div>
      
      
      
      
            </div>

            <div className="mb-6">
              <div className="flex justify-between items-center mb-2">
                <label className="block text-sm font-medium text-gray-700">
          Manufacturing year
                  <span className="ml-1 text-red-500">*</span>
                </label>
        
              </div>
      
              <div className="relative">
                <input
                  type="number"
                  name="year"
                  value={getNestedValue(formData, 'year') ?? ''}
                  onChange={handleChange}
                  placeholder="Enter manufacturing year..."
                  className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                  min="1900" max="2025" required
                />
        
        
              </div>
      
      
      
      
            </div>
          </div>
        )}

        {currentPage === 1 && (
          <div key="1" className="space-y-4">
                    
            <div className="mb-6">
              <div className="flex justify-between items-center mb-2">
                <label className="block text-sm font-medium text-gray-700">
          Vehicle price
                  <span className="ml-1 text-red-500">*</span>
                </label>
        
              </div>
      
              <div className="relative">
                <input
                  type="number"
                  name="price"
                  value={getNestedValue(formData, 'price') ?? ''}
                  onChange={handleChange}
                  placeholder="Enter vehicle price..."
                  className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                  min="0" required
                />
        
        
              </div>
      
      
      
      
            </div>

            <div className="mb-6">
              <div className="relative">
                <label className="block text-sm font-medium text-gray-700 mb-2">
          Vehicle condition
                </label>
                <div className="relative group">
                  <select
                    name="condition"
                    value={getNestedValue(formData, 'condition') ?? ''}
                    onChange={handleChange}
                    className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
                    required
                  >
                    <option value="" className="text-gray-500">Select condition</option>
                    <option key="NEW" value="NEW">NEW</option>
                    <option key="USED" value="USED">USED</option>
                    <option key="CERTIFIED" value="CERTIFIED">CERTIFIED</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="mb-6">
              <div className="flex justify-between items-center mb-2">
                <label className="block text-sm font-medium text-gray-700">
          Vehicle mileage
                  <span className="ml-1 text-red-500">*</span>
                </label>
        
              </div>
      
              <div className="relative">
                <input
                  type="number"
                  name="mileage"
                  value={getNestedValue(formData, 'mileage') ?? ''}
                  onChange={handleChange}
                  placeholder="Enter vehicle mileage..."
                  className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                  min="0" required
                />
        
        
              </div>
      
      
      
      
            </div>
          </div>
        )}

        {currentPage === 2 && (
          <div key="2" className="space-y-4">
                    
            <div className="mb-6">
              <div className="relative">
                <label className="block text-sm font-medium text-gray-700 mb-2">
          Fuel type
                </label>
                <div className="relative group">
                  <select
                    name="fuelType"
                    value={getNestedValue(formData, 'fuelType') ?? ''}
                    onChange={handleChange}
                    className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
                    required
                  >
                    <option value="" className="text-gray-500">Select fuelType</option>
                    <option key="GASOLINE" value="GASOLINE">GASOLINE</option>
                    <option key="DIESEL" value="DIESEL">DIESEL</option>
                    <option key="ELECTRIC" value="ELECTRIC">ELECTRIC</option>
                    <option key="HYBRID" value="HYBRID">HYBRID</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="mb-6">
              <div className="relative">
                <label className="block text-sm font-medium text-gray-700 mb-2">
          Transmission type
                </label>
                <div className="relative group">
                  <select
                    name="transmission"
                    value={getNestedValue(formData, 'transmission') ?? ''}
                    onChange={handleChange}
                    className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
                    required
                  >
                    <option value="" className="text-gray-500">Select transmission</option>
                    <option key="AUTOMATIC" value="AUTOMATIC">AUTOMATIC</option>
                    <option key="MANUAL" value="MANUAL">MANUAL</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="mb-6">
              <div className="flex justify-between items-center mb-2">
                <label className="block text-sm font-medium text-gray-700">
          Vehicle features
          
                </label>
        
              </div>
      
              <div className="relative">
                <input
                  type="text"
                  name="features"
                  value={getNestedValue(formData, 'features') ?? ''}
                  onChange={handleChange}
                  placeholder="Enter vehicle features..."
                  className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
          
                />
        
        
              </div>
      
      
      
      
            </div>
          </div>
        )}

        {currentPage === 3 && (
          <div key="3" className="space-y-4">
                    
            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              color
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Exterior color
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="exterior"
                      value={getNestedValue(formData, 'exterior') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter exterior color..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      required
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Interior color
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="interior"
                      value={getNestedValue(formData, 'interior') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter interior color..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      required
                    />
        
        
                  </div>
      
      
      
      
                </div>
              </div>
            </div>

            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              basicInformation
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Unique Vehicle Identifier
          
                    </label>
        
                    <div className="mt-1 relative group">
                      <button type="button" 
                        className="flex items-center justify-center w-5 h-5 text-blue-500 bg-blue-50 rounded-full 
            transition-all duration-200 hover:bg-blue-100">
                        <span className="text-xs font-medium">i</span>
                      </button>
                      <div className="absolute right-0 bottom-full mb-2 w-72 sm:w-50 p-4 bg-white border 
          rounded-lg shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible 
          transition-all duration-200 z-10">
                        <p className="text-sm text-gray-600 leading-relaxed">Format: VEH-XXXXXX where X is a digit (0-9)</p>
      
                        <div className="mt-1">
                          <span className="block text-xs font-medium text-gray-500 mb-2">Examples:</span>
          
                          <span className="ml-2 text-sm text-blue-600">VEH-123456</span>
          
                          <span className="ml-2 text-sm text-blue-600">VEH-000001</span>
          
                          <span className="ml-2 text-sm text-blue-600">VEH-999999</span>
          
                        </div>
      
                      </div>
                    </div>
  
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="vehicleId"
                      value={getNestedValue(formData, 'vehicleId') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter unique vehicle identifier..."
                      className={`w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 
    ${!new RegExp('^VEH-[0-9]{6}$').test(getNestedValue(formData, 'vehicleId') || '') && getNestedValue(formData, 'vehicleId') 
            ? 'border-red-300 focus:border-red-500 focus:ring-red-500' 
            : ''}
  `}
                      pattern="^VEH-[0-9]{6}$"
                    />
        
        
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      {!new RegExp('^VEH-[0-9]{6}$').test(getNestedValue(formData, 'vehicleId') || '') && 
             getNestedValue(formData, 'vehicleId') && (
                        <svg className="w-5 h-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      )}
                    </div>
        
                  </div>
      
      
                  {getNestedValue(formData, 'vehicleId') && !new RegExp('^VEH-[0-9]{6}$').test(getNestedValue(formData, 'vehicleId')) && (
                    <p className="mt-1 text-sm text-red-600">
        Vehicle ID must start with 'VEH-' followed by exactly 6 digits
                    </p>
                  )}
  
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Date of Purchase
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="date"
                      name="purchaseDate"
                      value={getNestedValue(formData, 'purchaseDate') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter date of purchase..."
                      className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 "
          
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Last Service Date and Time
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="datetime-local"
                      name="lastServiceDate"
                      value={getNestedValue(formData, 'lastServiceDate') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter last service date and time..."
                      className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 "
          
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
          Main Vehicle Image
                  </label>
                  <div className="group relative">
                    <div className="flex justify-center items-center w-full" >
                      <div className="relative w-full">
                        <input
                          type="file"
                          name="vehicleImage"
                          onChange={handleFileChange}
                          multiple
                          className="absolute inset-0 w-full h-full opacity-0 z-10 cursor-pointer"
                          accept="image/*"
                        />
                        <div className="flex flex-col items-center w-full h-32 px-4 py-6 
                    bg-white text-gray-500 rounded-lg border-2 border-dashed border-gray-300 
                    transition-all duration-200 
                    hover:bg-gray-50 hover:border-blue-300
                    group-focus-within:border-blue-500 group-focus-within:ring-2 group-focus-within:ring-blue-500 group-focus-within:ring-offset-2">
                    
                          <div className="flex flex-col items-center pointer-events-none">
                            <svg className="w-8 h-8 mb-2 text-gray-400 group-hover:text-blue-500 transition-colors duration-200" 
                              fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                            </svg>
                      
                            <p className="text-sm text-gray-500 text-center group-hover:text-gray-600">
                              <span className="font-medium">Click to upload</span> or drag and drop
                            </p>
                      
                            <p className="text-xs text-gray-500 mt-1">
                        Accepted files: image/*
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {(getNestedValue(formData, 'vehicleImage') || []).length > 0 && (
                      <div className="space-y-2">
                        {(getNestedValue(formData, 'vehicleImage') || []).map((fileName: string, index: number) => (
                          <div key={index} className="flex items-center justify-between p-3 bg-blue-50 rounded-lg">
                            <div className="flex items-center">
                              <svg className="w-5 h-5 text-blue-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                              </svg>
                              <span className="text-sm font-medium text-blue-700">
                                {fileName}
                              </span>
                            </div>
          
                            <button
                              type="button"
                              onClick={() => removeFile('vehicleImage', index)}
                              className="p-1 text-blue-400 hover:text-blue-600 rounded-full hover:bg-blue-100 
              transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                              </svg>
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Vehicle Color
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="color"
                      name="colorCode"
                      value={getNestedValue(formData, 'colorCode') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter vehicle color..."
                      className="w-full h-10 rounded-lg cursor-pointer "
          
                    />
        
        
                  </div>
      
      
      
      
                </div>
              </div>
            </div>

            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              documents
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
          Registration Document
                  </label>
                  <div className="group relative">
                    <div className="flex justify-center items-center w-full" >
                      <div className="relative w-full">
                        <input
                          type="file"
                          name="registrationDocument"
                          onChange={handleFileChange}
                          multiple
                          className="absolute inset-0 w-full h-full opacity-0 z-10 cursor-pointer"
                          accept=".pdf,.doc,.docx"
                        />
                        <div className="flex flex-col items-center w-full h-32 px-4 py-6 
                    bg-white text-gray-500 rounded-lg border-2 border-dashed border-gray-300 
                    transition-all duration-200 
                    hover:bg-gray-50 hover:border-blue-300
                    group-focus-within:border-blue-500 group-focus-within:ring-2 group-focus-within:ring-blue-500 group-focus-within:ring-offset-2">
                    
                          <div className="flex flex-col items-center pointer-events-none">
                            <svg className="w-8 h-8 mb-2 text-gray-400 group-hover:text-blue-500 transition-colors duration-200" 
                              fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                            </svg>
                      
                            <p className="text-sm text-gray-500 text-center group-hover:text-gray-600">
                              <span className="font-medium">Click to upload</span> or drag and drop
                            </p>
                      
                            <p className="text-xs text-gray-500 mt-1">
                        Accepted files: .pdf,.doc,.docx
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {(getNestedValue(formData, 'registrationDocument') || []).length > 0 && (
                      <div className="space-y-2">
                        {(getNestedValue(formData, 'registrationDocument') || []).map((fileName: string, index: number) => (
                          <div key={index} className="flex items-center justify-between p-3 bg-blue-50 rounded-lg">
                            <div className="flex items-center">
                              <svg className="w-5 h-5 text-blue-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                              </svg>
                              <span className="text-sm font-medium text-blue-700">
                                {fileName}
                              </span>
                            </div>
          
                            <button
                              type="button"
                              onClick={() => removeFile('registrationDocument', index)}
                              className="p-1 text-blue-400 hover:text-blue-600 rounded-full hover:bg-blue-100 
              transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                              </svg>
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
          Insurance Document
                  </label>
                  <div className="group relative">
                    <div className="flex justify-center items-center w-full" >
                      <div className="relative w-full">
                        <input
                          type="file"
                          name="insuranceDocument"
                          onChange={handleFileChange}
                          multiple
                          className="absolute inset-0 w-full h-full opacity-0 z-10 cursor-pointer"
                          accept=".pdf,.doc,.docx"
                        />
                        <div className="flex flex-col items-center w-full h-32 px-4 py-6 
                    bg-white text-gray-500 rounded-lg border-2 border-dashed border-gray-300 
                    transition-all duration-200 
                    hover:bg-gray-50 hover:border-blue-300
                    group-focus-within:border-blue-500 group-focus-within:ring-2 group-focus-within:ring-blue-500 group-focus-within:ring-offset-2">
                    
                          <div className="flex flex-col items-center pointer-events-none">
                            <svg className="w-8 h-8 mb-2 text-gray-400 group-hover:text-blue-500 transition-colors duration-200" 
                              fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                            </svg>
                      
                            <p className="text-sm text-gray-500 text-center group-hover:text-gray-600">
                              <span className="font-medium">Click to upload</span> or drag and drop
                            </p>
                      
                            <p className="text-xs text-gray-500 mt-1">
                        Accepted files: .pdf,.doc,.docx
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {(getNestedValue(formData, 'insuranceDocument') || []).length > 0 && (
                      <div className="space-y-2">
                        {(getNestedValue(formData, 'insuranceDocument') || []).map((fileName: string, index: number) => (
                          <div key={index} className="flex items-center justify-between p-3 bg-blue-50 rounded-lg">
                            <div className="flex items-center">
                              <svg className="w-5 h-5 text-blue-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                              </svg>
                              <span className="text-sm font-medium text-blue-700">
                                {fileName}
                              </span>
                            </div>
          
                            <button
                              type="button"
                              onClick={() => removeFile('insuranceDocument', index)}
                              className="p-1 text-blue-400 hover:text-blue-600 rounded-full hover:bg-blue-100 
              transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                              </svg>
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
          Service History Documents
                  </label>
                  <div className="group relative">
                    <div className="flex justify-center items-center w-full" >
                      <div className="relative w-full">
                        <input
                          type="file"
                          name="serviceHistory"
                          onChange={handleFileChange}
                          multiple
                          className="absolute inset-0 w-full h-full opacity-0 z-10 cursor-pointer"
                          accept=".pdf,.doc,.docx"
                        />
                        <div className="flex flex-col items-center w-full h-32 px-4 py-6 
                    bg-white text-gray-500 rounded-lg border-2 border-dashed border-gray-300 
                    transition-all duration-200 
                    hover:bg-gray-50 hover:border-blue-300
                    group-focus-within:border-blue-500 group-focus-within:ring-2 group-focus-within:ring-blue-500 group-focus-within:ring-offset-2">
                    
                          <div className="flex flex-col items-center pointer-events-none">
                            <svg className="w-8 h-8 mb-2 text-gray-400 group-hover:text-blue-500 transition-colors duration-200" 
                              fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                            </svg>
                      
                            <p className="text-sm text-gray-500 text-center group-hover:text-gray-600">
                              <span className="font-medium">Click to upload</span> or drag and drop
                            </p>
                      
                            <p className="text-xs text-gray-500 mt-1">
                        Accepted files: .pdf,.doc,.docx
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {(getNestedValue(formData, 'serviceHistory') || []).length > 0 && (
                      <div className="space-y-2">
                        {(getNestedValue(formData, 'serviceHistory') || []).map((fileName: string, index: number) => (
                          <div key={index} className="flex items-center justify-between p-3 bg-blue-50 rounded-lg">
                            <div className="flex items-center">
                              <svg className="w-5 h-5 text-blue-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                              </svg>
                              <span className="text-sm font-medium text-blue-700">
                                {fileName}
                              </span>
                            </div>
          
                            <button
                              type="button"
                              onClick={() => removeFile('serviceHistory', index)}
                              className="p-1 text-blue-400 hover:text-blue-600 rounded-full hover:bg-blue-100 
              transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                              </svg>
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {currentPage === 4 && (
          <div key="4" className="space-y-4">
                    
            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              ownership
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <fieldset className="w-full">
                    <legend className="text-sm font-medium text-gray-700 mb-3">
            Type of Ownership
                    </legend>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
            
                      <label className="group relative block">
                        <input
                          type="radio"
                          name="ownershipType"
                          value="First"
                          checked={getNestedValue(formData, 'ownershipType') === 'First'}
                          onChange={handleChange}
                          className="peer sr-only"
                    
                        />
                        <div className="relative rounded-xl p-3 border-2 cursor-pointer
                  bg-gradient-to-b from-white to-gray-50
                  border-gray-200 
                  peer-checked:border-blue-500 peer-checked:from-blue-50 peer-checked:to-blue-50
                  peer-focus:ring-2 peer-focus:ring-blue-500 peer-focus:ring-offset-2
                  group-hover:border-blue-200 group-hover:shadow-sm
                  transition-all duration-200">
                          <div className="flex items-center justify-between">
                            <span className="block text-sm font-medium text-gray-900">
                      First
                            </span>
                          </div>
                        </div>
                      </label>
              

                      <label className="group relative block">
                        <input
                          type="radio"
                          name="ownershipType"
                          value="Second"
                          checked={getNestedValue(formData, 'ownershipType') === 'Second'}
                          onChange={handleChange}
                          className="peer sr-only"
                    
                        />
                        <div className="relative rounded-xl p-3 border-2 cursor-pointer
                  bg-gradient-to-b from-white to-gray-50
                  border-gray-200 
                  peer-checked:border-blue-500 peer-checked:from-blue-50 peer-checked:to-blue-50
                  peer-focus:ring-2 peer-focus:ring-blue-500 peer-focus:ring-offset-2
                  group-hover:border-blue-200 group-hover:shadow-sm
                  transition-all duration-200">
                          <div className="flex items-center justify-between">
                            <span className="block text-sm font-medium text-gray-900">
                      Second
                            </span>
                          </div>
                        </div>
                      </label>
              

                      <label className="group relative block">
                        <input
                          type="radio"
                          name="ownershipType"
                          value="Third"
                          checked={getNestedValue(formData, 'ownershipType') === 'Third'}
                          onChange={handleChange}
                          className="peer sr-only"
                    
                        />
                        <div className="relative rounded-xl p-3 border-2 cursor-pointer
                  bg-gradient-to-b from-white to-gray-50
                  border-gray-200 
                  peer-checked:border-blue-500 peer-checked:from-blue-50 peer-checked:to-blue-50
                  peer-focus:ring-2 peer-focus:ring-blue-500 peer-focus:ring-offset-2
                  group-hover:border-blue-200 group-hover:shadow-sm
                  transition-all duration-200">
                          <div className="flex items-center justify-between">
                            <span className="block text-sm font-medium text-gray-900">
                      Third
                            </span>
                          </div>
                        </div>
                      </label>
              

                      <label className="group relative block">
                        <input
                          type="radio"
                          name="ownershipType"
                          value="Fourth or more"
                          checked={getNestedValue(formData, 'ownershipType') === 'Fourth or more'}
                          onChange={handleChange}
                          className="peer sr-only"
                    
                        />
                        <div className="relative rounded-xl p-3 border-2 cursor-pointer
                  bg-gradient-to-b from-white to-gray-50
                  border-gray-200 
                  peer-checked:border-blue-500 peer-checked:from-blue-50 peer-checked:to-blue-50
                  peer-focus:ring-2 peer-focus:ring-blue-500 peer-focus:ring-offset-2
                  group-hover:border-blue-200 group-hover:shadow-sm
                  transition-all duration-200">
                          <div className="flex items-center justify-between">
                            <span className="block text-sm font-medium text-gray-900">
                      Fourth or more
                            </span>
                          </div>
                        </div>
                      </label>
              
                    </div>
                  </fieldset>
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          ownershipHistory
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="ownershipHistory"
                      value={getNestedValue(formData, 'ownershipHistory') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter ownershiphistory..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
          
                    />
        
        
                  </div>
      
      
      
      
                </div>
              </div>
            </div>

            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              multimedia
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
          Vehicle Photo Gallery
                  </label>
                  <div className="group relative">
                    <div className="flex justify-center items-center w-full" >
                      <div className="relative w-full">
                        <input
                          type="file"
                          name="vehicleGallery"
                          onChange={handleFileChange}
                          multiple
                          className="absolute inset-0 w-full h-full opacity-0 z-10 cursor-pointer"
                          accept="image/*"
                        />
                        <div className="flex flex-col items-center w-full h-32 px-4 py-6 
                    bg-white text-gray-500 rounded-lg border-2 border-dashed border-gray-300 
                    transition-all duration-200 
                    hover:bg-gray-50 hover:border-blue-300
                    group-focus-within:border-blue-500 group-focus-within:ring-2 group-focus-within:ring-blue-500 group-focus-within:ring-offset-2">
                    
                          <div className="flex flex-col items-center pointer-events-none">
                            <svg className="w-8 h-8 mb-2 text-gray-400 group-hover:text-blue-500 transition-colors duration-200" 
                              fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                            </svg>
                      
                            <p className="text-sm text-gray-500 text-center group-hover:text-gray-600">
                              <span className="font-medium">Click to upload</span> or drag and drop
                            </p>
                      
                            <p className="text-xs text-gray-500 mt-1">
                        Accepted files: image/*
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {(getNestedValue(formData, 'vehicleGallery') || []).length > 0 && (
                      <div className="space-y-2">
                        {(getNestedValue(formData, 'vehicleGallery') || []).map((fileName: string, index: number) => (
                          <div key={index} className="flex items-center justify-between p-3 bg-blue-50 rounded-lg">
                            <div className="flex items-center">
                              <svg className="w-5 h-5 text-blue-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                              </svg>
                              <span className="text-sm font-medium text-blue-700">
                                {fileName}
                              </span>
                            </div>
          
                            <button
                              type="button"
                              onClick={() => removeFile('vehicleGallery', index)}
                              className="p-1 text-blue-400 hover:text-blue-600 rounded-full hover:bg-blue-100 
              transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                              </svg>
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
          Vehicle Walk-around Video
                  </label>
                  <div className="group relative">
                    <div className="flex justify-center items-center w-full" >
                      <div className="relative w-full">
                        <input
                          type="file"
                          name="walkAroundVideo"
                          onChange={handleFileChange}
                          multiple
                          className="absolute inset-0 w-full h-full opacity-0 z-10 cursor-pointer"
                          accept="video/*"
                        />
                        <div className="flex flex-col items-center w-full h-32 px-4 py-6 
                    bg-white text-gray-500 rounded-lg border-2 border-dashed border-gray-300 
                    transition-all duration-200 
                    hover:bg-gray-50 hover:border-blue-300
                    group-focus-within:border-blue-500 group-focus-within:ring-2 group-focus-within:ring-blue-500 group-focus-within:ring-offset-2">
                    
                          <div className="flex flex-col items-center pointer-events-none">
                            <svg className="w-8 h-8 mb-2 text-gray-400 group-hover:text-blue-500 transition-colors duration-200" 
                              fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                            </svg>
                      
                            <p className="text-sm text-gray-500 text-center group-hover:text-gray-600">
                              <span className="font-medium">Click to upload</span> or drag and drop
                            </p>
                      
                            <p className="text-xs text-gray-500 mt-1">
                        Accepted files: video/*
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {(getNestedValue(formData, 'walkAroundVideo') || []).length > 0 && (
                      <div className="space-y-2">
                        {(getNestedValue(formData, 'walkAroundVideo') || []).map((fileName: string, index: number) => (
                          <div key={index} className="flex items-center justify-between p-3 bg-blue-50 rounded-lg">
                            <div className="flex items-center">
                              <svg className="w-5 h-5 text-blue-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                              </svg>
                              <span className="text-sm font-medium text-blue-700">
                                {fileName}
                              </span>
                            </div>
          
                            <button
                              type="button"
                              onClick={() => removeFile('walkAroundVideo', index)}
                              className="p-1 text-blue-400 hover:text-blue-600 rounded-full hover:bg-blue-100 
              transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                              </svg>
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              onlinePresence
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Manufacturer's Website
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="url"
                      name="manufacturerWebsite"
                      value={getNestedValue(formData, 'manufacturerWebsite') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter manufacturer's website..."
                      className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 "
          
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Service Booking URL
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="url"
                      name="serviceBookingUrl"
                      value={getNestedValue(formData, 'serviceBookingUrl') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter service booking url..."
                      className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 "
          
                    />
        
        
                  </div>
      
      
      
      
                </div>
              </div>
            </div>
          </div>
        )}

        {currentPage === 5 && (
          <div key="5" className="space-y-4">
                    
            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              maintenance
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          maintenanceSchedule
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="maintenanceSchedule"
                      value={getNestedValue(formData, 'maintenanceSchedule') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter maintenanceschedule..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
          
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Next Service Due Date
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="date"
                      name="nextServiceDue"
                      value={getNestedValue(formData, 'nextServiceDue') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter next service due date..."
                      className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 "
          
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <fieldset className="w-full">
                    <legend className="text-sm font-medium text-gray-700 mb-3">
            Servicing Preference
                    </legend>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
            
                      <label className="group relative block">
                        <input
                          type="radio"
                          name="servicingPreference"
                          value="Authorized Dealer"
                          checked={getNestedValue(formData, 'servicingPreference') === 'Authorized Dealer'}
                          onChange={handleChange}
                          className="peer sr-only"
                    
                        />
                        <div className="relative rounded-xl p-3 border-2 cursor-pointer
                  bg-gradient-to-b from-white to-gray-50
                  border-gray-200 
                  peer-checked:border-blue-500 peer-checked:from-blue-50 peer-checked:to-blue-50
                  peer-focus:ring-2 peer-focus:ring-blue-500 peer-focus:ring-offset-2
                  group-hover:border-blue-200 group-hover:shadow-sm
                  transition-all duration-200">
                          <div className="flex items-center justify-between">
                            <span className="block text-sm font-medium text-gray-900">
                      Authorized Dealer
                            </span>
                          </div>
                        </div>
                      </label>
              

                      <label className="group relative block">
                        <input
                          type="radio"
                          name="servicingPreference"
                          value="Local Garage"
                          checked={getNestedValue(formData, 'servicingPreference') === 'Local Garage'}
                          onChange={handleChange}
                          className="peer sr-only"
                    
                        />
                        <div className="relative rounded-xl p-3 border-2 cursor-pointer
                  bg-gradient-to-b from-white to-gray-50
                  border-gray-200 
                  peer-checked:border-blue-500 peer-checked:from-blue-50 peer-checked:to-blue-50
                  peer-focus:ring-2 peer-focus:ring-blue-500 peer-focus:ring-offset-2
                  group-hover:border-blue-200 group-hover:shadow-sm
                  transition-all duration-200">
                          <div className="flex items-center justify-between">
                            <span className="block text-sm font-medium text-gray-900">
                      Local Garage
                            </span>
                          </div>
                        </div>
                      </label>
              

                      <label className="group relative block">
                        <input
                          type="radio"
                          name="servicingPreference"
                          value="Both"
                          checked={getNestedValue(formData, 'servicingPreference') === 'Both'}
                          onChange={handleChange}
                          className="peer sr-only"
                    
                        />
                        <div className="relative rounded-xl p-3 border-2 cursor-pointer
                  bg-gradient-to-b from-white to-gray-50
                  border-gray-200 
                  peer-checked:border-blue-500 peer-checked:from-blue-50 peer-checked:to-blue-50
                  peer-focus:ring-2 peer-focus:ring-blue-500 peer-focus:ring-offset-2
                  group-hover:border-blue-200 group-hover:shadow-sm
                  transition-all duration-200">
                          <div className="flex items-center justify-between">
                            <span className="block text-sm font-medium text-gray-900">
                      Both
                            </span>
                          </div>
                        </div>
                      </label>
              
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>

            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              customization
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          exteriorModifications
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="exteriorModifications"
                      value={getNestedValue(formData, 'exteriorModifications') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter exteriormodifications..."
                      className="h-5 w-5 rounded border-gray-300 text-blue-600 "
          
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          interiorModifications
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="interiorModifications"
                      value={getNestedValue(formData, 'interiorModifications') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter interiormodifications..."
                      className="h-5 w-5 rounded border-gray-300 text-blue-600 "
          
                    />
        
        
                  </div>
      
      
      
      
                </div>
              </div>
            </div>

            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              telemetry
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Current Mileage Reading
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="currentMileage"
                      value={getNestedValue(formData, 'currentMileage') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter current mileage reading..."
                      className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 "
          
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Last Telemetry Update
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="datetime-local"
                      name="lastUpdated"
                      value={getNestedValue(formData, 'lastUpdated') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter last telemetry update..."
                      className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 "
          
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Current Fuel Level (0-100)(%)
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="range"
                      name="fuelLevel"
                      value={getNestedValue(formData, 'fuelLevel') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter current fuel level (0-100)(%)..."
                      className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer "
                      min="0" max="100"
                    />
        
        
                  </div>
      
      
      
      
                </div>
              </div>
            </div>
          </div>
        )}
        {renderPaginationControls()}
      </form>
    </div>
  );
};

export default AutomobileFormForm;