
export default function Footer() {
  return (
    <footer className="bg-gray-800 text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10">
        {/* Footer Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Column 1 */}
          <div>
            <h2 className="text-lg font-semibold mb-4">About Us</h2>
            <p className="text-sm text-gray-400">
              We are dedicated to providing the best services and solutions for
              all your needs. Contact us for more details.
            </p>
          </div>

          {/* Column 2 */}
          <div>
            <h2 className="text-lg font-semibold mb-4">Quick Links</h2>
            <ul className="space-y-2">
              <li>
                <a
                  href="/about"
                  className="hover:text-gray-300 transition-colors duration-300"
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="/services"
                  className="hover:text-gray-300 transition-colors duration-300"
                >
                  Services
                </a>
              </li>
              <li>
                <a
                  href="/contact"
                  className="hover:text-gray-300 transition-colors duration-300"
                >
                  Contact Us
                </a>
              </li>
              <li>
                <a
                  href="/blog"
                  className="hover:text-gray-300 transition-colors duration-300"
                >
                  Blog
                </a>
              </li>
            </ul>
          </div>

          {/* Column 3 */}
          <div>
            <h2 className="text-lg font-semibold mb-4">Follow Us</h2>
            <div className="flex space-x-4">
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-gray-300 transition-colors duration-300"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  className="w-6 h-6"
                >
                  <path d="M22.675 0h-21.35c-.733 0-1.325.592-1.325 1.325v21.351c0 .733.592 1.325 1.325 1.325h11.488v-9.294h-3.121v-3.622h3.121v-2.671c0-3.1 1.894-4.788 4.657-4.788 1.325 0 2.464.099 2.797.143v3.24h-1.92c-1.507 0-1.8.717-1.8 1.766v2.31h3.6l-.469 3.622h-3.131v9.293h6.126c.733 0 1.325-.592 1.325-1.325v-21.35c0-.733-.592-1.325-1.325-1.325z" />
                </svg>
              </a>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-gray-300 transition-colors duration-300"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  className="w-6 h-6"
                >
                  <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.723-.951.564-2.005.974-3.127 1.195-.897-.955-2.173-1.554-3.591-1.554-2.717 0-4.917 2.2-4.917 4.917 0 .385.044.76.128 1.119-4.083-.205-7.699-2.16-10.125-5.134-.423.725-.666 1.566-.666 2.465 0 1.701.867 3.2 2.181 4.081-.805-.026-1.562-.247-2.228-.616v.062c0 2.374 1.688 4.355 3.93 4.805-.411.111-.843.171-1.287.171-.315 0-.623-.031-.924-.088.624 1.951 2.432 3.376 4.575 3.415-1.676 1.313-3.791 2.096-6.088 2.096-.395 0-.787-.023-1.174-.067 2.17 1.391 4.747 2.203 7.523 2.203 9.025 0 13.967-7.479 13.967-13.966 0-.213-.004-.426-.015-.637.961-.694 1.796-1.562 2.457-2.549z" />
                </svg>
              </a>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-gray-300 transition-colors duration-300"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  className="w-6 h-6"
                >
                  <path d="M12 2.163c3.204 0 3.584.012 4.85.07 1.366.062 2.633.31 3.608 1.285.975.975 1.223 2.242 1.285 3.608.058 1.266.07 1.646.07 4.85s-.012 3.584-.07 4.85c-.062 1.366-.31 2.633-1.285 3.608-.975.975-2.242 1.223-3.608 1.285-1.266.058-1.646.07-4.85.07s-3.584-.012-4.85-.07c-1.366-.062-2.633-.31-3.608-1.285-.975-.975-1.223-2.242-1.285-3.608-.058-1.266-.07-1.646-.07-4.85s.012-3.584.07-4.85c.062-1.366.31-2.633 1.285-3.608.975-.975 2.242-1.223 3.608-1.285 1.266-.058 1.646-.07 4.85-.07zm0-2.163c-3.259 0-3.67.012-4.947.072-1.264.058-2.666.307-3.937 1.578-1.271 1.271-1.52 2.673-1.578 3.937-.06 1.277-.072 1.688-.072 4.947s.012 3.67.072 4.947c.058 1.264.307 2.666 1.578 3.937 1.271 1.271 2.673 1.52 3.937 1.578 1.277.06 1.688.072 4.947.072s3.67-.012 4.947-.072c1.264-.058 2.666-.307 3.937-1.578 1.271-1.271 1.52-2.673 1.578-3.937.06-1.277.072-1.688.072-4.947s-.012-3.67-.072-4.947c-.058-1.264-.307-2.666-1.578-3.937-1.271-1.271-2.673-1.52-3.937-1.578-1.277-.06-1.688-.072-4.947-.072z" />
                  <path d="M12 5.838a6.162 6.162 0 100 12.324 6.162 6.162 0 000-12.324zm0 10.162a3.999 3.999 0 110-7.998 3.999 3.999 0 010 7.998zM18.406 4.594a1.44 1.44 0 100 2.88 1.44 1.44 0 000-2.88z" />
                </svg>
              </a>

            </div>
          </div>
        </div>

        {/* Divider */}
        <div className="mt-8 border-t border-gray-600 pt-6 text-center">
          <p className="text-sm text-gray-400">
            © 2023 ADZ Monster. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
