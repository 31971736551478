import React, { useState, useCallback , useEffect } from 'react';
import type { LandAndPlotsForm } from './landandplotsform';
import { useNavigate } from 'react-router-dom';

const STORAGE_KEY = 'formData';
const PAGE_STORAGE_KEY = 'currentFormPage';
const SCHEMA_METADATA = {
  'id': 'cb319a36-7783-4c82-af29-9bbaae40576e',
  'schemaFile': 'D:\\WebstormProjects\\schemas\\ad\\realestate\\land_and_plots\\land_and_plots_1_0_0.json',
};


const LandAndPlotsFormForm: React.FC = () => {
  const [formData, setFormData] = useState<Partial<LandAndPlotsForm>>(() =>{
    try {
      const savedData = localStorage.getItem(STORAGE_KEY);
      return savedData ? JSON.parse(savedData) : {};
    } catch (error) {
      console.error('Error parsing saved form data:', error);
      return {};
    }
  });
  const [currentPage, setCurrentPage] = useState(0);
  const totalPages = 4;
  const navigate = useNavigate();
  
  // Save form data to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(formData));
  }, [formData]);

  // Save current page to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem(PAGE_STORAGE_KEY, currentPage.toString());
  }, [currentPage]);

  const setNestedValue = (obj: any, path: string, value: any) => {
    const pathParts = path.split('.');
    const sectionKey = pathParts[0]; // Get the section key (e.g., generalInformation)
    const fieldPath = pathParts.slice(1); // Get the rest of the path

    // If there's no section, treat it as a root-level field
    if (fieldPath.length === 0) {
      return { ...obj, [sectionKey]: value };
    }

    // Ensure the section exists
    const section = obj[sectionKey] || {};
  
    // If we only have one field in the path, set it directly
    if (fieldPath.length === 1) {
      return {
        ...obj,
        [sectionKey]: {
          ...section,
          [fieldPath[0]]: value,
        },
      };
    }

    // For nested fields within the section
    const current = { ...section };
    const lastKey = fieldPath.pop()!;
    let pointer = current;

    for (const key of fieldPath) {
      pointer[key] = pointer[key] || {};
      pointer = pointer[key];
    }

    pointer[lastKey] = value;

    return {
      ...obj,
      [sectionKey]: current,
    };
  };

  const getNestedValue = (obj: any, path: string) => {
    try {
      return path.split('.').reduce((acc, part) => acc?.[part], obj) ?? '';
    } catch {
      return '';
    }
  };
  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(0, prev - 1));
  };

  const handleNextPage = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setCurrentPage((prev) => Math.min(totalPages - 1, prev + 1));
  };
   
  const handleDraft = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    // Save the current form data as a draft
    localStorage.setItem(STORAGE_KEY, JSON.stringify(formData));
    localStorage.setItem(PAGE_STORAGE_KEY, currentPage.toString());
    console.log('Draft saved');
  };
   
  // Add a reset function to clear the form
  const resetForm = useCallback(() => {
    setFormData({});
    setCurrentPage(0);
    localStorage.removeItem(STORAGE_KEY);
    localStorage.removeItem(PAGE_STORAGE_KEY);
  }, []);
  

  const renderPaginationControls = () => {
    return (
      <div className="flex flex-col sm:flex-row justify-between items-center gap-4 px-4 py-6 bg-white rounded-lg shadow-sm">
        <button
          type="button"
          onClick={handlePrevPage}
          disabled={currentPage === 0}
          className="max-sm:w-15 max-md::w-30 lg:w-32 xl:w-32 px-5 py-3 bg-gradient-to-r from-blue-500 to-blue-600 text-white font-medium rounded-xl 
        transform transition-all duration-200 
        hover:from-blue-600 hover:to-blue-700 hover:shadow-md 
        active:scale-95
        disabled:from-gray-300 disabled:to-gray-400 disabled:cursor-not-allowed disabled:transform-none
        focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          <span className="flex items-center justify-center">
            <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          Previous
          </span>
        </button>
        <button
          type="button"
          onClick={handleDraft}
          disabled={currentPage === 0}
          className="max-sm:w-25 max-md::w-30 lg:w-36 xl:w-38 px-5 py-3 bg-white text-gray-600 font-medium rounded-xl hover:text-green-600"
        >
          <span className="flex items-center justify-center">
          Save as Draft
          </span>
        </button>
      
      
        {currentPage === totalPages - 1 ? (
          <button
            type="submit"
            className="max-sm:w-15 max-md::w-30 lg:w-32 xl:w-32 px-5 py-3 bg-gradient-to-r from-green-500 to-green-600 text-white font-medium rounded-xl 
          transform transition-all duration-200 
          hover:from-green-600 hover:to-green-700 hover:shadow-md 
          active:scale-95
          focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
          >
            <span className="flex items-center justify-center">
            Submit
              <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
            </span>
          </button>
        ) : (
          <button
            type="button"
            onClick={handleNextPage}
            className="max-sm:w-15 max-md::w-30 lg:w-32 xl:w-32 px-5 py-3 bg-gradient-to-r from-blue-500 to-blue-600 text-white font-medium rounded-xl 
          transform transition-all duration-200 
          hover:from-blue-600 hover:to-blue-700 hover:shadow-md 
          active:scale-95
          focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            <span className="flex items-center justify-center">
            Next
              <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </span>
          </button>
        )}
      </div>
    );
  };

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;

    setFormData((prev) => {
      let newValue: any;

      switch (type) {
        case 'checkbox':
          newValue = (e.target as HTMLInputElement).checked;
          break;
        case 'number':
        case 'range':
          newValue = value === '' ? null : Number(value);
          break;
        case 'radio':
          newValue = value;
          break;
        case 'file':
          return prev; // Files are handled by handleFileChange
        default:
          newValue = value;
      }

      return setNestedValue({ ...prev }, name, newValue);
    });
  }, []);

  const handleFileChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = e.target;
    if (files && files.length > 0) {
      setFormData((prev) => {
      // Get existing files array or initialize empty array
        const existingFiles = getNestedValue(prev, name) || [];
        // Add new files to existing files, avoiding duplicates
        const newFiles = Array.from(files).map((file) => file.name);
        const uniqueFiles = [...new Set([...existingFiles, ...newFiles])];
        return setNestedValue({ ...prev }, name, uniqueFiles);
      });
    }
  }, [formData, getNestedValue]);
  
  // Add a function to remove individual files
  const removeFile = useCallback((fieldName: string, fileIndex: number) => {
    setFormData((prev) => {
      const files = getNestedValue(prev, fieldName) || [];
      const newFiles = files.filter((_: any, index: number) => index !== fileIndex);
      return setNestedValue({ ...prev }, fieldName, newFiles);
    });
  }, []);



  const handleSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    // Process form data to ensure all fields are included
    const processFormData = (data: any) => {
      const processed: any = {};
      
      // Helper function to handle empty/undefined values
      const processValue = (value: any) => {
        if (Array.isArray(value)) {
          return value.length > 0 ? value : [];
        }
        if (value === '') {
          return null;
        }
        return value ?? null;
      };

      // Process each field, maintaining structure
      Object.entries(data).forEach(([key, value]) => {
        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
          processed[key] = processFormData(value); // Recursively process nested objects
        } else {
          processed[key] = processValue(value);
        }
      });

      return processed;
    };


    // Create the new payload structure
    const payload = {      
         
      primaryCategoryId: SCHEMA_METADATA.id,
      schemaFile: SCHEMA_METADATA.schemaFile,
      data: JSON.stringify(processFormData(formData)),
    };
    console.log('Payload being sent:', JSON.stringify(payload, null, 2));

    try {
      const response = await fetch('https://adzmonster.com/api/ads/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log('Success:', result);
      // Clear form data after successful submission
      resetForm();
    } catch (error) {
      console.error('Error:', error);
    }
  }, [formData, resetForm]);
  return (
    <div className="min-h-screen from-blue-50/50 to-white flex items-center justify-center p-4 sm:p-6 md:p-8 lg:p-9">
      <form onSubmit={handleSubmit} className="w-full max-w-4xl mx-auto bg-white backdrop-blur-lg bg-opacity-95 rounded-2xl shadow-xl border border-white border-opacity-20 p-4 sm:p-6 md:p-8 transition-all duration-300 hover:shadow-2xl">
        {/* Progress Bar */}
        <div className="mb-8">
          <div className="w-full bg-gray-100 rounded-full h-3 overflow-hidden shadow-inner">
            <div
              className="bg-gradient-to-r from-blue-500 to-purple-500 h-3 rounded-full transition-all duration-500 ease-in-out"
              style={{ width: `${((currentPage + 1) / totalPages) * 100}%` }}
            />
          </div>
          <div className="flex items-center justify-center px-4 py-2 bg-white rounded-lg">
            <span className="text-sm sm:text-base font-medium text-gray-700">
                  Step {currentPage + 1} of {totalPages}
            </span>
          </div>
        </div>
        {/* Action Buttons */}
        <div className="flex flex-col sm:flex-row justify-between items-center gap-4 mb-8">
          {/* Navigation Links */}
          <button
            type="button"
            onClick={() => navigate('/v1')}
            className="group flex items-center px-4 py-2 text-sm font-medium text-gray-700 hover:text-blue-600 transition-all duration-200"
          >
            <svg 
              className="w-4 h-4 mr-2 transform transition-transform group-hover:-translate-x-1" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
                    Back to Home
          </button>
        
          {/* Reset Button */}
          <button
            type="button"
            onClick={resetForm}
            className="flex items-center px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-red-500 to-red-600 rounded-xl 
                      transform transition-all duration-200 
                      hover:from-red-600 hover:to-red-700 hover:shadow-md 
                      active:scale-95
                      focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
          >
            <svg 
              className="w-4 h-4 mr-2" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
            </svg>
                      Reset Form
          </button>
        </div>
                
        {currentPage === 0 && (
          <div key="0" className="space-y-4">
                    
            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              basicDetails
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Unique identifier for the plot
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                    <div className="mt-1 relative group">
                      <button type="button" 
                        className="flex items-center justify-center w-5 h-5 text-blue-500 bg-blue-50 rounded-full 
            transition-all duration-200 hover:bg-blue-100">
                        <span className="text-xs font-medium">i</span>
                      </button>
                      <div className="absolute right-0 bottom-full mb-2 w-72 sm:w-50 p-4 bg-white border 
          rounded-lg shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible 
          transition-all duration-200 z-10" />
                    </div>
  
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="plotId"
                      value={getNestedValue(formData, 'plotId') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter unique identifier for the plot..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
    pr-10 
    ${!new RegExp('^PLOT[0-9]{6}$').test(getNestedValue(formData, 'plotId') || '') && getNestedValue(formData, 'plotId') 
            ? 'border-red-300 focus:border-red-500 focus:ring-red-500' 
            : ''}
  `}
                      pattern="^PLOT[0-9]{6}$" required
                    />
        
        
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      {!new RegExp('^PLOT[0-9]{6}$').test(getNestedValue(formData, 'plotId') || '') && 
             getNestedValue(formData, 'plotId') && (
                        <svg className="w-5 h-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      )}
                    </div>
        
                  </div>
      
      
                  {getNestedValue(formData, 'plotId') && !new RegExp('^PLOT[0-9]{6}$').test(getNestedValue(formData, 'plotId')) && (
                    <p className="mt-1 text-sm text-red-600">
        undefined
                    </p>
                  )}
  
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Name or title of the plot
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                    <div className="mt-1 relative group">
                      <button type="button" 
                        className="flex items-center justify-center w-5 h-5 text-blue-500 bg-blue-50 rounded-full 
            transition-all duration-200 hover:bg-blue-100">
                        <span className="text-xs font-medium">i</span>
                      </button>
                      <div className="absolute right-0 bottom-full mb-2 w-72 sm:w-50 p-4 bg-white border 
          rounded-lg shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible 
          transition-all duration-200 z-10" />
                    </div>
  
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="plotName"
                      value={getNestedValue(formData, 'plotName') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter name or title of the plot..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
    pr-10 
    ${!new RegExp('a-zA-Z0-9s-]{3,100}$').test(getNestedValue(formData, 'plotName') || '') && getNestedValue(formData, 'plotName') 
            ? 'border-red-300 focus:border-red-500 focus:ring-red-500' 
            : ''}
  `}
                      pattern="a-zA-Z0-9s-]{3,100}$" required
                    />
        
        
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      {!new RegExp('a-zA-Z0-9s-]{3,100}$').test(getNestedValue(formData, 'plotName') || '') && 
             getNestedValue(formData, 'plotName') && (
                        <svg className="w-5 h-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      )}
                    </div>
        
                  </div>
      
      
                  {getNestedValue(formData, 'plotName') && !new RegExp('a-zA-Z0-9s-]{3,100}$').test(getNestedValue(formData, 'plotName')) && (
                    <p className="mt-1 text-sm text-red-600">
        undefined
                    </p>
                  )}
  
      
      
                </div>

                <div className="mb-6">
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
          Type of plot
                    </label>
                    <div className="relative group">
                      <select
                        name="plotType"
                        value={getNestedValue(formData, 'plotType') ?? ''}
                        onChange={handleChange}
                        className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
                        required
                      >
                        <option value="" className="text-gray-500">Select plotType</option>
                        <option key="residential" value="residential">residential</option>
                        <option key="commercial" value="commercial">commercial</option>
                        <option key="agricultural" value="agricultural">agricultural</option>
                        <option key="industrial" value="industrial">industrial</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                    <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              plotSize
                    </h3>
                  </div>
                  <div className="space-y-4 px-6">
          
                    <div className="mb-6">
                      <div className="flex justify-between items-center mb-2">
                        <label className="block text-sm font-medium text-gray-700">
          area
          
                        </label>
        
                      </div>
      
                      <div className="relative">
                        <input
                          type="number"
                          name="area"
                          value={getNestedValue(formData, 'area') ?? ''}
                          onChange={handleChange}
                          placeholder="Enter area..."
                          className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                          min="0"
                        />
        
        
                      </div>
      
      
      
      
                    </div>

                    <div className="mb-6">
                      <div className="relative">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
          unit
                        </label>
                        <div className="relative group">
                          <select
                            name="unit"
                            value={getNestedValue(formData, 'unit') ?? ''}
                            onChange={handleChange}
                            className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
          
                          >
                            <option value="" className="text-gray-500">Select unit</option>
                            <option key="sqft" value="sqft">sqft</option>
                            <option key="sqm" value="sqm">sqm</option>
                            <option key="acres" value="acres">acres</option>
                            <option key="hectares" value="hectares">hectares</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                    <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              dimensions
                    </h3>
                  </div>
                  <div className="space-y-4 px-6">
          
                    <div className="mb-6">
                      <div className="flex justify-between items-center mb-2">
                        <label className="block text-sm font-medium text-gray-700">
          frontage
          
                        </label>
        
                      </div>
      
                      <div className="relative">
                        <input
                          type="number"
                          name="frontage"
                          value={getNestedValue(formData, 'frontage') ?? ''}
                          onChange={handleChange}
                          placeholder="Enter frontage..."
                          className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                          min="0"
                        />
        
        
                      </div>
      
      
      
      
                    </div>

                    <div className="mb-6">
                      <div className="flex justify-between items-center mb-2">
                        <label className="block text-sm font-medium text-gray-700">
          depth
          
                        </label>
        
                      </div>
      
                      <div className="relative">
                        <input
                          type="number"
                          name="depth"
                          value={getNestedValue(formData, 'depth') ?? ''}
                          onChange={handleChange}
                          placeholder="Enter depth..."
                          className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                          min="0"
                        />
        
        
                      </div>
      
      
      
      
                    </div>
                  </div>
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          frontRoadWidth
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="frontRoadWidth"
                      value={getNestedValue(formData, 'frontRoadWidth') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter frontroadwidth..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="0"
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
          facingDirection
                    </label>
                    <div className="relative group">
                      <select
                        name="facingDirection"
                        value={getNestedValue(formData, 'facingDirection') ?? ''}
                        onChange={handleChange}
                        className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
          
                      >
                        <option value="" className="text-gray-500">Select facingDirection</option>
                        <option key="north" value="north">north</option>
                        <option key="south" value="south">south</option>
                        <option key="east" value="east">east</option>
                        <option key="west" value="west">west</option>
                        <option key="north-east" value="north-east">north-east</option>
                        <option key="north-west" value="north-west">north-west</option>
                        <option key="south-east" value="south-east">south-east</option>
                        <option key="south-west" value="south-west">south-west</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="mb-6">
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
          propertyUsage
                    </label>
                    <div className="relative group">
                      <select
                        name="propertyUsage"
                        value={getNestedValue(formData, 'propertyUsage') ?? ''}
                        onChange={handleChange}
                        className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
          
                      >
                        <option value="" className="text-gray-500">Select propertyUsage</option>
                        <option key="residential" value="residential">residential</option>
                        <option key="commercial" value="commercial">commercial</option>
                        <option key="agricultural" value="agricultural">agricultural</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="mb-6">
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
          landCondition
                    </label>
                    <div className="relative group">
                      <select
                        name="landCondition"
                        value={getNestedValue(formData, 'landCondition') ?? ''}
                        onChange={handleChange}
                        className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
          
                      >
                        <option value="" className="text-gray-500">Select landCondition</option>
                        <option key="vacant" value="vacant">vacant</option>
                        <option key="developed" value="developed">developed</option>
                        <option key="under_construction" value="under_construction">under_construction</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              address
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          fullAddress
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="textarea"
                      name="fullAddress"
                      value={getNestedValue(formData, 'fullAddress') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter fulladdress..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      required
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          city
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                    <div className="mt-1 relative group">
                      <button type="button" 
                        className="flex items-center justify-center w-5 h-5 text-blue-500 bg-blue-50 rounded-full 
            transition-all duration-200 hover:bg-blue-100">
                        <span className="text-xs font-medium">i</span>
                      </button>
                      <div className="absolute right-0 bottom-full mb-2 w-72 sm:w-50 p-4 bg-white border 
          rounded-lg shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible 
          transition-all duration-200 z-10" />
                    </div>
  
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="city"
                      value={getNestedValue(formData, 'city') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter city..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
    pr-10 
    ${!new RegExp('^[a-zA-Zs]{2,50}$').test(getNestedValue(formData, 'city') || '') && getNestedValue(formData, 'city') 
            ? 'border-red-300 focus:border-red-500 focus:ring-red-500' 
            : ''}
  `}
                      pattern="^[a-zA-Zs]{2,50}$" required
                    />
        
        
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      {!new RegExp('^[a-zA-Zs]{2,50}$').test(getNestedValue(formData, 'city') || '') && 
             getNestedValue(formData, 'city') && (
                        <svg className="w-5 h-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      )}
                    </div>
        
                  </div>
      
      
                  {getNestedValue(formData, 'city') && !new RegExp('^[a-zA-Zs]{2,50}$').test(getNestedValue(formData, 'city')) && (
                    <p className="mt-1 text-sm text-red-600">
        undefined
                    </p>
                  )}
  
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          state
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="select"
                      name="state"
                      value={getNestedValue(formData, 'state') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter state..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      required
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          zipCode
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                    <div className="mt-1 relative group">
                      <button type="button" 
                        className="flex items-center justify-center w-5 h-5 text-blue-500 bg-blue-50 rounded-full 
            transition-all duration-200 hover:bg-blue-100">
                        <span className="text-xs font-medium">i</span>
                      </button>
                      <div className="absolute right-0 bottom-full mb-2 w-72 sm:w-50 p-4 bg-white border 
          rounded-lg shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible 
          transition-all duration-200 z-10" />
                    </div>
  
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="zipCode"
                      value={getNestedValue(formData, 'zipCode') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter zipcode..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
    pr-10 
    ${!new RegExp('^[0-9]{5,6}$').test(getNestedValue(formData, 'zipCode') || '') && getNestedValue(formData, 'zipCode') 
            ? 'border-red-300 focus:border-red-500 focus:ring-red-500' 
            : ''}
  `}
                      pattern="^[0-9]{5,6}$" required
                    />
        
        
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      {!new RegExp('^[0-9]{5,6}$').test(getNestedValue(formData, 'zipCode') || '') && 
             getNestedValue(formData, 'zipCode') && (
                        <svg className="w-5 h-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      )}
                    </div>
        
                  </div>
      
      
                  {getNestedValue(formData, 'zipCode') && !new RegExp('^[0-9]{5,6}$').test(getNestedValue(formData, 'zipCode')) && (
                    <p className="mt-1 text-sm text-red-600">
        undefined
                    </p>
                  )}
  
      
      
                </div>

                <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                    <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              coordinates
                    </h3>
                  </div>
                  <div className="space-y-4 px-6">
          
                    <div className="mb-6">
                      <div className="flex justify-between items-center mb-2">
                        <label className="block text-sm font-medium text-gray-700">
          latitude
          
                        </label>
        
                      </div>
      
                      <div className="relative">
                        <input
                          type="number"
                          name="latitude"
                          value={getNestedValue(formData, 'latitude') ?? ''}
                          onChange={handleChange}
                          placeholder="Enter latitude..."
                          className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                          min="-90" max="90"
                        />
        
        
                      </div>
      
      
      
      
                    </div>

                    <div className="mb-6">
                      <div className="flex justify-between items-center mb-2">
                        <label className="block text-sm font-medium text-gray-700">
          longitude
          
                        </label>
        
                      </div>
      
                      <div className="relative">
                        <input
                          type="number"
                          name="longitude"
                          value={getNestedValue(formData, 'longitude') ?? ''}
                          onChange={handleChange}
                          placeholder="Enter longitude..."
                          className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                          min="-180" max="180"
                        />
        
        
                      </div>
      
      
      
      
                    </div>
                  </div>
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          landmark
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="landmark"
                      value={getNestedValue(formData, 'landmark') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter landmark..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
          
                    />
        
        
                  </div>
      
      
      
      
                </div>
              </div>
            </div>

            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              legalInformation
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
          ownershipType
                    </label>
                    <div className="relative group">
                      <select
                        name="ownershipType"
                        value={getNestedValue(formData, 'ownershipType') ?? ''}
                        onChange={handleChange}
                        className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
          
                      >
                        <option value="" className="text-gray-500">Select ownershipType</option>
                        <option key="freehold" value="freehold">freehold</option>
                        <option key="leasehold" value="leasehold">leasehold</option>
                        <option key="joint" value="joint">joint</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="mb-4">
                  <label className="inline-flex items-start">
                    <input
                      type="checkbox"
                      name="titleClearStatus"
                      checked={Boolean(getNestedValue(formData, 'titleClearStatus'))}
                      onChange={handleChange}
                      className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                    />
                    <span className="ml-2 text-sm text-gray-600">
          titleClearStatus
                    </span>
                  </label>
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          surveyNumber
          
                    </label>
        
                    <div className="mt-1 relative group">
                      <button type="button" 
                        className="flex items-center justify-center w-5 h-5 text-blue-500 bg-blue-50 rounded-full 
            transition-all duration-200 hover:bg-blue-100">
                        <span className="text-xs font-medium">i</span>
                      </button>
                      <div className="absolute right-0 bottom-full mb-2 w-72 sm:w-50 p-4 bg-white border 
          rounded-lg shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible 
          transition-all duration-200 z-10" />
                    </div>
  
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="surveyNumber"
                      value={getNestedValue(formData, 'surveyNumber') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter surveynumber..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
    pr-10 
    ${!new RegExp('^[A-Z0-9/]{3,15}$').test(getNestedValue(formData, 'surveyNumber') || '') && getNestedValue(formData, 'surveyNumber') 
            ? 'border-red-300 focus:border-red-500 focus:ring-red-500' 
            : ''}
  `}
                      pattern="^[A-Z0-9/]{3,15}$"
                    />
        
        
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      {!new RegExp('^[A-Z0-9/]{3,15}$').test(getNestedValue(formData, 'surveyNumber') || '') && 
             getNestedValue(formData, 'surveyNumber') && (
                        <svg className="w-5 h-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      )}
                    </div>
        
                  </div>
      
      
                  {getNestedValue(formData, 'surveyNumber') && !new RegExp('^[A-Z0-9/]{3,15}$').test(getNestedValue(formData, 'surveyNumber')) && (
                    <p className="mt-1 text-sm text-red-600">
        undefined
                    </p>
                  )}
  
      
      
                </div>

                <div className="mb-6">
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
          zoningCompliance
                    </label>
                    <div className="relative group">
                      <select
                        name="zoningCompliance"
                        value={getNestedValue(formData, 'zoningCompliance') ?? ''}
                        onChange={handleChange}
                        className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
          
                      >
                        <option value="" className="text-gray-500">Select zoningCompliance</option>
                        <option key="residential" value="residential">residential</option>
                        <option key="commercial" value="commercial">commercial</option>
                        <option key="agricultural" value="agricultural">agricultural</option>
                        <option key="industrial" value="industrial">industrial</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
          encumbranceCertificate
                  </label>
                  <div className="group relative">
                    <div className="flex justify-center items-center w-full" >
                      <div className="relative w-full">
                        <input
                          type="file"
                          name="encumbranceCertificate"
                          onChange={handleFileChange}
                          multiple
                          className="absolute inset-0 w-full h-full opacity-0 z-10 cursor-pointer"
                          pattern="\.pdf$"
                        />
                        <div className="flex flex-col items-center w-full h-32 px-4 py-6 
                    bg-white text-gray-500 rounded-lg border-2 border-dashed border-gray-300 
                    transition-all duration-200 
                    hover:bg-gray-50 hover:border-blue-300
                    group-focus-within:border-blue-500 group-focus-within:ring-2 group-focus-within:ring-blue-500 group-focus-within:ring-offset-2">
                    
                          <div className="flex flex-col items-center pointer-events-none">
                            <svg className="w-8 h-8 mb-2 text-gray-400 group-hover:text-blue-500 transition-colors duration-200" 
                              fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                            </svg>
                      
                            <p className="text-sm text-gray-500 text-center group-hover:text-gray-600">
                              <span className="font-medium">Click to upload</span> or drag and drop
                            </p>
                      
                            <p className="text-xs text-gray-500 mt-1">
                        All file types accepted
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {(getNestedValue(formData, 'encumbranceCertificate') || []).length > 0 && (
                      <div className="space-y-2">
                        {(getNestedValue(formData, 'encumbranceCertificate') || []).map((fileName: string, index: number) => (
                          <div key={index} className="flex items-center justify-between p-3 bg-blue-50 rounded-lg">
                            <div className="flex items-center">
                              <svg className="w-5 h-5 text-blue-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                              </svg>
                              <span className="text-sm font-medium text-blue-700">
                                {fileName}
                              </span>
                            </div>
          
                            <button
                              type="button"
                              onClick={() => removeFile('encumbranceCertificate', index)}
                              className="p-1 text-blue-400 hover:text-blue-600 rounded-full hover:bg-blue-100 
              transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                              </svg>
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className="mb-6">
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
          propertyTaxStatus
                    </label>
                    <div className="relative group">
                      <select
                        name="propertyTaxStatus"
                        value={getNestedValue(formData, 'propertyTaxStatus') ?? ''}
                        onChange={handleChange}
                        className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
          
                      >
                        <option value="" className="text-gray-500">Select propertyTaxStatus</option>
                        <option key="cleared" value="cleared">cleared</option>
                        <option key="pending" value="pending">pending</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {currentPage === 1 && (
          <div key="1" className="space-y-4">
                    
            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              pricing
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          listingPrice
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                    <div className="mt-1 relative group">
                      <button type="button" 
                        className="flex items-center justify-center w-5 h-5 text-blue-500 bg-blue-50 rounded-full 
            transition-all duration-200 hover:bg-blue-100">
                        <span className="text-xs font-medium">i</span>
                      </button>
                      <div className="absolute right-0 bottom-full mb-2 w-72 sm:w-50 p-4 bg-white border 
          rounded-lg shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible 
          transition-all duration-200 z-10" />
                    </div>
  
                  </div>
      
                  <div className="relative">
                    <input
                      type="currency"
                      name="listingPrice"
                      value={getNestedValue(formData, 'listingPrice') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter listingprice..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
    pr-10 
    ${!new RegExp('^[0-9]+(.[0-9]{1,2})?$').test(getNestedValue(formData, 'listingPrice') || '') && getNestedValue(formData, 'listingPrice') 
            ? 'border-red-300 focus:border-red-500 focus:ring-red-500' 
            : ''}
  `}
                      pattern="^[0-9]+(.[0-9]{1,2})?$" min="0" required
                    />
        
        
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      {!new RegExp('^[0-9]+(.[0-9]{1,2})?$').test(getNestedValue(formData, 'listingPrice') || '') && 
             getNestedValue(formData, 'listingPrice') && (
                        <svg className="w-5 h-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      )}
                    </div>
        
                  </div>
      
      
                  {getNestedValue(formData, 'listingPrice') && !new RegExp('^[0-9]+(.[0-9]{1,2})?$').test(getNestedValue(formData, 'listingPrice')) && (
                    <p className="mt-1 text-sm text-red-600">
        undefined
                    </p>
                  )}
  
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          pricePerUnit
          
                    </label>
        
                    <div className="mt-1 relative group">
                      <button type="button" 
                        className="flex items-center justify-center w-5 h-5 text-blue-500 bg-blue-50 rounded-full 
            transition-all duration-200 hover:bg-blue-100">
                        <span className="text-xs font-medium">i</span>
                      </button>
                      <div className="absolute right-0 bottom-full mb-2 w-72 sm:w-50 p-4 bg-white border 
          rounded-lg shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible 
          transition-all duration-200 z-10" />
                    </div>
  
                  </div>
      
                  <div className="relative">
                    <input
                      type="currency"
                      name="pricePerUnit"
                      value={getNestedValue(formData, 'pricePerUnit') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter priceperunit..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
    pr-10 
    ${!new RegExp('^[0-9]+(.[0-9]{1,2})?$').test(getNestedValue(formData, 'pricePerUnit') || '') && getNestedValue(formData, 'pricePerUnit') 
            ? 'border-red-300 focus:border-red-500 focus:ring-red-500' 
            : ''}
  `}
                      pattern="^[0-9]+(.[0-9]{1,2})?$" min="0"
                    />
        
        
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      {!new RegExp('^[0-9]+(.[0-9]{1,2})?$').test(getNestedValue(formData, 'pricePerUnit') || '') && 
             getNestedValue(formData, 'pricePerUnit') && (
                        <svg className="w-5 h-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      )}
                    </div>
        
                  </div>
      
      
                  {getNestedValue(formData, 'pricePerUnit') && !new RegExp('^[0-9]+(.[0-9]{1,2})?$').test(getNestedValue(formData, 'pricePerUnit')) && (
                    <p className="mt-1 text-sm text-red-600">
        undefined
                    </p>
                  )}
  
      
      
                </div>

                <div className="mb-4">
                  <label className="inline-flex items-start">
                    <input
                      type="checkbox"
                      name="isNegotiable"
                      checked={Boolean(getNestedValue(formData, 'isNegotiable'))}
                      onChange={handleChange}
                      className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                    />
                    <span className="ml-2 text-sm text-gray-600">
          isNegotiable
                    </span>
                  </label>
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          downPayment
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="currency"
                      name="downPayment"
                      value={getNestedValue(formData, 'downPayment') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter downpayment..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="0"
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-4">
                  <label className="inline-flex items-start">
                    <input
                      type="checkbox"
                      name="loanAvailability"
                      checked={Boolean(getNestedValue(formData, 'loanAvailability'))}
                      onChange={handleChange}
                      className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                    />
                    <span className="ml-2 text-sm text-gray-600">
          loanAvailability
                    </span>
                  </label>
                </div>
              </div>
            </div>

            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              connectivity
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                    <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              nearbyTransport
                    </h3>
                  </div>
                  <div className="space-y-4 px-6">
          
                    <div className="mb-6">
                      <div className="flex justify-between items-center mb-2">
                        <label className="block text-sm font-medium text-gray-700">
          Distance in kilometers
          
                        </label>
        
                      </div>
      
                      <div className="relative">
                        <input
                          type="number"
                          name="busStation"
                          value={getNestedValue(formData, 'busStation') ?? ''}
                          onChange={handleChange}
                          placeholder="Enter distance in kilometers..."
                          className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                          min="0"
                        />
        
        
                      </div>
      
      
      
      
                    </div>

                    <div className="mb-6">
                      <div className="flex justify-between items-center mb-2">
                        <label className="block text-sm font-medium text-gray-700">
          Distance in kilometers
          
                        </label>
        
                      </div>
      
                      <div className="relative">
                        <input
                          type="number"
                          name="metroStation"
                          value={getNestedValue(formData, 'metroStation') ?? ''}
                          onChange={handleChange}
                          placeholder="Enter distance in kilometers..."
                          className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                          min="0"
                        />
        
        
                      </div>
      
      
      
      
                    </div>

                    <div className="mb-6">
                      <div className="flex justify-between items-center mb-2">
                        <label className="block text-sm font-medium text-gray-700">
          Distance in kilometers
          
                        </label>
        
                      </div>
      
                      <div className="relative">
                        <input
                          type="number"
                          name="trainStation"
                          value={getNestedValue(formData, 'trainStation') ?? ''}
                          onChange={handleChange}
                          placeholder="Enter distance in kilometers..."
                          className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                          min="0"
                        />
        
        
                      </div>
      
      
      
      
                    </div>

                    <div className="mb-6">
                      <div className="flex justify-between items-center mb-2">
                        <label className="block text-sm font-medium text-gray-700">
          Distance in kilometers
          
                        </label>
        
                      </div>
      
                      <div className="relative">
                        <input
                          type="number"
                          name="airport"
                          value={getNestedValue(formData, 'airport') ?? ''}
                          onChange={handleChange}
                          placeholder="Enter distance in kilometers..."
                          className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                          min="0"
                        />
        
        
                      </div>
      
      
      
      
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              utilities
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <fieldset>
                    <legend className="text-sm font-medium text-gray-700 mb-3">
            Basic
                    </legend>
                    <div className="grid grid-cols-2 sm:grid-cols-1 lg:grid-cols-3  gap-x-6 gap-y-2 items-center justify-center">
            
                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="Basic"
                          value="electricity"
                          checked={Array.isArray(getNestedValue(formData, 'Basic')) && 
                    getNestedValue(formData, 'Basic').includes('electricity')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'Basic')) 
                              ? getNestedValue(formData, 'Basic') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'electricity']
                              : currentValues.filter((val: string) => val !== 'electricity');
                            setFormData((prev) => setNestedValue({ ...prev }, 'Basic', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  electricity
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="Basic"
                          value="sewageSystem"
                          checked={Array.isArray(getNestedValue(formData, 'Basic')) && 
                    getNestedValue(formData, 'Basic').includes('sewageSystem')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'Basic')) 
                              ? getNestedValue(formData, 'Basic') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'sewageSystem']
                              : currentValues.filter((val: string) => val !== 'sewageSystem');
                            setFormData((prev) => setNestedValue({ ...prev }, 'Basic', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  sewageSystem
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="Basic"
                          value="boundaryWall"
                          checked={Array.isArray(getNestedValue(formData, 'Basic')) && 
                    getNestedValue(formData, 'Basic').includes('boundaryWall')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'Basic')) 
                              ? getNestedValue(formData, 'Basic') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'boundaryWall']
                              : currentValues.filter((val: string) => val !== 'boundaryWall');
                            setFormData((prev) => setNestedValue({ ...prev }, 'Basic', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  boundaryWall
                        </span>
                      </label>
            
                    </div>
                  </fieldset>
                </div>

                <div className="mb-6">
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
          waterSupply
                    </label>
                    <div className="relative group">
                      <select
                        name="waterSupply"
                        value={getNestedValue(formData, 'waterSupply') ?? ''}
                        onChange={handleChange}
                        className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
          
                      >
                        <option value="" className="text-gray-500">Select waterSupply</option>
                        <option key="municipal" value="municipal">municipal</option>
                        <option key="borewell" value="borewell">borewell</option>
                        <option key="none" value="none">none</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="mb-6">
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
          roadType
                    </label>
                    <div className="relative group">
                      <select
                        name="roadType"
                        value={getNestedValue(formData, 'roadType') ?? ''}
                        onChange={handleChange}
                        className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
          
                      >
                        <option value="" className="text-gray-500">Select roadType</option>
                        <option key="tar" value="tar">tar</option>
                        <option key="concrete" value="concrete">concrete</option>
                        <option key="gravel" value="gravel">gravel</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {currentPage === 2 && (
          <div key="2" className="space-y-4">
                    
            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              environmental
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-4">
                  <label className="inline-flex items-start">
                    <input
                      type="checkbox"
                      name="floodZone"
                      checked={Boolean(getNestedValue(formData, 'floodZone'))}
                      onChange={handleChange}
                      className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                    />
                    <span className="ml-2 text-sm text-gray-600">
          floodZone
                    </span>
                  </label>
                </div>

                <div className="mb-6">
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
          soilType
                    </label>
                    <div className="relative group">
                      <select
                        name="soilType"
                        value={getNestedValue(formData, 'soilType') ?? ''}
                        onChange={handleChange}
                        className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
          
                      >
                        <option value="" className="text-gray-500">Select soilType</option>
                        <option key="clay" value="clay">clay</option>
                        <option key="sandy" value="sandy">sandy</option>
                        <option key="loamy" value="loamy">loamy</option>
                        <option key="silt" value="silt">silt</option>
                        <option key="rocky" value="rocky">rocky</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="mb-4">
                  <label className="inline-flex items-start">
                    <input
                      type="checkbox"
                      name="groundwaterAvailability"
                      checked={Boolean(getNestedValue(formData, 'groundwaterAvailability'))}
                      onChange={handleChange}
                      className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                    />
                    <span className="ml-2 text-sm text-gray-600">
          groundwaterAvailability
                    </span>
                  </label>
                </div>

                <div className="mb-6">
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
          seismicZone
                    </label>
                    <div className="relative group">
                      <select
                        name="seismicZone"
                        value={getNestedValue(formData, 'seismicZone') ?? ''}
                        onChange={handleChange}
                        className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
          
                      >
                        <option value="" className="text-gray-500">Select seismicZone</option>
                        <option key="zone1" value="zone1">zone1</option>
                        <option key="zone2" value="zone2">zone2</option>
                        <option key="zone3" value="zone3">zone3</option>
                        <option key="zone4" value="zone4">zone4</option>
                        <option key="zone5" value="zone5">zone5</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              media
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
          primaryImage
                  </label>
                  <div className="group relative">
                    <div className="flex justify-center items-center w-full" >
                      <div className="relative w-full">
                        <input
                          type="file"
                          name="primaryImage"
                          onChange={handleFileChange}
                          multiple
                          className="absolute inset-0 w-full h-full opacity-0 z-10 cursor-pointer"
                          pattern=".(jpg|jpeg|png|gif)$"
                        />
                        <div className="flex flex-col items-center w-full h-32 px-4 py-6 
                    bg-white text-gray-500 rounded-lg border-2 border-dashed border-gray-300 
                    transition-all duration-200 
                    hover:bg-gray-50 hover:border-blue-300
                    group-focus-within:border-blue-500 group-focus-within:ring-2 group-focus-within:ring-blue-500 group-focus-within:ring-offset-2">
                    
                          <div className="flex flex-col items-center pointer-events-none">
                            <svg className="w-8 h-8 mb-2 text-gray-400 group-hover:text-blue-500 transition-colors duration-200" 
                              fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                            </svg>
                      
                            <p className="text-sm text-gray-500 text-center group-hover:text-gray-600">
                              <span className="font-medium">Click to upload</span> or drag and drop
                            </p>
                      
                            <p className="text-xs text-gray-500 mt-1">
                        All file types accepted
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {(getNestedValue(formData, 'primaryImage') || []).length > 0 && (
                      <div className="space-y-2">
                        {(getNestedValue(formData, 'primaryImage') || []).map((fileName: string, index: number) => (
                          <div key={index} className="flex items-center justify-between p-3 bg-blue-50 rounded-lg">
                            <div className="flex items-center">
                              <svg className="w-5 h-5 text-blue-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                              </svg>
                              <span className="text-sm font-medium text-blue-700">
                                {fileName}
                              </span>
                            </div>
          
                            <button
                              type="button"
                              onClick={() => removeFile('primaryImage', index)}
                              className="p-1 text-blue-400 hover:text-blue-600 rounded-full hover:bg-blue-100 
              transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                              </svg>
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                    <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              boundaryPhotos
                    </h3>
                  </div>
                  <div className="space-y-4 px-6">
          
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700 mb-2">
          photo1
                      </label>
                      <div className="group relative">
                        <div className="flex justify-center items-center w-full" >
                          <div className="relative w-full">
                            <input
                              type="file"
                              name="photo1"
                              onChange={handleFileChange}
                              multiple
                              className="absolute inset-0 w-full h-full opacity-0 z-10 cursor-pointer"
                              pattern=".(jpg|jpeg|png|gif)$"
                            />
                            <div className="flex flex-col items-center w-full h-32 px-4 py-6 
                    bg-white text-gray-500 rounded-lg border-2 border-dashed border-gray-300 
                    transition-all duration-200 
                    hover:bg-gray-50 hover:border-blue-300
                    group-focus-within:border-blue-500 group-focus-within:ring-2 group-focus-within:ring-blue-500 group-focus-within:ring-offset-2">
                    
                              <div className="flex flex-col items-center pointer-events-none">
                                <svg className="w-8 h-8 mb-2 text-gray-400 group-hover:text-blue-500 transition-colors duration-200" 
                                  fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                                </svg>
                      
                                <p className="text-sm text-gray-500 text-center group-hover:text-gray-600">
                                  <span className="font-medium">Click to upload</span> or drag and drop
                                </p>
                      
                                <p className="text-xs text-gray-500 mt-1">
                        All file types accepted
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {(getNestedValue(formData, 'photo1') || []).length > 0 && (
                          <div className="space-y-2">
                            {(getNestedValue(formData, 'photo1') || []).map((fileName: string, index: number) => (
                              <div key={index} className="flex items-center justify-between p-3 bg-blue-50 rounded-lg">
                                <div className="flex items-center">
                                  <svg className="w-5 h-5 text-blue-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                      d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                  </svg>
                                  <span className="text-sm font-medium text-blue-700">
                                    {fileName}
                                  </span>
                                </div>
          
                                <button
                                  type="button"
                                  onClick={() => removeFile('photo1', index)}
                                  className="p-1 text-blue-400 hover:text-blue-600 rounded-full hover:bg-blue-100 
              transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                >
                                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                  </svg>
                                </button>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700 mb-2">
          photo2
                      </label>
                      <div className="group relative">
                        <div className="flex justify-center items-center w-full" >
                          <div className="relative w-full">
                            <input
                              type="file"
                              name="photo2"
                              onChange={handleFileChange}
                              multiple
                              className="absolute inset-0 w-full h-full opacity-0 z-10 cursor-pointer"
                              pattern=".(jpg|jpeg|png|gif)$"
                            />
                            <div className="flex flex-col items-center w-full h-32 px-4 py-6 
                    bg-white text-gray-500 rounded-lg border-2 border-dashed border-gray-300 
                    transition-all duration-200 
                    hover:bg-gray-50 hover:border-blue-300
                    group-focus-within:border-blue-500 group-focus-within:ring-2 group-focus-within:ring-blue-500 group-focus-within:ring-offset-2">
                    
                              <div className="flex flex-col items-center pointer-events-none">
                                <svg className="w-8 h-8 mb-2 text-gray-400 group-hover:text-blue-500 transition-colors duration-200" 
                                  fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                                </svg>
                      
                                <p className="text-sm text-gray-500 text-center group-hover:text-gray-600">
                                  <span className="font-medium">Click to upload</span> or drag and drop
                                </p>
                      
                                <p className="text-xs text-gray-500 mt-1">
                        All file types accepted
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {(getNestedValue(formData, 'photo2') || []).length > 0 && (
                          <div className="space-y-2">
                            {(getNestedValue(formData, 'photo2') || []).map((fileName: string, index: number) => (
                              <div key={index} className="flex items-center justify-between p-3 bg-blue-50 rounded-lg">
                                <div className="flex items-center">
                                  <svg className="w-5 h-5 text-blue-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                      d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                  </svg>
                                  <span className="text-sm font-medium text-blue-700">
                                    {fileName}
                                  </span>
                                </div>
          
                                <button
                                  type="button"
                                  onClick={() => removeFile('photo2', index)}
                                  className="p-1 text-blue-400 hover:text-blue-600 rounded-full hover:bg-blue-100 
              transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                >
                                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                  </svg>
                                </button>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          googleMapsLink
          
                    </label>
        
                    <div className="mt-1 relative group">
                      <button type="button" 
                        className="flex items-center justify-center w-5 h-5 text-blue-500 bg-blue-50 rounded-full 
            transition-all duration-200 hover:bg-blue-100">
                        <span className="text-xs font-medium">i</span>
                      </button>
                      <div className="absolute right-0 bottom-full mb-2 w-72 sm:w-50 p-4 bg-white border 
          rounded-lg shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible 
          transition-all duration-200 z-10" />
                    </div>
  
                  </div>
      
                  <div className="relative">
                    <input
                      type="url"
                      name="googleMapsLink"
                      value={getNestedValue(formData, 'googleMapsLink') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter googlemapslink..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
    pr-10 
    ${!new RegExp('^https://maps.google.com/.*$').test(getNestedValue(formData, 'googleMapsLink') || '') && getNestedValue(formData, 'googleMapsLink') 
            ? 'border-red-300 focus:border-red-500 focus:ring-red-500' 
            : ''}
  `}
                      pattern="^https://maps.google.com/.*$"
                    />
        
        
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      {!new RegExp('^https://maps.google.com/.*$').test(getNestedValue(formData, 'googleMapsLink') || '') && 
             getNestedValue(formData, 'googleMapsLink') && (
                        <svg className="w-5 h-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      )}
                    </div>
        
                  </div>
      
      
                  {getNestedValue(formData, 'googleMapsLink') && !new RegExp('^https://maps.google.com/.*$').test(getNestedValue(formData, 'googleMapsLink')) && (
                    <p className="mt-1 text-sm text-red-600">
        undefined
                    </p>
                  )}
  
      
      
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
          droneVideo
                  </label>
                  <div className="group relative">
                    <div className="flex justify-center items-center w-full" >
                      <div className="relative w-full">
                        <input
                          type="file"
                          name="droneVideo"
                          onChange={handleFileChange}
                          multiple
                          className="absolute inset-0 w-full h-full opacity-0 z-10 cursor-pointer"
                          pattern=".(mp4|mov|avi)$"
                        />
                        <div className="flex flex-col items-center w-full h-32 px-4 py-6 
                    bg-white text-gray-500 rounded-lg border-2 border-dashed border-gray-300 
                    transition-all duration-200 
                    hover:bg-gray-50 hover:border-blue-300
                    group-focus-within:border-blue-500 group-focus-within:ring-2 group-focus-within:ring-blue-500 group-focus-within:ring-offset-2">
                    
                          <div className="flex flex-col items-center pointer-events-none">
                            <svg className="w-8 h-8 mb-2 text-gray-400 group-hover:text-blue-500 transition-colors duration-200" 
                              fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                            </svg>
                      
                            <p className="text-sm text-gray-500 text-center group-hover:text-gray-600">
                              <span className="font-medium">Click to upload</span> or drag and drop
                            </p>
                      
                            <p className="text-xs text-gray-500 mt-1">
                        All file types accepted
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {(getNestedValue(formData, 'droneVideo') || []).length > 0 && (
                      <div className="space-y-2">
                        {(getNestedValue(formData, 'droneVideo') || []).map((fileName: string, index: number) => (
                          <div key={index} className="flex items-center justify-between p-3 bg-blue-50 rounded-lg">
                            <div className="flex items-center">
                              <svg className="w-5 h-5 text-blue-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                              </svg>
                              <span className="text-sm font-medium text-blue-700">
                                {fileName}
                              </span>
                            </div>
          
                            <button
                              type="button"
                              onClick={() => removeFile('droneVideo', index)}
                              className="p-1 text-blue-400 hover:text-blue-600 rounded-full hover:bg-blue-100 
              transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                              </svg>
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              security
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          accessRestrictions
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="textarea"
                      name="accessRestrictions"
                      value={getNestedValue(formData, 'accessRestrictions') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter accessrestrictions..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
          
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          securityArrangements
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="textarea"
                      name="securityArrangements"
                      value={getNestedValue(formData, 'securityArrangements') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter securityarrangements..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
          
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Distance in kilometers
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="policeStation"
                      value={getNestedValue(formData, 'policeStation') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter distance in kilometers..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="0"
                    />
        
        
                  </div>
      
      
      
      
                </div>
              </div>
            </div>
          </div>
        )}

        {currentPage === 3 && (
          <div key="3" className="space-y-4">
                    
            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              listingDetails
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          listingId
          
                    </label>
        
                    <div className="mt-1 relative group">
                      <button type="button" 
                        className="flex items-center justify-center w-5 h-5 text-blue-500 bg-blue-50 rounded-full 
            transition-all duration-200 hover:bg-blue-100">
                        <span className="text-xs font-medium">i</span>
                      </button>
                      <div className="absolute right-0 bottom-full mb-2 w-72 sm:w-50 p-4 bg-white border 
          rounded-lg shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible 
          transition-all duration-200 z-10" />
                    </div>
  
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="listingId"
                      value={getNestedValue(formData, 'listingId') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter listingid..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
    pr-10 
    ${!new RegExp('^LIST[0-9]{8}$').test(getNestedValue(formData, 'listingId') || '') && getNestedValue(formData, 'listingId') 
            ? 'border-red-300 focus:border-red-500 focus:ring-red-500' 
            : ''}
  `}
                      pattern="^LIST[0-9]{8}$"
                    />
        
        
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      {!new RegExp('^LIST[0-9]{8}$').test(getNestedValue(formData, 'listingId') || '') && 
             getNestedValue(formData, 'listingId') && (
                        <svg className="w-5 h-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      )}
                    </div>
        
                  </div>
      
      
                  {getNestedValue(formData, 'listingId') && !new RegExp('^LIST[0-9]{8}$').test(getNestedValue(formData, 'listingId')) && (
                    <p className="mt-1 text-sm text-red-600">
        undefined
                    </p>
                  )}
  
      
      
                </div>

                <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                    <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              agent
                    </h3>
                  </div>
                  <div className="space-y-4 px-6">
          
                    <div className="mb-6">
                      <div className="flex justify-between items-center mb-2">
                        <label className="block text-sm font-medium text-gray-700">
          name
          
                        </label>
        
                        <div className="mt-1 relative group">
                          <button type="button" 
                            className="flex items-center justify-center w-5 h-5 text-blue-500 bg-blue-50 rounded-full 
            transition-all duration-200 hover:bg-blue-100">
                            <span className="text-xs font-medium">i</span>
                          </button>
                          <div className="absolute right-0 bottom-full mb-2 w-72 sm:w-50 p-4 bg-white border 
          rounded-lg shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible 
          transition-all duration-200 z-10" />
                        </div>
  
                      </div>
      
                      <div className="relative">
                        <input
                          type="text"
                          name="name"
                          value={getNestedValue(formData, 'name') ?? ''}
                          onChange={handleChange}
                          placeholder="Enter name..."
                          className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
    pr-10 
    ${!new RegExp('^[a-zA-Zs]{2,50}$').test(getNestedValue(formData, 'name') || '') && getNestedValue(formData, 'name') 
            ? 'border-red-300 focus:border-red-500 focus:ring-red-500' 
            : ''}
  `}
                          pattern="^[a-zA-Zs]{2,50}$"
                        />
        
        
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                          {!new RegExp('^[a-zA-Zs]{2,50}$').test(getNestedValue(formData, 'name') || '') && 
             getNestedValue(formData, 'name') && (
                            <svg className="w-5 h-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                          )}
                        </div>
        
                      </div>
      
      
                      {getNestedValue(formData, 'name') && !new RegExp('^[a-zA-Zs]{2,50}$').test(getNestedValue(formData, 'name')) && (
                        <p className="mt-1 text-sm text-red-600">
        undefined
                        </p>
                      )}
  
      
      
                    </div>

                    <div className="mb-6">
                      <div className="flex justify-between items-center mb-2">
                        <label className="block text-sm font-medium text-gray-700">
          contactNumber
          
                        </label>
        
                        <div className="mt-1 relative group">
                          <button type="button" 
                            className="flex items-center justify-center w-5 h-5 text-blue-500 bg-blue-50 rounded-full 
            transition-all duration-200 hover:bg-blue-100">
                            <span className="text-xs font-medium">i</span>
                          </button>
                          <div className="absolute right-0 bottom-full mb-2 w-72 sm:w-50 p-4 bg-white border 
          rounded-lg shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible 
          transition-all duration-200 z-10" />
                        </div>
  
                      </div>
      
                      <div className="relative">
                        <input
                          type="tel"
                          name="contactNumber"
                          value={getNestedValue(formData, 'contactNumber') ?? ''}
                          onChange={handleChange}
                          placeholder="Enter contactnumber..."
                          className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
    pr-10 
    ${!new RegExp('^[0-9]{10}$').test(getNestedValue(formData, 'contactNumber') || '') && getNestedValue(formData, 'contactNumber') 
            ? 'border-red-300 focus:border-red-500 focus:ring-red-500' 
            : ''}
  `}
                          pattern="^[0-9]{10}$"
                        />
        
        
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                          {!new RegExp('^[0-9]{10}$').test(getNestedValue(formData, 'contactNumber') || '') && 
             getNestedValue(formData, 'contactNumber') && (
                            <svg className="w-5 h-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                          )}
                        </div>
        
                      </div>
      
      
                      {getNestedValue(formData, 'contactNumber') && !new RegExp('^[0-9]{10}$').test(getNestedValue(formData, 'contactNumber')) && (
                        <p className="mt-1 text-sm text-red-600">
        undefined
                        </p>
                      )}
  
      
      
                    </div>

                    <div className="mb-6">
                      <div className="flex justify-between items-center mb-2">
                        <label className="block text-sm font-medium text-gray-700">
          email
          
                        </label>
        
                        <div className="mt-1 relative group">
                          <button type="button" 
                            className="flex items-center justify-center w-5 h-5 text-blue-500 bg-blue-50 rounded-full 
            transition-all duration-200 hover:bg-blue-100">
                            <span className="text-xs font-medium">i</span>
                          </button>
                          <div className="absolute right-0 bottom-full mb-2 w-72 sm:w-50 p-4 bg-white border 
          rounded-lg shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible 
          transition-all duration-200 z-10" />
                        </div>
  
                      </div>
      
                      <div className="relative">
                        <input
                          type="email"
                          name="email"
                          value={getNestedValue(formData, 'email') ?? ''}
                          onChange={handleChange}
                          placeholder="Enter email..."
                          className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
    pr-10 
    ${!new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$').test(getNestedValue(formData, 'email') || '') && getNestedValue(formData, 'email') 
            ? 'border-red-300 focus:border-red-500 focus:ring-red-500' 
            : ''}
  `}
                          pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$"
                        />
        
        
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                          {!new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$').test(getNestedValue(formData, 'email') || '') && 
             getNestedValue(formData, 'email') && (
                            <svg className="w-5 h-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                          )}
                        </div>
        
                      </div>
      
      
                      {getNestedValue(formData, 'email') && !new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$').test(getNestedValue(formData, 'email')) && (
                        <p className="mt-1 text-sm text-red-600">
        undefined
                        </p>
                      )}
  
      
      
                    </div>
                  </div>
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          listingDate
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="date"
                      name="listingDate"
                      value={getNestedValue(formData, 'listingDate') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter listingdate..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
          
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          expiryDate
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="date"
                      name="expiryDate"
                      value={getNestedValue(formData, 'expiryDate') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter expirydate..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
          
                    />
        
        
                  </div>
      
      
      
      
                </div>
              </div>
            </div>

            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              engagement
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          buyerInterestCount
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="buyerInterestCount"
                      value={getNestedValue(formData, 'buyerInterestCount') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter buyerinterestcount..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="0"
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          siteVisitsCount
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="siteVisitsCount"
                      value={getNestedValue(formData, 'siteVisitsCount') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter sitevisitscount..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="0"
                    />
        
        
                  </div>
      
      
      
      
                </div>
              </div>
            </div>
          </div>
        )}
        {renderPaginationControls()}
      </form>
    </div>
  );
};

export default LandAndPlotsFormForm;