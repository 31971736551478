import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronRight, ChevronLeft, Search } from 'lucide-react';
import LanguageContext from '@/components/language/language_context.tsx';
import { fetchCategoriesForTypeAndSectionName } from '@infrastructure/api/categories.ts';

interface Category {
  id: string;
  code: string;
  translatedName: string;
  childCategories?: Category[];
}

const HeaderBelow: React.FC = () => {
  const languageContext = useContext(LanguageContext);
  const selectedLanguage = languageContext?.selectedLanguage || '';
  const [categories, setCategories] = useState<Category[]>([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [currentCategories, setCurrentCategories] = useState<Category[]>([]);
  const [categoryStack, setCategoryStack] = useState<Category[][]>([]);
  const [translations] = useState({
    all: 'All',
    back: 'Back',
    search: 'Search',
  });
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const getCategories = async () => {
      try {
        const responseData = await fetchCategoriesForTypeAndSectionName(
          selectedLanguage,
          'WEBUI',
          'categories_for_navigation',
        );
        console.log('responseData=', JSON.stringify(responseData));
        const categories: Category[] = responseData.data.map((item: any) => ({
          id: item.id,
          code: item.code,
          translatedName: item.translatedName,
          childCategories: item.childCategories || [], // Map child categories if present
        }));
        setCategories(categories);
        setCurrentCategories(categories);
        setCategoryStack([]);
        setSearchQuery('');
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An error occurred');
      }
    };

    getCategories().catch(console.error);
    setIsMenuOpen(false);
  }, [selectedLanguage]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    if (!isMenuOpen) {
      setCurrentCategories(categories);
      setCategoryStack([]);
    }
  };

  const handleCategoryClick = (category: Category) => {
    if (category.childCategories && category.childCategories.length > 0) {
      setCategoryStack([...categoryStack, currentCategories]);
      setCurrentCategories(category.childCategories);
    } else {
      navigate(`/${category.id}`);
      setIsMenuOpen(false);
    }
  };

  const handleBack = () => {
    if (categoryStack.length > 0) {
      const previousCategories = categoryStack[categoryStack.length - 1];
      const newStack = categoryStack.slice(0, -1);
      setCurrentCategories(previousCategories);
      setCategoryStack(newStack);
    }
  };

  const filteredCategories: Category[] = searchQuery
    ? currentCategories.filter((category) =>
        category.translatedName.toLowerCase().includes(searchQuery.toLowerCase()),
      )
    : currentCategories;

  if (error && !isMenuOpen) {
    return <div style={{ color: 'red' }}>Error: {error}</div>;
  }

  return (
    <div className="w-full bg-gray-900 text-white">
      <div className="max-w-[1500px] mx-auto">
        <div className="flex items-center space-x-4 px-4 h-[39px] text-sm">
          <button
            onClick={toggleMenu}
            className="flex items-center hover:border border-white px-2 py-1 rounded-sm"
          >
            <span className="font-bold">All</span>
          </button>
          <div className="flex-1 flex items-center space-x-4 overflow-x-auto scrollbar-hide">
            {categories.slice(0, 15).map((category) => (
              <button
                key={category.id}
                onClick={() => handleCategoryClick(category)}
                className="text-sm whitespace-nowrap hover:border border-white hover:text-white/80 transition-colors"
              >
                {category.translatedName}
              </button>
            ))}
          </div>
        </div>
      </div>
      {isMenuOpen && (
        <div className="fixed inset-0 bg-opacity-50 z-50">
          <div className="absolute left-0 top-0 h-full w-[365px] bg-white text-black overflow-y-auto">
            <div className="flex items-center justify-between bg-[#232f3e] text-white p-4 sticky-top">
              <div className="flex items-center space-x-2">
                <span className="text-lg font-bold">Browse Categories</span>
              </div>
              <button onClick={toggleMenu} className="h-4 w-4">
                X
              </button>
            </div>
            <div className="p-4 border-b">
              <div className="relative">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder={translations.search}
                  className="w-full pl-10 pr-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#232f3e]"
                />
                <Search className="h-5 w-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              </div>
            </div>
            {categoryStack.length > 0 && (
              <button
                onClick={handleBack}
                className="flex items-center space-x-2 p-2 hover:bg-gray-100 w-full"
              >
                <ChevronLeft className="h-5 w-5" />
                <span>Back</span>
              </button>
            )}
            <div className="divide-y">
              {error ? (
                <div className="p-4 text-red-500">{error}</div>
              ) : filteredCategories.length === 0 ? (
                <div className="p-4 text-gray-500">No categories found</div>
              ) : (
                filteredCategories.map((category) => (
                  <button
                    key={category.id}
                    onClick={() => handleCategoryClick(category)}
                    className="flex items-center justify-between w-full p-2 hover:bg-gray-100 transition-colors"
                  >
                    <span>{category.translatedName}</span>
                    {category.childCategories && category.childCategories.length > 0 && (
                      <ChevronRight className="h-5 w-5" />
                    )}
                  </button>
                ))
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderBelow;
