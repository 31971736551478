import React, { useState, useCallback , useEffect } from 'react';
import type { ResidentialProperty } from './residentialproperty';
import { useNavigate } from 'react-router-dom';

const STORAGE_KEY = 'formData';
const PAGE_STORAGE_KEY = 'currentFormPage';
const SCHEMA_METADATA = {
  'id': '9ed34c8d-ea90-4da3-8bad-eff5a5501e0a',
  'schemaFile': 'D:\\WebstormProjects\\schemas\\ad\\realestate\\residential\\residential_v_1_0_0.json',
};


const ResidentialPropertyForm: React.FC = () => {
  const [formData, setFormData] = useState<Partial<ResidentialProperty>>(() =>{
    try {
      const savedData = localStorage.getItem(STORAGE_KEY);
      return savedData ? JSON.parse(savedData) : {};
    } catch (error) {
      console.error('Error parsing saved form data:', error);
      return {};
    }
  });
  const [currentPage, setCurrentPage] = useState(0);
  const totalPages = 6;
  const navigate = useNavigate();
  
  // Save form data to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(formData));
  }, [formData]);

  // Save current page to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem(PAGE_STORAGE_KEY, currentPage.toString());
  }, [currentPage]);

  const setNestedValue = (obj: any, path: string, value: any) => {
    const pathParts = path.split('.');
    const sectionKey = pathParts[0]; // Get the section key (e.g., generalInformation)
    const fieldPath = pathParts.slice(1); // Get the rest of the path

    // If there's no section, treat it as a root-level field
    if (fieldPath.length === 0) {
      return { ...obj, [sectionKey]: value };
    }

    // Ensure the section exists
    const section = obj[sectionKey] || {};
  
    // If we only have one field in the path, set it directly
    if (fieldPath.length === 1) {
      return {
        ...obj,
        [sectionKey]: {
          ...section,
          [fieldPath[0]]: value,
        },
      };
    }

    // For nested fields within the section
    const current = { ...section };
    const lastKey = fieldPath.pop()!;
    let pointer = current;

    for (const key of fieldPath) {
      pointer[key] = pointer[key] || {};
      pointer = pointer[key];
    }

    pointer[lastKey] = value;

    return {
      ...obj,
      [sectionKey]: current,
    };
  };

  const getNestedValue = (obj: any, path: string) => {
    try {
      return path.split('.').reduce((acc, part) => acc?.[part], obj) ?? '';
    } catch {
      return '';
    }
  };
  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(0, prev - 1));
  };

  const handleNextPage = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setCurrentPage((prev) => Math.min(totalPages - 1, prev + 1));
  };
   
  const handleDraft = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    // Save the current form data as a draft
    localStorage.setItem(STORAGE_KEY, JSON.stringify(formData));
    localStorage.setItem(PAGE_STORAGE_KEY, currentPage.toString());
    console.log('Draft saved');
  };
   
  // Add a reset function to clear the form
  const resetForm = useCallback(() => {
    setFormData({});
    setCurrentPage(0);
    localStorage.removeItem(STORAGE_KEY);
    localStorage.removeItem(PAGE_STORAGE_KEY);
  }, []);
  

  const renderPaginationControls = () => {
    return (
      <div className="flex flex-col sm:flex-row justify-between items-center gap-4 px-4 py-6 bg-white rounded-lg shadow-sm">
        <button
          type="button"
          onClick={handlePrevPage}
          disabled={currentPage === 0}
          className="max-sm:w-15 max-md::w-30 lg:w-32 xl:w-32 px-5 py-3 bg-gradient-to-r from-blue-500 to-blue-600 text-white font-medium rounded-xl 
        transform transition-all duration-200 
        hover:from-blue-600 hover:to-blue-700 hover:shadow-md 
        active:scale-95
        disabled:from-gray-300 disabled:to-gray-400 disabled:cursor-not-allowed disabled:transform-none
        focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          <span className="flex items-center justify-center">
            <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          Previous
          </span>
        </button>
        <button
          type="button"
          onClick={handleDraft}
          disabled={currentPage === 0}
          className="max-sm:w-25 max-md::w-30 lg:w-36 xl:w-38 px-5 py-3 bg-white text-gray-600 font-medium rounded-xl hover:text-green-600"
        >
          <span className="flex items-center justify-center">
          Save as Draft
          </span>
        </button>
      
      
        {currentPage === totalPages - 1 ? (
          <button
            type="submit"
            className="max-sm:w-15 max-md::w-30 lg:w-32 xl:w-32 px-5 py-3 bg-gradient-to-r from-green-500 to-green-600 text-white font-medium rounded-xl 
          transform transition-all duration-200 
          hover:from-green-600 hover:to-green-700 hover:shadow-md 
          active:scale-95
          focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
          >
            <span className="flex items-center justify-center">
            Submit
              <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
            </span>
          </button>
        ) : (
          <button
            type="button"
            onClick={handleNextPage}
            className="max-sm:w-15 max-md::w-30 lg:w-32 xl:w-32 px-5 py-3 bg-gradient-to-r from-blue-500 to-blue-600 text-white font-medium rounded-xl 
          transform transition-all duration-200 
          hover:from-blue-600 hover:to-blue-700 hover:shadow-md 
          active:scale-95
          focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            <span className="flex items-center justify-center">
            Next
              <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </span>
          </button>
        )}
      </div>
    );
  };

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;

    setFormData((prev) => {
      let newValue: any;

      switch (type) {
        case 'checkbox':
          newValue = (e.target as HTMLInputElement).checked;
          break;
        case 'number':
        case 'range':
          newValue = value === '' ? null : Number(value);
          break;
        case 'radio':
          newValue = value;
          break;
        case 'file':
          return prev; // Files are handled by handleFileChange
        default:
          newValue = value;
      }

      return setNestedValue({ ...prev }, name, newValue);
    });
  }, []);

  const handleFileChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = e.target;
    if (files && files.length > 0) {
      setFormData((prev) => {
      // Get existing files array or initialize empty array
        const existingFiles = getNestedValue(prev, name) || [];
        // Add new files to existing files, avoiding duplicates
        const newFiles = Array.from(files).map((file) => file.name);
        const uniqueFiles = [...new Set([...existingFiles, ...newFiles])];
        return setNestedValue({ ...prev }, name, uniqueFiles);
      });
    }
  }, [formData, getNestedValue]);
  
  // Add a function to remove individual files
  const removeFile = useCallback((fieldName: string, fileIndex: number) => {
    setFormData((prev) => {
      const files = getNestedValue(prev, fieldName) || [];
      const newFiles = files.filter((_: any, index: number) => index !== fileIndex);
      return setNestedValue({ ...prev }, fieldName, newFiles);
    });
  }, []);



  const handleSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    // Process form data to ensure all fields are included
    const processFormData = (data: any) => {
      const processed: any = {};
      
      // Helper function to handle empty/undefined values
      const processValue = (value: any) => {
        if (Array.isArray(value)) {
          return value.length > 0 ? value : [];
        }
        if (value === '') {
          return null;
        }
        return value ?? null;
      };

      // Process each field, maintaining structure
      Object.entries(data).forEach(([key, value]) => {
        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
          processed[key] = processFormData(value); // Recursively process nested objects
        } else {
          processed[key] = processValue(value);
        }
      });

      return processed;
    };


    // Create the new payload structure
    const payload = {      
         
      primaryCategoryId: SCHEMA_METADATA.id,
      schemaFile: SCHEMA_METADATA.schemaFile,
      data: JSON.stringify(processFormData(formData)),
    };
    console.log('Payload being sent:', JSON.stringify(payload, null, 2));

    try {
      const response = await fetch('https://adzmonster.com/api/ads/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log('Success:', result);
      // Clear form data after successful submission
      resetForm();
    } catch (error) {
      console.error('Error:', error);
    }
  }, [formData, resetForm]);
  return (
    <div className="min-h-screen from-blue-50/50 to-white flex items-center justify-center p-4 sm:p-6 md:p-8 lg:p-9">
      <form onSubmit={handleSubmit} className="w-full max-w-4xl mx-auto bg-white backdrop-blur-lg bg-opacity-95 rounded-2xl shadow-xl border border-white border-opacity-20 p-4 sm:p-6 md:p-8 transition-all duration-300 hover:shadow-2xl">
        {/* Progress Bar */}
        <div className="mb-8">
          <div className="w-full bg-gray-100 rounded-full h-3 overflow-hidden shadow-inner">
            <div
              className="bg-gradient-to-r from-blue-500 to-purple-500 h-3 rounded-full transition-all duration-500 ease-in-out"
              style={{ width: `${((currentPage + 1) / totalPages) * 100}%` }}
            />
          </div>
          <div className="flex items-center justify-center px-4 py-2 bg-white rounded-lg">
            <span className="text-sm sm:text-base font-medium text-gray-700">
                  Step {currentPage + 1} of {totalPages}
            </span>
          </div>
        </div>
        {/* Action Buttons */}
        <div className="flex flex-col sm:flex-row justify-between items-center gap-4 mb-8">
          {/* Navigation Links */}
          <button
            type="button"
            onClick={() => navigate('/v1')}
            className="group flex items-center px-4 py-2 text-sm font-medium text-gray-700 hover:text-blue-600 transition-all duration-200"
          >
            <svg 
              className="w-4 h-4 mr-2 transform transition-transform group-hover:-translate-x-1" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
                    Back to Home
          </button>
        
          {/* Reset Button */}
          <button
            type="button"
            onClick={resetForm}
            className="flex items-center px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-red-500 to-red-600 rounded-xl 
                      transform transition-all duration-200 
                      hover:from-red-600 hover:to-red-700 hover:shadow-md 
                      active:scale-95
                      focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
          >
            <svg 
              className="w-4 h-4 mr-2" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
            </svg>
                      Reset Form
          </button>
        </div>
                
        {currentPage === 0 && (
          <div key="0" className="space-y-4">
                    
            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              generalInformation
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Project Name
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="projectName"
                      value={getNestedValue(formData, 'projectName') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter project name..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      minLength={3} required
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
          Project Type
                    </label>
                    <div className="relative group">
                      <select
                        name="projectType"
                        value={getNestedValue(formData, 'projectType') ?? ''}
                        onChange={handleChange}
                        className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
                        required
                      >
                        <option value="" className="text-gray-500">Select projectType</option>
                        <option key="Residential" value="Residential">Residential</option>
                        <option key="Mixed-use" value="Mixed-use">Mixed-use</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Project Description
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="textarea"
                      name="projectDescription"
                      value={getNestedValue(formData, 'projectDescription') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter project description..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
          
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
          Project Status
                    </label>
                    <div className="relative group">
                      <select
                        name="projectStatus"
                        value={getNestedValue(formData, 'projectStatus') ?? ''}
                        onChange={handleChange}
                        className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
                        required
                      >
                        <option value="" className="text-gray-500">Select projectStatus</option>
                        <option key="Under Construction" value="Under Construction">Under Construction</option>
                        <option key="Ready to Move" value="Ready to Move">Ready to Move</option>
                        <option key="Upcoming" value="Upcoming">Upcoming</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Project Launch Date
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="date"
                      name="launchDate"
                      value={getNestedValue(formData, 'launchDate') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter project launch date..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
          
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Expected Completion Date
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="date"
                      name="completionDate"
                      value={getNestedValue(formData, 'completionDate') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter expected completion date..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
          
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Developer/Builder Name
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="developerName"
                      value={getNestedValue(formData, 'developerName') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter developer/builder name..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      required
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          RERA Registration Number
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="reraNumber"
                      value={getNestedValue(formData, 'reraNumber') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter rera registration number..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
    pr-10 
    ${!new RegExp('^[A-Z0-9-]+$').test(getNestedValue(formData, 'reraNumber') || '') && getNestedValue(formData, 'reraNumber') 
            ? 'border-red-300 focus:border-red-500 focus:ring-red-500' 
            : ''}
  `}
                      pattern="^[A-Z0-9-]+$" required
                    />
        
        
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      {!new RegExp('^[A-Z0-9-]+$').test(getNestedValue(formData, 'reraNumber') || '') && 
             getNestedValue(formData, 'reraNumber') && (
                        <svg className="w-5 h-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      )}
                    </div>
        
                  </div>
      
      
                  {getNestedValue(formData, 'reraNumber') && !new RegExp('^[A-Z0-9-]+$').test(getNestedValue(formData, 'reraNumber')) && (
                    <p className="mt-1 text-sm text-red-600">
        Please enter a valid RERA number
                    </p>
                  )}
  
      
      
                </div>
              </div>
            </div>

            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              location
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Complete Address
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="textarea"
                      name="address"
                      value={getNestedValue(formData, 'address') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter complete address..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      required
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          City
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="city"
                      value={getNestedValue(formData, 'city') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter city..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      required
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          State
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="state"
                      value={getNestedValue(formData, 'state') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter state..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      required
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          PIN/ZIP Code
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                    <div className="mt-1 relative group">
                      <button type="button" 
                        className="flex items-center justify-center w-5 h-5 text-blue-500 bg-blue-50 rounded-full 
            transition-all duration-200 hover:bg-blue-100">
                        <span className="text-xs font-medium">i</span>
                      </button>
                      <div className="absolute right-0 bottom-full mb-2 w-72 sm:w-50 p-4 bg-white border 
          rounded-lg shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible 
          transition-all duration-200 z-10">
                        <p className="text-sm text-gray-600 leading-relaxed">5 or 6 digits only</p>
      
                        <div className="mt-1">
                          <span className="block text-xs font-medium text-gray-500 mb-2">Examples:</span>
          
                          <span className="ml-2 text-sm text-blue-600">400001</span>
          
                          <span className="ml-2 text-sm text-blue-600">110001</span>
          
                        </div>
      
                      </div>
                    </div>
  
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="pinCode"
                      value={getNestedValue(formData, 'pinCode') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter pin/zip code..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
    pr-10 
    ${!new RegExp('^[0-9]{5,6}$').test(getNestedValue(formData, 'pinCode') || '') && getNestedValue(formData, 'pinCode') 
            ? 'border-red-300 focus:border-red-500 focus:ring-red-500' 
            : ''}
  `}
                      pattern="^[0-9]{5,6}$" required
                    />
        
        
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      {!new RegExp('^[0-9]{5,6}$').test(getNestedValue(formData, 'pinCode') || '') && 
             getNestedValue(formData, 'pinCode') && (
                        <svg className="w-5 h-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      )}
                    </div>
        
                  </div>
      
      
                  {getNestedValue(formData, 'pinCode') && !new RegExp('^[0-9]{5,6}$').test(getNestedValue(formData, 'pinCode')) && (
                    <p className="mt-1 text-sm text-red-600">
        Please enter a valid 5 or 6-digit PIN code
                    </p>
                  )}
  
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Google Maps Link
          
                    </label>
        
                    <div className="mt-1 relative group">
                      <button type="button" 
                        className="flex items-center justify-center w-5 h-5 text-blue-500 bg-blue-50 rounded-full 
            transition-all duration-200 hover:bg-blue-100">
                        <span className="text-xs font-medium">i</span>
                      </button>
                      <div className="absolute right-0 bottom-full mb-2 w-72 sm:w-50 p-4 bg-white border 
          rounded-lg shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible 
          transition-all duration-200 z-10">
                        <p className="text-sm text-gray-600 leading-relaxed">Valid Google Maps URL (maps.google.com or goo.gl)</p>
      
                        <div className="mt-1">
                          <span className="block text-xs font-medium text-gray-500 mb-2">Examples:</span>
          
                          <span className="ml-2 text-sm text-blue-600">https://maps.google.com/...</span>
          
                        </div>
      
                      </div>
                    </div>
  
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="googleMapsLink"
                      value={getNestedValue(formData, 'googleMapsLink') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter google maps link..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
    pr-10 
    ${!new RegExp('^https://.*maps.google.com.*$').test(getNestedValue(formData, 'googleMapsLink') || '') && getNestedValue(formData, 'googleMapsLink') 
            ? 'border-red-300 focus:border-red-500 focus:ring-red-500' 
            : ''}
  `}
                      pattern="^https://.*maps.google.com.*$"
                    />
        
        
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      {!new RegExp('^https://.*maps.google.com.*$').test(getNestedValue(formData, 'googleMapsLink') || '') && 
             getNestedValue(formData, 'googleMapsLink') && (
                        <svg className="w-5 h-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      )}
                    </div>
        
                  </div>
      
      
                  {getNestedValue(formData, 'googleMapsLink') && !new RegExp('^https://.*maps.google.com.*$').test(getNestedValue(formData, 'googleMapsLink')) && (
                    <p className="mt-1 text-sm text-red-600">
        Please enter a valid Google Maps URL
                    </p>
                  )}
  
      
      
                </div>

                <div className="mb-6">
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
          Zone Classification
                    </label>
                    <div className="relative group">
                      <select
                        name="zoneClassification"
                        value={getNestedValue(formData, 'zoneClassification') ?? ''}
                        onChange={handleChange}
                        className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
                        required
                      >
                        <option value="" className="text-gray-500">Select zoneClassification</option>
                        <option key="Residential" value="Residential">Residential</option>
                        <option key="Mixed" value="Mixed">Mixed</option>
                        <option key="Commercial" value="Commercial">Commercial</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Nearby Landmarks
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="textarea"
                      name="landmarks"
                      value={getNestedValue(formData, 'landmarks') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter nearby landmarks..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
          
                    />
        
        
                  </div>
      
      
      
      
                </div>
              </div>
            </div>

            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              proximityDetails
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Distance to Nearest School (km)
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="schools"
                      value={getNestedValue(formData, 'schools') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter distance to nearest school (km)..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="0"
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Distance to Nearest Hospital (km)
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="hospitals"
                      value={getNestedValue(formData, 'hospitals') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter distance to nearest hospital (km)..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="0"
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Distance to Public Transport (km)
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="publicTransport"
                      value={getNestedValue(formData, 'publicTransport') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter distance to public transport (km)..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="0"
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Distance to Shopping Centers (km)
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="shoppingCenters"
                      value={getNestedValue(formData, 'shoppingCenters') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter distance to shopping centers (km)..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="0"
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Distance to Parks (km)
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="parks"
                      value={getNestedValue(formData, 'parks') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter distance to parks (km)..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="0"
                    />
        
        
                  </div>
      
      
      
      
                </div>
              </div>
            </div>
          </div>
        )}

        {currentPage === 1 && (
          <div key="1" className="space-y-4">
                    
            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              utilities
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Water Supply Source
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="waterSupply"
                      value={getNestedValue(formData, 'waterSupply') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter water supply source..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
          
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Electricity Provider
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="electricityProvider"
                      value={getNestedValue(formData, 'electricityProvider') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter electricity provider..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
          
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Available Internet Service Providers
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="internetProviders"
                      value={getNestedValue(formData, 'internetProviders') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter available internet service providers..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
          
                    />
        
        
                  </div>
      
      
      
      
                </div>
              </div>
            </div>

            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              propertyDetails
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Total Area (sq ft)
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="totalArea"
                      value={getNestedValue(formData, 'totalArea') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter total area (sq ft)..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="0" required
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Number of Bathrooms
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="bedrooms"
                      value={getNestedValue(formData, 'bedrooms') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter number of bathrooms..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="1"
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Number of Bathrooms
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="bathrooms"
                      value={getNestedValue(formData, 'bathrooms') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter number of bathrooms..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="1"
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-4">
                  <label className="inline-flex items-start">
                    <input
                      type="checkbox"
                      name="pipedGas"
                      checked={Boolean(getNestedValue(formData, 'pipedGas'))}
                      onChange={handleChange}
                      className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                    />
                    <span className="ml-2 text-sm text-gray-600">
          Piped Gas Connection
                    </span>
                  </label>
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Plot Size (sq ft)
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="plotSize"
                      value={getNestedValue(formData, 'plotSize') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter plot size (sq ft)..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="0"
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Number of Available Units
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="availableUnits"
                      value={getNestedValue(formData, 'availableUnits') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter number of available units..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="0" required
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <fieldset>
                    <legend className="text-sm font-medium text-gray-700 mb-3">
            Available Unit Types
                    </legend>
                    <div className="grid grid-cols-2 sm:grid-cols-1 lg:grid-cols-3  gap-x-6 gap-y-2 items-center justify-center">
            
                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="unitTypes"
                          value="1BHK"
                          checked={Array.isArray(getNestedValue(formData, 'unitTypes')) && 
                    getNestedValue(formData, 'unitTypes').includes('1BHK')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'unitTypes')) 
                              ? getNestedValue(formData, 'unitTypes') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, '1BHK']
                              : currentValues.filter((val: string) => val !== '1BHK');
                            setFormData((prev) => setNestedValue({ ...prev }, 'unitTypes', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  1BHK
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="unitTypes"
                          value="2BHK"
                          checked={Array.isArray(getNestedValue(formData, 'unitTypes')) && 
                    getNestedValue(formData, 'unitTypes').includes('2BHK')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'unitTypes')) 
                              ? getNestedValue(formData, 'unitTypes') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, '2BHK']
                              : currentValues.filter((val: string) => val !== '2BHK');
                            setFormData((prev) => setNestedValue({ ...prev }, 'unitTypes', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  2BHK
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="unitTypes"
                          value="3BHK"
                          checked={Array.isArray(getNestedValue(formData, 'unitTypes')) && 
                    getNestedValue(formData, 'unitTypes').includes('3BHK')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'unitTypes')) 
                              ? getNestedValue(formData, 'unitTypes') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, '3BHK']
                              : currentValues.filter((val: string) => val !== '3BHK');
                            setFormData((prev) => setNestedValue({ ...prev }, 'unitTypes', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  3BHK
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="unitTypes"
                          value="4BHK"
                          checked={Array.isArray(getNestedValue(formData, 'unitTypes')) && 
                    getNestedValue(formData, 'unitTypes').includes('4BHK')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'unitTypes')) 
                              ? getNestedValue(formData, 'unitTypes') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, '4BHK']
                              : currentValues.filter((val: string) => val !== '4BHK');
                            setFormData((prev) => setNestedValue({ ...prev }, 'unitTypes', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  4BHK
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="unitTypes"
                          value="5BHK"
                          checked={Array.isArray(getNestedValue(formData, 'unitTypes')) && 
                    getNestedValue(formData, 'unitTypes').includes('5BHK')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'unitTypes')) 
                              ? getNestedValue(formData, 'unitTypes') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, '5BHK']
                              : currentValues.filter((val: string) => val !== '5BHK');
                            setFormData((prev) => setNestedValue({ ...prev }, 'unitTypes', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  5BHK
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="unitTypes"
                          value="Penthouse"
                          checked={Array.isArray(getNestedValue(formData, 'unitTypes')) && 
                    getNestedValue(formData, 'unitTypes').includes('Penthouse')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'unitTypes')) 
                              ? getNestedValue(formData, 'unitTypes') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Penthouse']
                              : currentValues.filter((val: string) => val !== 'Penthouse');
                            setFormData((prev) => setNestedValue({ ...prev }, 'unitTypes', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Penthouse
                        </span>
                      </label>
            
                    </div>
                  </fieldset>
                </div>

                <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                    <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              unitSizeRange
                    </h3>
                  </div>
                  <div className="space-y-4 px-6">
          
                    <div className="mb-6">
                      <div className="flex justify-between items-center mb-2">
                        <label className="block text-sm font-medium text-gray-700">
          Minimum Unit Size (sq ft)
          
                        </label>
        
                      </div>
      
                      <div className="relative">
                        <input
                          type="number"
                          name="minimum"
                          value={getNestedValue(formData, 'minimum') ?? ''}
                          onChange={handleChange}
                          placeholder="Enter minimum unit size (sq ft)..."
                          className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
          
                        />
        
        
                      </div>
      
      
      
      
                    </div>

                    <div className="mb-6">
                      <div className="flex justify-between items-center mb-2">
                        <label className="block text-sm font-medium text-gray-700">
          Maximum Unit Size (sq ft)
          
                        </label>
        
                      </div>
      
                      <div className="relative">
                        <input
                          type="number"
                          name="maximum"
                          value={getNestedValue(formData, 'maximum') ?? ''}
                          onChange={handleChange}
                          placeholder="Enter maximum unit size (sq ft)..."
                          className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
          
                        />
        
        
                      </div>
      
      
      
      
                    </div>
                  </div>
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Total Number of Floors
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="numberOfFloors"
                      value={getNestedValue(formData, 'numberOfFloors') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter total number of floors..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="1" required
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Number of Towers/Blocks
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="numberOfTowers"
                      value={getNestedValue(formData, 'numberOfTowers') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter number of towers/blocks..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="1" required
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Number of Elevators per Tower
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="numberOfElevators"
                      value={getNestedValue(formData, 'numberOfElevators') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter number of elevators per tower..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="1"
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <fieldset className="w-full">
                    <legend className="text-sm font-medium text-gray-700 mb-3">
            Interior Finish
                    </legend>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
            
                      <label className="group relative block">
                        <input
                          type="radio"
                          name="interiorFinish"
                          value="Furnished"
                          checked={getNestedValue(formData, 'interiorFinish') === 'Furnished'}
                          onChange={handleChange}
                          className="peer sr-only"
                    
                        />
                        <div className="relative rounded-xl p-3 border-2 cursor-pointer
                  bg-gradient-to-b from-white to-gray-50
                  border-gray-200 
                  peer-checked:border-blue-500 peer-checked:from-blue-50 peer-checked:to-blue-50
                  peer-focus:ring-2 peer-focus:ring-blue-500 peer-focus:ring-offset-2
                  group-hover:border-blue-200 group-hover:shadow-sm
                  transition-all duration-200">
                          <div className="flex items-center justify-between">
                            <span className="block text-sm font-medium text-gray-900">
                      Furnished
                            </span>
                          </div>
                        </div>
                      </label>
              

                      <label className="group relative block">
                        <input
                          type="radio"
                          name="interiorFinish"
                          value="Semi-Furnished"
                          checked={getNestedValue(formData, 'interiorFinish') === 'Semi-Furnished'}
                          onChange={handleChange}
                          className="peer sr-only"
                    
                        />
                        <div className="relative rounded-xl p-3 border-2 cursor-pointer
                  bg-gradient-to-b from-white to-gray-50
                  border-gray-200 
                  peer-checked:border-blue-500 peer-checked:from-blue-50 peer-checked:to-blue-50
                  peer-focus:ring-2 peer-focus:ring-blue-500 peer-focus:ring-offset-2
                  group-hover:border-blue-200 group-hover:shadow-sm
                  transition-all duration-200">
                          <div className="flex items-center justify-between">
                            <span className="block text-sm font-medium text-gray-900">
                      Semi-Furnished
                            </span>
                          </div>
                        </div>
                      </label>
              

                      <label className="group relative block">
                        <input
                          type="radio"
                          name="interiorFinish"
                          value="Unfurnished"
                          checked={getNestedValue(formData, 'interiorFinish') === 'Unfurnished'}
                          onChange={handleChange}
                          className="peer sr-only"
                    
                        />
                        <div className="relative rounded-xl p-3 border-2 cursor-pointer
                  bg-gradient-to-b from-white to-gray-50
                  border-gray-200 
                  peer-checked:border-blue-500 peer-checked:from-blue-50 peer-checked:to-blue-50
                  peer-focus:ring-2 peer-focus:ring-blue-500 peer-focus:ring-offset-2
                  group-hover:border-blue-200 group-hover:shadow-sm
                  transition-all duration-200">
                          <div className="flex items-center justify-between">
                            <span className="block text-sm font-medium text-gray-900">
                      Unfurnished
                            </span>
                          </div>
                        </div>
                      </label>
              
                    </div>
                  </fieldset>
                </div>

                <div className="mb-6">
                  <fieldset>
                    <legend className="text-sm font-medium text-gray-700 mb-3">
            Availability
                    </legend>
                    <div className="grid grid-cols-2 sm:grid-cols-1 lg:grid-cols-3  gap-x-6 gap-y-2 items-center justify-center">
            
                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="Availability"
                          value="Balcony/Patio Available"
                          checked={Array.isArray(getNestedValue(formData, 'Availability')) && 
                    getNestedValue(formData, 'Availability').includes('Balcony/Patio Available')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'Availability')) 
                              ? getNestedValue(formData, 'Availability') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Balcony/Patio Available']
                              : currentValues.filter((val: string) => val !== 'Balcony/Patio Available');
                            setFormData((prev) => setNestedValue({ ...prev }, 'Availability', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Balcony/Patio Available
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="Availability"
                          value="Modular Kitchen Available"
                          checked={Array.isArray(getNestedValue(formData, 'Availability')) && 
                    getNestedValue(formData, 'Availability').includes('Modular Kitchen Available')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'Availability')) 
                              ? getNestedValue(formData, 'Availability') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Modular Kitchen Available']
                              : currentValues.filter((val: string) => val !== 'Modular Kitchen Available');
                            setFormData((prev) => setNestedValue({ ...prev }, 'Availability', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Modular Kitchen Available
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="Availability"
                          value="Servant Quarters Available"
                          checked={Array.isArray(getNestedValue(formData, 'Availability')) && 
                    getNestedValue(formData, 'Availability').includes('Servant Quarters Available')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'Availability')) 
                              ? getNestedValue(formData, 'Availability') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Servant Quarters Available']
                              : currentValues.filter((val: string) => val !== 'Servant Quarters Available');
                            setFormData((prev) => setNestedValue({ ...prev }, 'Availability', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Servant Quarters Available
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="Availability"
                          value="Private Garden/Terrace"
                          checked={Array.isArray(getNestedValue(formData, 'Availability')) && 
                    getNestedValue(formData, 'Availability').includes('Private Garden/Terrace')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'Availability')) 
                              ? getNestedValue(formData, 'Availability') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Private Garden/Terrace']
                              : currentValues.filter((val: string) => val !== 'Private Garden/Terrace');
                            setFormData((prev) => setNestedValue({ ...prev }, 'Availability', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Private Garden/Terrace
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="Availability"
                          value="Smart Home Features"
                          checked={Array.isArray(getNestedValue(formData, 'Availability')) && 
                    getNestedValue(formData, 'Availability').includes('Smart Home Features')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'Availability')) 
                              ? getNestedValue(formData, 'Availability') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Smart Home Features']
                              : currentValues.filter((val: string) => val !== 'Smart Home Features');
                            setFormData((prev) => setNestedValue({ ...prev }, 'Availability', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Smart Home Features
                        </span>
                      </label>
            
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>

            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              constructionDetails
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Foundation Type
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="foundationType"
                      value={getNestedValue(formData, 'foundationType') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter foundation type..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
          
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
          Structure Type
                    </label>
                    <div className="relative group">
                      <select
                        name="structure"
                        value={getNestedValue(formData, 'structure') ?? ''}
                        onChange={handleChange}
                        className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
          
                      >
                        <option value="" className="text-gray-500">Select structure</option>
                        <option key="RCC" value="RCC">RCC</option>
                        <option key="Steel" value="Steel">Steel</option>
                        <option key="Load Bearing" value="Load Bearing">Load Bearing</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Total Building Height (meters)
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="buildingHeight"
                      value={getNestedValue(formData, 'buildingHeight') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter total building height (meters)..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="0"
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Floor to Floor Height (meters)
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="floorHeight"
                      value={getNestedValue(formData, 'floorHeight') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter floor to floor height (meters)..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="0"
                    />
        
        
                  </div>
      
      
      
      
                </div>
              </div>
            </div>
          </div>
        )}

        {currentPage === 2 && (
          <div key="2" className="space-y-4">
                    
            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              pricing
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Starting Price (₹)
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="startingPrice"
                      value={getNestedValue(formData, 'startingPrice') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter starting price (₹)..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="0" required
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Price Per Sq Ft (₹)
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="pricePerSqFt"
                      value={getNestedValue(formData, 'pricePerSqFt') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter price per sq ft (₹)..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="0" required
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Monthly Maintenance Charges (₹)
                      <span className="ml-1 text-red-500">*</span>
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="maintenanceCharges"
                      value={getNestedValue(formData, 'maintenanceCharges') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter monthly maintenance charges (₹)..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="0" required
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Booking Amount (₹)
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="bookingAmount"
                      value={getNestedValue(formData, 'bookingAmount') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter booking amount (₹)..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="0"
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-4">
                  <label className="inline-flex items-start">
                    <input
                      type="checkbox"
                      name="emiOptions"
                      checked={Boolean(getNestedValue(formData, 'emiOptions'))}
                      onChange={handleChange}
                      className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                    />
                    <span className="ml-2 text-sm text-gray-600">
          EMI Options Available
                    </span>
                  </label>
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Available Payment Plans
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="textarea"
                      name="paymentPlans"
                      value={getNestedValue(formData, 'paymentPlans') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter available payment plans..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
          
                    />
        
        
                  </div>
      
      
      
      
                </div>
              </div>
            </div>

            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              Amenities
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <fieldset>
                    <legend className="text-sm font-medium text-gray-700 mb-3">
            General Amenities
                    </legend>
                    <div className="grid grid-cols-2 sm:grid-cols-1 lg:grid-cols-3  gap-x-6 gap-y-2 items-center justify-center">
            
                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="General"
                          value="Parking Available"
                          checked={Array.isArray(getNestedValue(formData, 'General')) && 
                    getNestedValue(formData, 'General').includes('Parking Available')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'General')) 
                              ? getNestedValue(formData, 'General') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Parking Available']
                              : currentValues.filter((val: string) => val !== 'Parking Available');
                            setFormData((prev) => setNestedValue({ ...prev }, 'General', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Parking Available
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="General"
                          value="Swimming Pool"
                          checked={Array.isArray(getNestedValue(formData, 'General')) && 
                    getNestedValue(formData, 'General').includes('Swimming Pool')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'General')) 
                              ? getNestedValue(formData, 'General') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Swimming Pool']
                              : currentValues.filter((val: string) => val !== 'Swimming Pool');
                            setFormData((prev) => setNestedValue({ ...prev }, 'General', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Swimming Pool
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="General"
                          value="Gymnasium"
                          checked={Array.isArray(getNestedValue(formData, 'General')) && 
                    getNestedValue(formData, 'General').includes('Gymnasium')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'General')) 
                              ? getNestedValue(formData, 'General') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Gymnasium']
                              : currentValues.filter((val: string) => val !== 'Gymnasium');
                            setFormData((prev) => setNestedValue({ ...prev }, 'General', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Gymnasium
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="General"
                          value="Clubhouse"
                          checked={Array.isArray(getNestedValue(formData, 'General')) && 
                    getNestedValue(formData, 'General').includes('Clubhouse')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'General')) 
                              ? getNestedValue(formData, 'General') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Clubhouse']
                              : currentValues.filter((val: string) => val !== 'Clubhouse');
                            setFormData((prev) => setNestedValue({ ...prev }, 'General', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Clubhouse
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="General"
                          value="Children Play Area"
                          checked={Array.isArray(getNestedValue(formData, 'General')) && 
                    getNestedValue(formData, 'General').includes('Children Play Area')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'General')) 
                              ? getNestedValue(formData, 'General') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Children Play Area']
                              : currentValues.filter((val: string) => val !== 'Children Play Area');
                            setFormData((prev) => setNestedValue({ ...prev }, 'General', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Children Play Area
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="General"
                          value="24/7 Security"
                          checked={Array.isArray(getNestedValue(formData, 'General')) && 
                    getNestedValue(formData, 'General').includes('24/7 Security')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'General')) 
                              ? getNestedValue(formData, 'General') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, '24/7 Security']
                              : currentValues.filter((val: string) => val !== '24/7 Security');
                            setFormData((prev) => setNestedValue({ ...prev }, 'General', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  24/7 Security
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="General"
                          value="Power Backup"
                          checked={Array.isArray(getNestedValue(formData, 'General')) && 
                    getNestedValue(formData, 'General').includes('Power Backup')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'General')) 
                              ? getNestedValue(formData, 'General') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Power Backup']
                              : currentValues.filter((val: string) => val !== 'Power Backup');
                            setFormData((prev) => setNestedValue({ ...prev }, 'General', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Power Backup
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="General"
                          value="CCTV Surveillance"
                          checked={Array.isArray(getNestedValue(formData, 'General')) && 
                    getNestedValue(formData, 'General').includes('CCTV Surveillance')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'General')) 
                              ? getNestedValue(formData, 'General') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'CCTV Surveillance']
                              : currentValues.filter((val: string) => val !== 'CCTV Surveillance');
                            setFormData((prev) => setNestedValue({ ...prev }, 'General', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  CCTV Surveillance
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="General"
                          value="Landscaped Gardens"
                          checked={Array.isArray(getNestedValue(formData, 'General')) && 
                    getNestedValue(formData, 'General').includes('Landscaped Gardens')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'General')) 
                              ? getNestedValue(formData, 'General') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Landscaped Gardens']
                              : currentValues.filter((val: string) => val !== 'Landscaped Gardens');
                            setFormData((prev) => setNestedValue({ ...prev }, 'General', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Landscaped Gardens
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="General"
                          value="Library/Reading Room"
                          checked={Array.isArray(getNestedValue(formData, 'General')) && 
                    getNestedValue(formData, 'General').includes('Library/Reading Room')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'General')) 
                              ? getNestedValue(formData, 'General') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Library/Reading Room']
                              : currentValues.filter((val: string) => val !== 'Library/Reading Room');
                            setFormData((prev) => setNestedValue({ ...prev }, 'General', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Library/Reading Room
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="General"
                          value="Banquet Hall"
                          checked={Array.isArray(getNestedValue(formData, 'General')) && 
                    getNestedValue(formData, 'General').includes('Banquet Hall')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'General')) 
                              ? getNestedValue(formData, 'General') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Banquet Hall']
                              : currentValues.filter((val: string) => val !== 'Banquet Hall');
                            setFormData((prev) => setNestedValue({ ...prev }, 'General', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Banquet Hall
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="General"
                          value="Walking/Jogging Tracks"
                          checked={Array.isArray(getNestedValue(formData, 'General')) && 
                    getNestedValue(formData, 'General').includes('Walking/Jogging Tracks')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'General')) 
                              ? getNestedValue(formData, 'General') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Walking/Jogging Tracks']
                              : currentValues.filter((val: string) => val !== 'Walking/Jogging Tracks');
                            setFormData((prev) => setNestedValue({ ...prev }, 'General', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Walking/Jogging Tracks
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="General"
                          value="Pet-Friendly Areas"
                          checked={Array.isArray(getNestedValue(formData, 'General')) && 
                    getNestedValue(formData, 'General').includes('Pet-Friendly Areas')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'General')) 
                              ? getNestedValue(formData, 'General') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Pet-Friendly Areas']
                              : currentValues.filter((val: string) => val !== 'Pet-Friendly Areas');
                            setFormData((prev) => setNestedValue({ ...prev }, 'General', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Pet-Friendly Areas
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="General"
                          value="Rooftop Lounge"
                          checked={Array.isArray(getNestedValue(formData, 'General')) && 
                    getNestedValue(formData, 'General').includes('Rooftop Lounge')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'General')) 
                              ? getNestedValue(formData, 'General') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Rooftop Lounge']
                              : currentValues.filter((val: string) => val !== 'Rooftop Lounge');
                            setFormData((prev) => setNestedValue({ ...prev }, 'General', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Rooftop Lounge
                        </span>
                      </label>
            
                    </div>
                  </fieldset>
                </div>

                <div className="mb-6">
                  <fieldset>
                    <legend className="text-sm font-medium text-gray-700 mb-3">
            sports Amenities
                    </legend>
                    <div className="grid grid-cols-2 sm:grid-cols-1 lg:grid-cols-3  gap-x-6 gap-y-2 items-center justify-center">
            
                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="sports"
                          value="Tennis Court"
                          checked={Array.isArray(getNestedValue(formData, 'sports')) && 
                    getNestedValue(formData, 'sports').includes('Tennis Court')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'sports')) 
                              ? getNestedValue(formData, 'sports') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Tennis Court']
                              : currentValues.filter((val: string) => val !== 'Tennis Court');
                            setFormData((prev) => setNestedValue({ ...prev }, 'sports', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Tennis Court
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="sports"
                          value="Basketball Court"
                          checked={Array.isArray(getNestedValue(formData, 'sports')) && 
                    getNestedValue(formData, 'sports').includes('Basketball Court')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'sports')) 
                              ? getNestedValue(formData, 'sports') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Basketball Court']
                              : currentValues.filter((val: string) => val !== 'Basketball Court');
                            setFormData((prev) => setNestedValue({ ...prev }, 'sports', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Basketball Court
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="sports"
                          value="Dedicated Cycling Track"
                          checked={Array.isArray(getNestedValue(formData, 'sports')) && 
                    getNestedValue(formData, 'sports').includes('Dedicated Cycling Track')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'sports')) 
                              ? getNestedValue(formData, 'sports') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Dedicated Cycling Track']
                              : currentValues.filter((val: string) => val !== 'Dedicated Cycling Track');
                            setFormData((prev) => setNestedValue({ ...prev }, 'sports', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Dedicated Cycling Track
                        </span>
                      </label>
            
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>

            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              sustainability
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Green Building Rating (0-5)
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="range"
                      name="greenRating"
                      value={getNestedValue(formData, 'greenRating') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter green building rating (0-5)..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="0" max="5"
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <fieldset>
                    <legend className="text-sm font-medium text-gray-700 mb-3">
            Features
                    </legend>
                    <div className="grid grid-cols-2 sm:grid-cols-1 lg:grid-cols-3  gap-x-6 gap-y-2 items-center justify-center">
            
                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="Features"
                          value="Solar Panels Installed"
                          checked={Array.isArray(getNestedValue(formData, 'Features')) && 
                    getNestedValue(formData, 'Features').includes('Solar Panels Installed')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'Features')) 
                              ? getNestedValue(formData, 'Features') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Solar Panels Installed']
                              : currentValues.filter((val: string) => val !== 'Solar Panels Installed');
                            setFormData((prev) => setNestedValue({ ...prev }, 'Features', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Solar Panels Installed
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="Features"
                          value="Rainwater Harvesting System"
                          checked={Array.isArray(getNestedValue(formData, 'Features')) && 
                    getNestedValue(formData, 'Features').includes('Rainwater Harvesting System')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'Features')) 
                              ? getNestedValue(formData, 'Features') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Rainwater Harvesting System']
                              : currentValues.filter((val: string) => val !== 'Rainwater Harvesting System');
                            setFormData((prev) => setNestedValue({ ...prev }, 'Features', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Rainwater Harvesting System
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="Features"
                          value="Waste Management System"
                          checked={Array.isArray(getNestedValue(formData, 'Features')) && 
                    getNestedValue(formData, 'Features').includes('Waste Management System')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'Features')) 
                              ? getNestedValue(formData, 'Features') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Waste Management System']
                              : currentValues.filter((val: string) => val !== 'Waste Management System');
                            setFormData((prev) => setNestedValue({ ...prev }, 'Features', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Waste Management System
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="Features"
                          value="Water Recycling Facilities"
                          checked={Array.isArray(getNestedValue(formData, 'Features')) && 
                    getNestedValue(formData, 'Features').includes('Water Recycling Facilities')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'Features')) 
                              ? getNestedValue(formData, 'Features') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Water Recycling Facilities']
                              : currentValues.filter((val: string) => val !== 'Water Recycling Facilities');
                            setFormData((prev) => setNestedValue({ ...prev }, 'Features', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Water Recycling Facilities
                        </span>
                      </label>
            
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        )}

        {currentPage === 3 && (
          <div key="3" className="space-y-4">
                    
            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              legal
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <fieldset className="w-full">
                    <legend className="text-sm font-medium text-gray-700 mb-3">
            Ownership Type
                    </legend>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
            
                      <label className="group relative block">
                        <input
                          type="radio"
                          name="ownershipType"
                          value="Freehold"
                          checked={getNestedValue(formData, 'ownershipType') === 'Freehold'}
                          onChange={handleChange}
                          className="peer sr-only"
                    
                        />
                        <div className="relative rounded-xl p-3 border-2 cursor-pointer
                  bg-gradient-to-b from-white to-gray-50
                  border-gray-200 
                  peer-checked:border-blue-500 peer-checked:from-blue-50 peer-checked:to-blue-50
                  peer-focus:ring-2 peer-focus:ring-blue-500 peer-focus:ring-offset-2
                  group-hover:border-blue-200 group-hover:shadow-sm
                  transition-all duration-200">
                          <div className="flex items-center justify-between">
                            <span className="block text-sm font-medium text-gray-900">
                      Freehold
                            </span>
                          </div>
                        </div>
                      </label>
              

                      <label className="group relative block">
                        <input
                          type="radio"
                          name="ownershipType"
                          value="Leasehold"
                          checked={getNestedValue(formData, 'ownershipType') === 'Leasehold'}
                          onChange={handleChange}
                          className="peer sr-only"
                    
                        />
                        <div className="relative rounded-xl p-3 border-2 cursor-pointer
                  bg-gradient-to-b from-white to-gray-50
                  border-gray-200 
                  peer-checked:border-blue-500 peer-checked:from-blue-50 peer-checked:to-blue-50
                  peer-focus:ring-2 peer-focus:ring-blue-500 peer-focus:ring-offset-2
                  group-hover:border-blue-200 group-hover:shadow-sm
                  transition-all duration-200">
                          <div className="flex items-center justify-between">
                            <span className="block text-sm font-medium text-gray-900">
                      Leasehold
                            </span>
                          </div>
                        </div>
                      </label>
              

                      <label className="group relative block">
                        <input
                          type="radio"
                          name="ownershipType"
                          value="Cooperative"
                          checked={getNestedValue(formData, 'ownershipType') === 'Cooperative'}
                          onChange={handleChange}
                          className="peer sr-only"
                    
                        />
                        <div className="relative rounded-xl p-3 border-2 cursor-pointer
                  bg-gradient-to-b from-white to-gray-50
                  border-gray-200 
                  peer-checked:border-blue-500 peer-checked:from-blue-50 peer-checked:to-blue-50
                  peer-focus:ring-2 peer-focus:ring-blue-500 peer-focus:ring-offset-2
                  group-hover:border-blue-200 group-hover:shadow-sm
                  transition-all duration-200">
                          <div className="flex items-center justify-between">
                            <span className="block text-sm font-medium text-gray-900">
                      Cooperative
                            </span>
                          </div>
                        </div>
                      </label>
              

                      <label className="group relative block">
                        <input
                          type="radio"
                          name="ownershipType"
                          value="Power of Attorney"
                          checked={getNestedValue(formData, 'ownershipType') === 'Power of Attorney'}
                          onChange={handleChange}
                          className="peer sr-only"
                    
                        />
                        <div className="relative rounded-xl p-3 border-2 cursor-pointer
                  bg-gradient-to-b from-white to-gray-50
                  border-gray-200 
                  peer-checked:border-blue-500 peer-checked:from-blue-50 peer-checked:to-blue-50
                  peer-focus:ring-2 peer-focus:ring-blue-500 peer-focus:ring-offset-2
                  group-hover:border-blue-200 group-hover:shadow-sm
                  transition-all duration-200">
                          <div className="flex items-center justify-between">
                            <span className="block text-sm font-medium text-gray-900">
                      Power of Attorney
                            </span>
                          </div>
                        </div>
                      </label>
              
                    </div>
                  </fieldset>
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Approved Banks for Loan
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="approvedBanks"
                      value={getNestedValue(formData, 'approvedBanks') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter approved banks for loan..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
          
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <fieldset>
                    <legend className="text-sm font-medium text-gray-700 mb-3">
            Certificates
                    </legend>
                    <div className="grid grid-cols-2 sm:grid-cols-1 lg:grid-cols-3  gap-x-6 gap-y-2 items-center justify-center">
            
                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="certificates"
                          value="Title Deed Available"
                          checked={Array.isArray(getNestedValue(formData, 'certificates')) && 
                    getNestedValue(formData, 'certificates').includes('Title Deed Available')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'certificates')) 
                              ? getNestedValue(formData, 'certificates') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Title Deed Available']
                              : currentValues.filter((val: string) => val !== 'Title Deed Available');
                            setFormData((prev) => setNestedValue({ ...prev }, 'certificates', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Title Deed Available
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="certificates"
                          value="Sale Deed Available"
                          checked={Array.isArray(getNestedValue(formData, 'certificates')) && 
                    getNestedValue(formData, 'certificates').includes('Sale Deed Available')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'certificates')) 
                              ? getNestedValue(formData, 'certificates') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Sale Deed Available']
                              : currentValues.filter((val: string) => val !== 'Sale Deed Available');
                            setFormData((prev) => setNestedValue({ ...prev }, 'certificates', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Sale Deed Available
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="certificates"
                          value="Completion Certificate Available"
                          checked={Array.isArray(getNestedValue(formData, 'certificates')) && 
                    getNestedValue(formData, 'certificates').includes('Completion Certificate Available')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'certificates')) 
                              ? getNestedValue(formData, 'certificates') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Completion Certificate Available']
                              : currentValues.filter((val: string) => val !== 'Completion Certificate Available');
                            setFormData((prev) => setNestedValue({ ...prev }, 'certificates', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Completion Certificate Available
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="certificates"
                          value="Occupancy Certificate Available"
                          checked={Array.isArray(getNestedValue(formData, 'certificates')) && 
                    getNestedValue(formData, 'certificates').includes('Occupancy Certificate Available')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'certificates')) 
                              ? getNestedValue(formData, 'certificates') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Occupancy Certificate Available']
                              : currentValues.filter((val: string) => val !== 'Occupancy Certificate Available');
                            setFormData((prev) => setNestedValue({ ...prev }, 'certificates', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Occupancy Certificate Available
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="certificates"
                          value="Encumbrance Certificate Available"
                          checked={Array.isArray(getNestedValue(formData, 'certificates')) && 
                    getNestedValue(formData, 'certificates').includes('Encumbrance Certificate Available')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'certificates')) 
                              ? getNestedValue(formData, 'certificates') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Encumbrance Certificate Available']
                              : currentValues.filter((val: string) => val !== 'Encumbrance Certificate Available');
                            setFormData((prev) => setNestedValue({ ...prev }, 'certificates', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Encumbrance Certificate Available
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="certificates"
                          value="Land Use Permission Available"
                          checked={Array.isArray(getNestedValue(formData, 'certificates')) && 
                    getNestedValue(formData, 'certificates').includes('Land Use Permission Available')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'certificates')) 
                              ? getNestedValue(formData, 'certificates') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Land Use Permission Available']
                              : currentValues.filter((val: string) => val !== 'Land Use Permission Available');
                            setFormData((prev) => setNestedValue({ ...prev }, 'certificates', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Land Use Permission Available
                        </span>
                      </label>
            
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>

            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              transaction
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <fieldset className="w-full">
                    <legend className="text-sm font-medium text-gray-700 mb-3">
            Transaction Type
                    </legend>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
            
                      <label className="group relative block">
                        <input
                          type="radio"
                          name="transactionType"
                          value="Sale"
                          checked={getNestedValue(formData, 'transactionType') === 'Sale'}
                          onChange={handleChange}
                          className="peer sr-only"
                    
                        />
                        <div className="relative rounded-xl p-3 border-2 cursor-pointer
                  bg-gradient-to-b from-white to-gray-50
                  border-gray-200 
                  peer-checked:border-blue-500 peer-checked:from-blue-50 peer-checked:to-blue-50
                  peer-focus:ring-2 peer-focus:ring-blue-500 peer-focus:ring-offset-2
                  group-hover:border-blue-200 group-hover:shadow-sm
                  transition-all duration-200">
                          <div className="flex items-center justify-between">
                            <span className="block text-sm font-medium text-gray-900">
                      Sale
                            </span>
                          </div>
                        </div>
                      </label>
              

                      <label className="group relative block">
                        <input
                          type="radio"
                          name="transactionType"
                          value="Rent"
                          checked={getNestedValue(formData, 'transactionType') === 'Rent'}
                          onChange={handleChange}
                          className="peer sr-only"
                    
                        />
                        <div className="relative rounded-xl p-3 border-2 cursor-pointer
                  bg-gradient-to-b from-white to-gray-50
                  border-gray-200 
                  peer-checked:border-blue-500 peer-checked:from-blue-50 peer-checked:to-blue-50
                  peer-focus:ring-2 peer-focus:ring-blue-500 peer-focus:ring-offset-2
                  group-hover:border-blue-200 group-hover:shadow-sm
                  transition-all duration-200">
                          <div className="flex items-center justify-between">
                            <span className="block text-sm font-medium text-gray-900">
                      Rent
                            </span>
                          </div>
                        </div>
                      </label>
              

                      <label className="group relative block">
                        <input
                          type="radio"
                          name="transactionType"
                          value="Lease"
                          checked={getNestedValue(formData, 'transactionType') === 'Lease'}
                          onChange={handleChange}
                          className="peer sr-only"
                    
                        />
                        <div className="relative rounded-xl p-3 border-2 cursor-pointer
                  bg-gradient-to-b from-white to-gray-50
                  border-gray-200 
                  peer-checked:border-blue-500 peer-checked:from-blue-50 peer-checked:to-blue-50
                  peer-focus:ring-2 peer-focus:ring-blue-500 peer-focus:ring-offset-2
                  group-hover:border-blue-200 group-hover:shadow-sm
                  transition-all duration-200">
                          <div className="flex items-center justify-between">
                            <span className="block text-sm font-medium text-gray-900">
                      Lease
                            </span>
                          </div>
                        </div>
                      </label>
              
                    </div>
                  </fieldset>
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Possession Date
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="date"
                      name="possessionDate"
                      value={getNestedValue(formData, 'possessionDate') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter possession date..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
          
                    />
        
        
                  </div>
      
      
      
      
                </div>
              </div>
            </div>

            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              contactInformation
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Sales Office Address
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="textarea"
                      name="salesOffice"
                      value={getNestedValue(formData, 'salesOffice') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter sales office address..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
          
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-4">
                  <label className="inline-flex items-start">
                    <input
                      type="checkbox"
                      name="relationshipManager"
                      checked={Boolean(getNestedValue(formData, 'relationshipManager'))}
                      onChange={handleChange}
                      className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                    />
                    <span className="ml-2 text-sm text-gray-600">
          Dedicated Relationship Manager
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        )}

        {currentPage === 4 && (
          <div key="4" className="space-y-4">
                    
            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              investment
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Expected Return on Investment (%)
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="expectedROI"
                      value={getNestedValue(formData, 'expectedROI') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter expected return on investment (%)..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="0" max="100"
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Expected Rental Yield (%)
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="number"
                      name="rentalYield"
                      value={getNestedValue(formData, 'rentalYield') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter expected rental yield (%)..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      min="0" max="100"
                    />
        
        
                  </div>
      
      
      
      
                </div>

                <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                    <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              marketTrends
                    </h3>
                  </div>
                  <div className="space-y-4 px-6">
          
                    <div className="mb-6">
                      <div className="relative">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
          Current Market Demand
                        </label>
                        <div className="relative group">
                          <select
                            name="currentDemand"
                            value={getNestedValue(formData, 'currentDemand') ?? ''}
                            onChange={handleChange}
                            className="w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 text-sm appearance-none cursor-pointer transition-all duration-200 hover:border-blue-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
          
                          >
                            <option value="" className="text-gray-500">Select currentDemand</option>
                            <option key="High" value="High">High</option>
                            <option key="Medium" value="Medium">Medium</option>
                            <option key="Low" value="Low">Low</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="mb-6">
                      <div className="flex justify-between items-center mb-2">
                        <label className="block text-sm font-medium text-gray-700">
          Historical Annual Appreciation (%)
          
                        </label>
        
                      </div>
      
                      <div className="relative">
                        <input
                          type="number"
                          name="appreciationHistory"
                          value={getNestedValue(formData, 'appreciationHistory') ?? ''}
                          onChange={handleChange}
                          placeholder="Enter historical annual appreciation (%)..."
                          className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
          
                        />
        
        
                      </div>
      
      
      
      
                    </div>
                  </div>
                </div>

                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Future Development Plans in Vicinity
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="textarea"
                      name="futureDevelopment"
                      value={getNestedValue(formData, 'futureDevelopment') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter future development plans in vicinity..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
     `}
                      maxLength={500}
                    />
        
        
                  </div>
      
      
      
      
                </div>
              </div>
            </div>

            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              Features
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <fieldset>
                    <legend className="text-sm font-medium text-gray-700 mb-3">
            communityFeatures
                    </legend>
                    <div className="grid grid-cols-2 sm:grid-cols-1 lg:grid-cols-3  gap-x-6 gap-y-2 items-center justify-center">
            
                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="communityFeatures"
                          value="Gated Community"
                          checked={Array.isArray(getNestedValue(formData, 'communityFeatures')) && 
                    getNestedValue(formData, 'communityFeatures').includes('Gated Community')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'communityFeatures')) 
                              ? getNestedValue(formData, 'communityFeatures') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Gated Community']
                              : currentValues.filter((val: string) => val !== 'Gated Community');
                            setFormData((prev) => setNestedValue({ ...prev }, 'communityFeatures', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Gated Community
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="communityFeatures"
                          value="Dedicated Senior Citizen Area"
                          checked={Array.isArray(getNestedValue(formData, 'communityFeatures')) && 
                    getNestedValue(formData, 'communityFeatures').includes('Dedicated Senior Citizen Area')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'communityFeatures')) 
                              ? getNestedValue(formData, 'communityFeatures') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Dedicated Senior Citizen Area']
                              : currentValues.filter((val: string) => val !== 'Dedicated Senior Citizen Area');
                            setFormData((prev) => setNestedValue({ ...prev }, 'communityFeatures', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Dedicated Senior Citizen Area
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="communityFeatures"
                          value="Differently-Abled Friendly"
                          checked={Array.isArray(getNestedValue(formData, 'communityFeatures')) && 
                    getNestedValue(formData, 'communityFeatures').includes('Differently-Abled Friendly')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'communityFeatures')) 
                              ? getNestedValue(formData, 'communityFeatures') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Differently-Abled Friendly']
                              : currentValues.filter((val: string) => val !== 'Differently-Abled Friendly');
                            setFormData((prev) => setNestedValue({ ...prev }, 'communityFeatures', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Differently-Abled Friendly
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="communityFeatures"
                          value="Community Events Area"
                          checked={Array.isArray(getNestedValue(formData, 'communityFeatures')) && 
                    getNestedValue(formData, 'communityFeatures').includes('Community Events Area')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'communityFeatures')) 
                              ? getNestedValue(formData, 'communityFeatures') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Community Events Area']
                              : currentValues.filter((val: string) => val !== 'Community Events Area');
                            setFormData((prev) => setNestedValue({ ...prev }, 'communityFeatures', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Community Events Area
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="communityFeatures"
                          value="Yoga and Meditation Zone"
                          checked={Array.isArray(getNestedValue(formData, 'communityFeatures')) && 
                    getNestedValue(formData, 'communityFeatures').includes('Yoga and Meditation Zone')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'communityFeatures')) 
                              ? getNestedValue(formData, 'communityFeatures') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Yoga and Meditation Zone']
                              : currentValues.filter((val: string) => val !== 'Yoga and Meditation Zone');
                            setFormData((prev) => setNestedValue({ ...prev }, 'communityFeatures', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Yoga and Meditation Zone
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="communityFeatures"
                          value="Cultural Amenities (Amphitheater)"
                          checked={Array.isArray(getNestedValue(formData, 'communityFeatures')) && 
                    getNestedValue(formData, 'communityFeatures').includes('Cultural Amenities (Amphitheater)')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'communityFeatures')) 
                              ? getNestedValue(formData, 'communityFeatures') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Cultural Amenities (Amphitheater)']
                              : currentValues.filter((val: string) => val !== 'Cultural Amenities (Amphitheater)');
                            setFormData((prev) => setNestedValue({ ...prev }, 'communityFeatures', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Cultural Amenities (Amphitheater)
                        </span>
                      </label>
            
                    </div>
                  </fieldset>
                </div>

                <div className="mb-6">
                  <fieldset>
                    <legend className="text-sm font-medium text-gray-700 mb-3">
            advancedFeatures
                    </legend>
                    <div className="grid grid-cols-2 sm:grid-cols-1 lg:grid-cols-3  gap-x-6 gap-y-2 items-center justify-center">
            
                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="AdvancedFeatures"
                          value="Fire Safety Standards Compliance"
                          checked={Array.isArray(getNestedValue(formData, 'AdvancedFeatures')) && 
                    getNestedValue(formData, 'AdvancedFeatures').includes('Fire Safety Standards Compliance')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'AdvancedFeatures')) 
                              ? getNestedValue(formData, 'AdvancedFeatures') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Fire Safety Standards Compliance']
                              : currentValues.filter((val: string) => val !== 'Fire Safety Standards Compliance');
                            setFormData((prev) => setNestedValue({ ...prev }, 'AdvancedFeatures', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Fire Safety Standards Compliance
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="AdvancedFeatures"
                          value="Earthquake Resistance Standards"
                          checked={Array.isArray(getNestedValue(formData, 'AdvancedFeatures')) && 
                    getNestedValue(formData, 'AdvancedFeatures').includes('Earthquake Resistance Standards')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'AdvancedFeatures')) 
                              ? getNestedValue(formData, 'AdvancedFeatures') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Earthquake Resistance Standards']
                              : currentValues.filter((val: string) => val !== 'Earthquake Resistance Standards');
                            setFormData((prev) => setNestedValue({ ...prev }, 'AdvancedFeatures', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Earthquake Resistance Standards
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="AdvancedFeatures"
                          value="EV Charging Stations"
                          checked={Array.isArray(getNestedValue(formData, 'AdvancedFeatures')) && 
                    getNestedValue(formData, 'AdvancedFeatures').includes('EV Charging Stations')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'AdvancedFeatures')) 
                              ? getNestedValue(formData, 'AdvancedFeatures') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'EV Charging Stations']
                              : currentValues.filter((val: string) => val !== 'EV Charging Stations');
                            setFormData((prev) => setNestedValue({ ...prev }, 'AdvancedFeatures', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  EV Charging Stations
                        </span>
                      </label>
            
                    </div>
                  </fieldset>
                </div>

                <div className="mb-6">
                  <fieldset>
                    <legend className="text-sm font-medium text-gray-700 mb-3">
            additionalServices
                    </legend>
                    <div className="grid grid-cols-2 sm:grid-cols-1 lg:grid-cols-3  gap-x-6 gap-y-2 items-center justify-center">
            
                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="additionalServices"
                          value="Interior Design Support"
                          checked={Array.isArray(getNestedValue(formData, 'additionalServices')) && 
                    getNestedValue(formData, 'additionalServices').includes('Interior Design Support')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'additionalServices')) 
                              ? getNestedValue(formData, 'additionalServices') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Interior Design Support']
                              : currentValues.filter((val: string) => val !== 'Interior Design Support');
                            setFormData((prev) => setNestedValue({ ...prev }, 'additionalServices', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Interior Design Support
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="additionalServices"
                          value="Property Management Services"
                          checked={Array.isArray(getNestedValue(formData, 'additionalServices')) && 
                    getNestedValue(formData, 'additionalServices').includes('Property Management Services')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'additionalServices')) 
                              ? getNestedValue(formData, 'additionalServices') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Property Management Services']
                              : currentValues.filter((val: string) => val !== 'Property Management Services');
                            setFormData((prev) => setNestedValue({ ...prev }, 'additionalServices', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Property Management Services
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="additionalServices"
                          value="Rental Assistance"
                          checked={Array.isArray(getNestedValue(formData, 'additionalServices')) && 
                    getNestedValue(formData, 'additionalServices').includes('Rental Assistance')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'additionalServices')) 
                              ? getNestedValue(formData, 'additionalServices') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Rental Assistance']
                              : currentValues.filter((val: string) => val !== 'Rental Assistance');
                            setFormData((prev) => setNestedValue({ ...prev }, 'additionalServices', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Rental Assistance
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="additionalServices"
                          value="Resale Assistance"
                          checked={Array.isArray(getNestedValue(formData, 'additionalServices')) && 
                    getNestedValue(formData, 'additionalServices').includes('Resale Assistance')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'additionalServices')) 
                              ? getNestedValue(formData, 'additionalServices') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Resale Assistance']
                              : currentValues.filter((val: string) => val !== 'Resale Assistance');
                            setFormData((prev) => setNestedValue({ ...prev }, 'additionalServices', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Resale Assistance
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="additionalServices"
                          value="Onsite Maintenance Team"
                          checked={Array.isArray(getNestedValue(formData, 'additionalServices')) && 
                    getNestedValue(formData, 'additionalServices').includes('Onsite Maintenance Team')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'additionalServices')) 
                              ? getNestedValue(formData, 'additionalServices') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Onsite Maintenance Team']
                              : currentValues.filter((val: string) => val !== 'Onsite Maintenance Team');
                            setFormData((prev) => setNestedValue({ ...prev }, 'additionalServices', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Onsite Maintenance Team
                        </span>
                      </label>
            

                      <label className="inline-flex items-start">
                        <input
                          type="checkbox"
                          name="additionalServices"
                          value="Concierge Services"
                          checked={Array.isArray(getNestedValue(formData, 'additionalServices')) && 
                    getNestedValue(formData, 'additionalServices').includes('Concierge Services')}
                          onChange={(e) => {
                            const currentValues = Array.isArray(getNestedValue(formData, 'additionalServices')) 
                              ? getNestedValue(formData, 'additionalServices') 
                              : [];
                            const newValues = e.target.checked
                              ? [...currentValues, 'Concierge Services']
                              : currentValues.filter((val: string) => val !== 'Concierge Services');
                            setFormData((prev) => setNestedValue({ ...prev }, 'additionalServices', newValues));
                          }}
                          className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
                    focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
                    cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                  Concierge Services
                        </span>
                      </label>
            
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>

            <div className="mb-6 p-6 bg-gray-50 rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 pb-3 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              marketing
                </h3>
              </div>
              <div className="space-y-4 px-6">
          
                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
          Virtual Tour Link
          
                    </label>
        
                  </div>
      
                  <div className="relative">
                    <input
                      type="text"
                      name="virtualTour"
                      value={getNestedValue(formData, 'virtualTour') ?? ''}
                      onChange={handleChange}
                      placeholder="Enter virtual tour link..."
                      className={`w-full px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 
    placeholder-gray-400 text-sm transition-all duration-200
    hover:border-blue-300
    focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none
    disabled:bg-gray-100 disabled:cursor-not-allowed
    pr-10 
    ${!new RegExp('^https?://.*$').test(getNestedValue(formData, 'virtualTour') || '') && getNestedValue(formData, 'virtualTour') 
            ? 'border-red-300 focus:border-red-500 focus:ring-red-500' 
            : ''}
  `}
                      pattern="^https?://.*$"
                    />
        
        
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      {!new RegExp('^https?://.*$').test(getNestedValue(formData, 'virtualTour') || '') && 
             getNestedValue(formData, 'virtualTour') && (
                        <svg className="w-5 h-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      )}
                    </div>
        
                  </div>
      
      
                  {getNestedValue(formData, 'virtualTour') && !new RegExp('^https?://.*$').test(getNestedValue(formData, 'virtualTour')) && (
                    <p className="mt-1 text-sm text-red-600">
        Please enter a valid URL
                    </p>
                  )}
  
      
      
                </div>

                <div className="mb-4">
                  <label className="inline-flex items-start">
                    <input
                      type="checkbox"
                      name="modelHome"
                      checked={Boolean(getNestedValue(formData, 'modelHome'))}
                      onChange={handleChange}
                      className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                    />
                    <span className="ml-2 text-sm text-gray-600">
          Model Home Available
                    </span>
                  </label>
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
          Project Brochure
                  </label>
                  <div className="group relative">
                    <div className="flex justify-center items-center w-full" >
                      <div className="relative w-full">
                        <input
                          type="file"
                          name="brochure"
                          onChange={handleFileChange}
                          multiple
                          className="absolute inset-0 w-full h-full opacity-0 z-10 cursor-pointer"
                          accept=".pdf,.doc,.docx"
                        />
                        <div className="flex flex-col items-center w-full h-32 px-4 py-6 
                    bg-white text-gray-500 rounded-lg border-2 border-dashed border-gray-300 
                    transition-all duration-200 
                    hover:bg-gray-50 hover:border-blue-300
                    group-focus-within:border-blue-500 group-focus-within:ring-2 group-focus-within:ring-blue-500 group-focus-within:ring-offset-2">
                    
                          <div className="flex flex-col items-center pointer-events-none">
                            <svg className="w-8 h-8 mb-2 text-gray-400 group-hover:text-blue-500 transition-colors duration-200" 
                              fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                            </svg>
                      
                            <p className="text-sm text-gray-500 text-center group-hover:text-gray-600">
                              <span className="font-medium">Click to upload</span> or drag and drop
                            </p>
                      
                            <p className="text-xs text-gray-500 mt-1">
                        Accepted files: .pdf,.doc,.docx
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {(getNestedValue(formData, 'brochure') || []).length > 0 && (
                      <div className="space-y-2">
                        {(getNestedValue(formData, 'brochure') || []).map((fileName: string, index: number) => (
                          <div key={index} className="flex items-center justify-between p-3 bg-blue-50 rounded-lg">
                            <div className="flex items-center">
                              <svg className="w-5 h-5 text-blue-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                              </svg>
                              <span className="text-sm font-medium text-blue-700">
                                {fileName}
                              </span>
                            </div>
          
                            <button
                              type="button"
                              onClick={() => removeFile('brochure', index)}
                              className="p-1 text-blue-400 hover:text-blue-600 rounded-full hover:bg-blue-100 
              transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                              </svg>
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
          Floor Plan Images
                  </label>
                  <div className="group relative">
                    <div className="flex justify-center items-center w-full" >
                      <div className="relative w-full">
                        <input
                          type="file"
                          name="floorPlan"
                          onChange={handleFileChange}
                          multiple
                          className="absolute inset-0 w-full h-full opacity-0 z-10 cursor-pointer"
                          accept=".jpg,.jpeg,.png,.pdf"
                        />
                        <div className="flex flex-col items-center w-full h-32 px-4 py-6 
                    bg-white text-gray-500 rounded-lg border-2 border-dashed border-gray-300 
                    transition-all duration-200 
                    hover:bg-gray-50 hover:border-blue-300
                    group-focus-within:border-blue-500 group-focus-within:ring-2 group-focus-within:ring-blue-500 group-focus-within:ring-offset-2">
                    
                          <div className="flex flex-col items-center pointer-events-none">
                            <svg className="w-8 h-8 mb-2 text-gray-400 group-hover:text-blue-500 transition-colors duration-200" 
                              fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                            </svg>
                      
                            <p className="text-sm text-gray-500 text-center group-hover:text-gray-600">
                              <span className="font-medium">Click to upload</span> or drag and drop
                            </p>
                      
                            <p className="text-xs text-gray-500 mt-1">
                        Accepted files: .jpg,.jpeg,.png,.pdf
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {(getNestedValue(formData, 'floorPlan') || []).length > 0 && (
                      <div className="space-y-2">
                        {(getNestedValue(formData, 'floorPlan') || []).map((fileName: string, index: number) => (
                          <div key={index} className="flex items-center justify-between p-3 bg-blue-50 rounded-lg">
                            <div className="flex items-center">
                              <svg className="w-5 h-5 text-blue-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                              </svg>
                              <span className="text-sm font-medium text-blue-700">
                                {fileName}
                              </span>
                            </div>
          
                            <button
                              type="button"
                              onClick={() => removeFile('floorPlan', index)}
                              className="p-1 text-blue-400 hover:text-blue-600 rounded-full hover:bg-blue-100 
              transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                              </svg>
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
          3D Renderings
                  </label>
                  <div className="group relative">
                    <div className="flex justify-center items-center w-full" >
                      <div className="relative w-full">
                        <input
                          type="file"
                          name="renders3d"
                          onChange={handleFileChange}
                          multiple
                          className="absolute inset-0 w-full h-full opacity-0 z-10 cursor-pointer"
                          accept=".jpg,.jpeg,.png"
                        />
                        <div className="flex flex-col items-center w-full h-32 px-4 py-6 
                    bg-white text-gray-500 rounded-lg border-2 border-dashed border-gray-300 
                    transition-all duration-200 
                    hover:bg-gray-50 hover:border-blue-300
                    group-focus-within:border-blue-500 group-focus-within:ring-2 group-focus-within:ring-blue-500 group-focus-within:ring-offset-2">
                    
                          <div className="flex flex-col items-center pointer-events-none">
                            <svg className="w-8 h-8 mb-2 text-gray-400 group-hover:text-blue-500 transition-colors duration-200" 
                              fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                            </svg>
                      
                            <p className="text-sm text-gray-500 text-center group-hover:text-gray-600">
                              <span className="font-medium">Click to upload</span> or drag and drop
                            </p>
                      
                            <p className="text-xs text-gray-500 mt-1">
                        Accepted files: .jpg,.jpeg,.png
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {(getNestedValue(formData, 'renders3d') || []).length > 0 && (
                      <div className="space-y-2">
                        {(getNestedValue(formData, 'renders3d') || []).map((fileName: string, index: number) => (
                          <div key={index} className="flex items-center justify-between p-3 bg-blue-50 rounded-lg">
                            <div className="flex items-center">
                              <svg className="w-5 h-5 text-blue-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                              </svg>
                              <span className="text-sm font-medium text-blue-700">
                                {fileName}
                              </span>
                            </div>
          
                            <button
                              type="button"
                              onClick={() => removeFile('renders3d', index)}
                              className="p-1 text-blue-400 hover:text-blue-600 rounded-full hover:bg-blue-100 
              transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                              </svg>
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {currentPage === 5 && (
          <div key="5" className="space-y-4">
                    
            <div className="mb-4">
              <label className="inline-flex items-start">
                <input
                  type="checkbox"
                  name="Agree"
                  checked={Boolean(getNestedValue(formData, 'Agree'))}
                  onChange={handleChange}
                  className="mt-0.5 h-4 w-4 rounded border-gray-300 text-blue-600 
            focus:ring-1 focus:ring-blue-500 focus:ring-offset-0
            cursor-pointer"
                />
                <span className="ml-2 text-sm text-gray-600">
          By agreeing this you agree our terms of service and privacy policy
                </span>
              </label>
            </div>
          </div>
        )}
        {renderPaginationControls()}
      </form>
    </div>
  );
};

export default ResidentialPropertyForm;