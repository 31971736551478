import { createRoot } from 'react-dom/client';
import App from './App';
import { LanguageProvider } from './components/language/language_context';
import './index.css';

const Main = () => {
  return (
    <LanguageProvider>
      <App />
    </LanguageProvider>
  );
};

export default Main;

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(<Main />);
} else {
  console.error('Root container not found');
}
