// src/App.tsx
// import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import WebUIHome from '@/components/WebUIHome.tsx';
import AutomobileFormForm from '@/generated/AutomobileFormForm.tsx';
import EventsFormForm from '@/generated/EventsFormForm.tsx';
import RealEstateFormForm from '@/generated/RealEstateFormForm.tsx';
import LandAndPlotsFormForm from '@/generated/LandAndPlotsFormForm.tsx';
import PostAd from '@/components/ads/PostAD/PostAd.tsx';
// import TestFormForm from '@/generated/TestFormForm.tsx';
import SignupForm from '@/components/LoginForm/Signup.tsx';
import Login from '@/components/LoginForm/Login.tsx';
import RoomsForRentFormForm from '@/generated/RoomsForRentFormForm.tsx';
import ResidentialPropertyForm from '@/generated/ResidentialPropertyForm.tsx';

function App() {
  console.log('Rendering App');

  return (
    <Router basename="/v1">
      <div className="min-h-screen bg-gray-50">
        <WebUIHome>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/post-ad" element={<PostAd />} />
            <Route
              path="/post-ad/5f6f8483-ecad-48eb-813d-afe851520c3c"
              element={<AutomobileFormForm />}
            />
            <Route
              path="/post-ad/ea75c953-809a-405c-8283-a47d63f02de1"
              element={<EventsFormForm />}
            />
            <Route
              path="/post-ad/f595d16c-05d7-41e6-9e6e-ccc483e91ada"
              element={<RealEstateFormForm />}
            />
            <Route
              path="/post-ad/cb319a36-7783-4c82-af29-9bbaae40576e"
              element={<LandAndPlotsFormForm />}
            />
            <Route
              path="/post-ad/184cb1b5-a9ff-4114-837d-db5eae350016"
              element={<RoomsForRentFormForm />}
            />
            <Route
              path="/post-ad/9ed34c8d-ea90-4da3-8bad-eff5a5501e0a"
              element={<ResidentialPropertyForm />}
            />
            {/*<Route*/}
            {/*  path="/post-ad/41a548e5-3015-4502-aa97-704b85213c8e"*/}
            {/*  element={<TestFormForm />}*/}
            {/*/>*/}
            <Route path="/Signup" element={<SignupForm />} />
          </Routes>
        </WebUIHome>
      </div>
    </Router>
  );
}

export default App;
