import type { ReactNode } from 'react';
import { createContext, useState } from 'react';

// Define the type for the context value
export interface LanguageContextType {
  selectedLanguage: string;
  setSelectedLanguage: (languageCode: string) => void;
}

// Create the context
const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

// Create a provider component
export const LanguageProvider = ({ children }: { children: ReactNode }) => {
  const [selectedLanguage, setSelectedLanguage] = useState<string>('EN'); // Default language is English

  return (
    <LanguageContext.Provider value={{ selectedLanguage, setSelectedLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContext;
