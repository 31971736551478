import React from 'react';
import { Link } from 'react-router-dom';

const LoginPrompt: React.FC = () => {
  return (
    <div className="flex-1 p-6 bg-gray-50 flex flex-col items-center justify-center">
      <h3 className="text-lg font-medium text-gray-700 mb-2">You Have and Account</h3>

      <Link
        to="/Login"
        className="px-4 py-2 border border-blue-500 text-blue-500 rounded hover:bg-blue-50 transition text-sm font-medium"
      >
        Login
      </Link>
    </div>
  );
};

export default LoginPrompt;
