import React, { useState } from 'react';
import CategoryDropdown from '@/components/header/category_dropdown.tsx';
import { Search } from 'lucide-react';

const SearchBar = () => {
  const [search, setSearch] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<string>(''); // State lifted for the dropdown

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (search.trim() === '') {
      console.log('Please enter a search term.');
    } else {
      console.log('Search:', search, 'Category:', selectedCategory);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="">
      <div className="flex items-center sm- lg:w-full">
        {/* Category Dropdown */}
        <div className="text-black rounded-l-xl  px-2 border-r p-2 bg-white border-gray-300 w-13">
          <CategoryDropdown
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
        </div>

        {/* Search Input */}
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="flex-1 h-10 px-4 w-40 border-t border-b text-gray-800 font-medium border-gray-300 focus:outline-none"
          placeholder="Search for categories..."
        />

        {/* Search Button */}

        <button
          type="submit"
          className="h-10 w-12  flex items-center justify-center bg-yellow-500 font-medium rounded-r-xl text-white hover:bg-yellow-600 focus:outline-none"
        >

          <Search />
        </button>
      </div>
    </form>
  );
};

export default SearchBar;
