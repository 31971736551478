import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchCategories } from '@infrastructure/api/categories.ts';
import LanguageContext from '@/components/language/language_context.tsx';
import { ChevronDown, ChevronRight } from 'lucide-react';

interface Category {
  id: string;
  code: string;
  translatedName: string;
  childCategories?: Category[];
}

const PostAd: React.FC = () => {
  const { selectedLanguage } = useContext(LanguageContext) || { selectedLanguage: '' };
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [subCategories, setSubCategories] = useState<Category[]>([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const getCategories = async () => {
      try {
        const responseData = await fetchCategories(selectedLanguage as string);
        const categories: Category[] = responseData.data.map((item: any) => ({
          id: item.id,
          code: item.code,
          translatedName: item.translatedName,
          childCategories: item.childCategories || [],
        }));
        setCategories(categories);
      } catch (error) {
        console.error('Failed to fetch categories:', error);
      } finally {
        setIsLoading(false);
      }
    };

    getCategories().catch(console.error);
  }, [selectedLanguage]);

  const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const categoryId = event.target.value;
    setSelectedCategory(categoryId);
    const category = categories.find((cat) => cat.id === categoryId);
    setSubCategories(category?.childCategories || []);
    setSelectedSubCategory('');
  };

  const handleSubCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedSubCategory(event.target.value);
  };

  const handlePostAdClick = () => {
    if (selectedSubCategory) {
      navigate(`/post-ad/${selectedSubCategory}`);
    } else if (selectedCategory) {
      navigate(`/post-ad/${selectedCategory}`);
    } else {
      alert('Please select a category to proceed.');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50/50 to-white">
      {/* Container with responsive padding */}
      <div className="w-full px-4 sm:px-6 md:px-8 lg:px-12 pt-8 sm:pt-12 md:pt-16 pb-8 mx-auto max-w-[90%] sm:max-w-[85%] md:max-w-2xl lg:max-w-3xl xl:max-w-4xl 2xl:max-w-5xl">
        {/* Responsive heading */}
        <h2 className="text-center text-xl sm:text-4xl md:text-5xl font-bold text-black-600 mb-6 sm:mb-4 md:mb-8">
          Create Your Ad
        </h2>
        <div className="flex flex-col sm:flex-row items-center justify-center text-gray-600 mb-8 sm:mb-12">
          <div className="flex items-center space-x-2 text-sm sm:text-base md:text-lg">
            <span className="flex items-center">
              <span className="font-medium hover:text-blue-600 transition-colors duration-200">
                Advertise
              </span>
              <ChevronRight className="w-4 h-4 md:w-5 md:h-5 ml-2 text-gray-400" />
            </span>
            <span className="flex items-center">
              <span className="font-medium hover:text-blue-600 transition-colors duration-200">
                Connect
              </span>
              <ChevronRight className="w-4 h-4 md:w-5 md:h-5 ml-2 text-gray-400" />
            </span>
            <span className="flex items-center">
              <span className="font-medium hover:text-blue-600 transition-colors duration-200">
                Grow
              </span>
            </span>
          </div>
        </div>

        {/* Main card with responsive padding and margins */}
        <div className="bg-white rounded-xl sm:rounded-2xl shadow-md sm:shadow-lg p-4 sm:p-6 md:p-8 lg:p-10">
          <div className="space-y-4 sm:space-y-5 md:space-y-6">
            {/* Category select */}
            <div>
              <label
                htmlFor="category-select"
                className="block text-gray-700 text-sm sm:text-base font-medium mb-1.5 sm:mb-2"
              >
                Primary Category
              </label>
              <div className="relative">
                <select
                  id="category-select"
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  className="w-full h-10 sm:h-12 pl-3 sm:pl-4 pr-8 sm:pr-10 text-sm sm:text-base bg-white border border-gray-200 rounded-lg appearance-none focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors cursor-pointer hover:border-gray-300"
                >
                  <option value="">Select your category</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.translatedName}
                    </option>
                  ))}
                </select>
                <ChevronDown className="absolute right-2.5 sm:right-3 top-1/2 -translate-y-1/2 w-4 h-4 sm:w-5 sm:h-5 text-gray-400 pointer-events-none" />
              </div>
            </div>

            {/* Subcategory select */}
            {subCategories.length > 0 && (
              <div className="animate-fadeIn">
                <label
                  htmlFor="subcategory-select"
                  className="block text-gray-700 text-sm sm:text-base font-medium mb-1.5 sm:mb-2"
                >
                  Subcategory
                </label>
                <div className="relative">
                  <select
                    id="subcategory-select"
                    value={selectedSubCategory}
                    onChange={handleSubCategoryChange}
                    className="w-full h-10 sm:h-12 pl-3 sm:pl-4 pr-8 sm:pr-10 text-sm sm:text-base bg-white border border-gray-200 rounded-lg appearance-none focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors cursor-pointer hover:border-gray-300"
                  >
                    <option value="">Choose a subcategory</option>
                    {subCategories.map((subCategory) => (
                      <option key={subCategory.id} value={subCategory.id}>
                        {subCategory.translatedName}
                      </option>
                    ))}
                  </select>
                  <ChevronDown className="absolute right-2.5 sm:right-3 top-1/2 -translate-y-1/2 w-4 h-4 sm:w-5 sm:h-5 text-gray-400 pointer-events-none" />
                </div>
              </div>
            )}

            {/* Button container */}
            <div className="flex justify-center pt-4 sm:pt-6">
              <button
                onClick={handlePostAdClick}
                disabled={isLoading}
                className="sm:w-auto min-w-[120px] h-10 sm:h-12 px-4 sm:px-4 md:px-6 lg:px-10
                            text-sm sm:text-base font-medium text-white bg-blue-600
                            rounded-lg sm:rounded-xl transition-all duration-200
                            hover:bg-blue-700 hover:shadow-md
                            focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2
                            disabled:opacity-50 disabled:hover:bg-blue-600 disabled:hover:shadow-none
                            active:transform active:scale-95"
              >
                Post Ad
              </button>
            </div>
          </div>
        </div>

        {/* Terms text */}
        <div className="mt-4 sm:mt-6 text-center px-4">
          <p className="text-xs sm:text-sm text-gray-500">
            By posting an ad, you agree to our Terms of Service and Privacy Policy
          </p>
        </div>
      </div>
    </div>
  );
};

export default PostAd;
