// useLanguage.ts
import { useContext } from 'react';
import type { LanguageContextType } from './language_context';
import LanguageContext from './language_context';

export const useLanguage = (): LanguageContextType => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context as LanguageContextType;
};
