import React from 'react';
import HeaderLayout from '@/components/header/header_layout.tsx';
import Menu from '@/components/navigation/Menu.tsx';
import Footer from '@/components/Footer/Footer.tsx';
import AdsDisplay from '@/components/ads/adsdisplay.tsx';

const WebUIHome: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div>
      <HeaderLayout />
      <Menu />
      <main>{children}</main>
      <AdsDisplay />
      <Footer />
    </div>
  );
};

export default WebUIHome;
