import React from 'react';

interface LoginFormProps {
  username: string;
  password: string;
  loading: boolean;
  error: string | null;
  successMessage: string | null;
  onUsernameChange: (value: string) => void;
  onPasswordChange: (value: string) => void;
  onSubmit: (event: React.FormEvent) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({
  username,
  password,
  loading,
  error,
  successMessage,
  onUsernameChange,
  onPasswordChange,
  onSubmit,
}) => {
  return (
    <div className="flex-1 p-6 border-b lg:border-b-0 lg:border-r border-gray-300">
      <h2 className="text-2xl font-semibold text-gray-700 text-center mb-6">Sign In</h2>

      {error && <div className="bg-red-100 text-red-700 p-4 mb-4 rounded text-sm">{error}</div>}
      {successMessage && (
        <div className="bg-green-100 text-green-700 p-4 mb-4 rounded text-sm">
          {successMessage}
        </div>
      )}

      <form onSubmit={onSubmit} className="space-y-4">
        <div>
          <label htmlFor="username" className="block text-sm font-medium text-gray-600">
            Username
          </label>
          <input
            type="text"
            id="username"
            className="mt-1 w-full px-4 py-2 border rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 text-gray-900 text-sm"
            value={username}
            onChange={(e) => onUsernameChange(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="password" className="block text-sm font-medium text-gray-600">
            Password
          </label>
          <input
            type="password"
            id="password"
            className="mt-1 w-full px-4 py-2 border rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 text-gray-900 text-sm"
            value={password}
            onChange={(e) => onPasswordChange(e.target.value)}
            required
          />
        </div>
        <div className="flex justify-center">
          <button
            type="submit"
            className={`min-w-16 max-w-28 flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 
                ${loading ? 'bg-blue-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'}`}
            disabled={loading}
          >
            {loading ? 'Processing...' : 'Sign In'}
          </button>
        </div>
      </form>

    </div>
  );
};

export default LoginForm;
